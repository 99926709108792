import {
  apiFetchCustomerPickupTrackingNumber,
  apiCreateCustomerPickup,
  apiUpdateCustomerPickup,
  apiFetchCustomerPickupDetails,
} from '../../services/apiEndpoints';

const FETCH_CUSTOMER_PICKUP_TRACKING_NUMBER = 'customerPickup/FETCH_CUSTOMER_PICKUP_TRACKING_NUMBER';
export const fetchCustomerPickupTrackingNumber = trackingNumber => ({
  type: FETCH_CUSTOMER_PICKUP_TRACKING_NUMBER,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchCustomerPickupTrackingNumber(trackingNumber),
    },
  },
});

const CREATE_CUSTOMER_PICKUP = 'customerPickup/CREATE_CUSTOMER_PICKUP';
export const createCustomerPickup = data => ({
  type: CREATE_CUSTOMER_PICKUP,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateCustomerPickup,
      data,
    },
  },
});

const UPDATE_CUSTOMER_PICKUP = 'customerPickup/UPDATE_CUSTOMER_PICKUP';
export const updateCustomerPickup = (pickupId, data) => ({
  type: UPDATE_CUSTOMER_PICKUP,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateCustomerPickup(pickupId),
      data,
    },
  },
});

const FETCH_CUSTOMER_PICKUP_DETAILS = 'customerPickup/FETCH_CUSTOMER_PICKUP_DETAILS';
export const fetchCustomerPickupDetails = pickupId => ({
  type: FETCH_CUSTOMER_PICKUP_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchCustomerPickupDetails(pickupId),
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { format } from 'date-fns';
import {
  Button,
  Spin,
  DatePicker,
  Col,
  Form,
  Icon,
  List,
  Pagination,
  Tag,
  message,
} from 'antd';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import ReactTable from '../../components/ReactTable';
import LineChart from '../../components/LineChart';

import {
  volumeTrend,
  fetchOptions,
  fetchOptionsLike,
} from '../ducks';
import Pageheader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import ReactSelect from '../../components/Select';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';

import colorGenerator from '../../utilities/colorGenerator';

class VolumeTrend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDateReference: 'handover_date',
      selectedClients: [1],
      clientOptions: [],
      selectedDates: {
        from: moment().subtract(15, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        to: moment().format('YYYY-MM-DD 23:59:59'),
      },
      spinnerState: true,
      dashboardChart: {},
      isClientOptionsLoading: false,
      downloadTrendData: [],
      reactTableData: [],
      reactTableHeaders: [],
    };

    this.downloadTrend = this.downloadTrend.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions } = this.props;
    this.submitSearch();
    doFetchOptions('client').then(res => this.setState({ clientOptions: res.payload.data.slice(0, 50) }));
  }

  searchClient(e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isClientOptionsLoading: true,
    });

    doFetchOptionsLike('client', e).then((action) => {
      this.setState({
        clientOptions: action.payload.data.slice(0, 50),
        isClientOptionsLoading: false,
      });
    });
  }

  clientHandler(e) {
    this.setState({
      selectedClients: [...e],
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');

    this.setState({
      selectedDates: {
        from: formatDateOne !== 'Invalid Date' ? formatDateOne : null,
        to: formatDateTwo !== 'Invalid Date' ? formatDateTwo : null,
      },
    });
  }

  submitSearch() {
    this.setState({ spinnerState: true });

    const {
      selectedDateReference,
      selectedClients,
      selectedDates,
    } = this.state;

    const {
      doFetchVolumeTrend,
    } = this.props;

    const params = new URLSearchParams();
    params.append('dateFrom', selectedDates.from);
    params.append('dateTo', selectedDates.to);
    params.append('reference', selectedDateReference);
    params.append('clients', JSON.stringify(selectedClients));

    doFetchVolumeTrend(params).then((res) => {
      const { data } = res.payload;

      const allDates = (() => {
        const from = moment(selectedDates.from);
        const dates = [];

        while (from.isSameOrBefore(selectedDates.to)) {
          dates.push(from.format('YYYY-MM-DD'));
          from.add(1, 'days');
        }

        return dates;
      })();

      const dashboardChart = {
        labels: allDates,
        datasets: Object.keys(data).map((clientName) => {
          let datapoints = data[clientName].reduce((accumulator, datapoint) => {
            const newDatapoints = accumulator;
            newDatapoints[datapoint.reference_date] = datapoint.volume;
            return newDatapoints;
          }, {});

          datapoints = allDates.map((date) => {
            if (typeof (datapoints[date]) !== 'undefined') {
              return datapoints[date];
            }

            return 0;
          });

          const randomColor = colorGenerator();

          return {
            label: clientName,
            data: datapoints,
            fill: false,
            pointHitRadius: 20,
            backgroundColor: randomColor,
            borderColor: randomColor,
          };
        }),
      };

      const reactTableData = [];
      dashboardChart.datasets.forEach((dataset) => {
        const datePoints = [];
        dataset.data.forEach((dataPoint, index) => {
          datePoints[dashboardChart.labels[index]] = dataPoint;
        });

        const reactTableRow = { ...datePoints };
        reactTableRow.client = dataset.label;

        reactTableData.push(reactTableRow);
      });

      const reactTableHeaders = [
        { Header: 'Client', accessor: 'client' },
      ];
      dashboardChart.labels.forEach((date) => {
        reactTableHeaders.push({
          Header: date,
          accessor: date,
        });
      });

      this.setState({
        spinnerState: false,
        dashboardChart,
        dashboardLabels: allDates,
        reactTableData,
        reactTableHeaders,
      });
    });
  }

  downloadTrend() {
    const { dashboardChart } = this.state;
    const { datasets, labels } = dashboardChart;

    if (!datasets.length) {
      message.warning('No data to export!');
    }

    const columnHeaders = datasets.map(data => data.label.toString().replace(/"/g, "'"));
    columnHeaders.unshift('Clients');
    columnHeaders.push('Total');

    const colTotal = [];

    const rows = labels.map((date, index) => {
      const row = [];

      datasets.forEach((clientData, colIndex) => {
        const value = clientData.data[index];
        row.push(value);
        colTotal[colIndex] = colTotal[colIndex]
          ? colTotal[colIndex] + value
          : value;
      });

      row.push(row.reduce((sum, val) => sum + val));
      row.unshift(date);

      return row;
    });

    colTotal.push(colTotal.reduce((sum, val) => sum + val));
    colTotal.unshift('Total');

    this.setState({
      downloadTrendData: [
        columnHeaders,
        ...rows,
        colTotal,
      ],
    });
  }

  renderClientData(client) {
    const { dashboardLabels } = this.state;

    const totalQuantity = client.data.reduce((total, qty) => total + qty, 0);

    const computedDatapoints = dashboardLabels.map((date, index) => {
      const current = client.data[index];
      const previous = client.data[index - 1];
      const change = index
        ? (((current - previous) / (previous || 1)) * 100)
        : 0;

      return {
        date,
        volume: current,
        change,
      };
    });

    return (
      <List.Item>
        <Card title={`${client.label} (Total: ${totalQuantity})`}>
          <List
            pagination={(<Pagination defaultCurrent={1} total={10} />)}
            size="small"
            dataSource={computedDatapoints}
            renderItem={item => (
              <List.Item>
                <span style={{ display: 'inline-block', width: '80px' }}>{`${item.date}:`}</span>
                <span style={{ display: 'inline-block' }}>
                  <strong>{item.volume.toLocaleString()}</strong>
                </span>
                <Tag
                  // eslint-disable-next-line no-nested-ternary
                  color={item.change > 0 ? 'green' : (item.change === 0) ? 'gray' : 'red'}
                  style={{ marginLeft: 'auto' }}
                >
                  {`${item.change.toFixed(2)}%`}
                </Tag>
              </List.Item>
            )}
          />
        </Card>
      </List.Item>
    );
  }

  render() {
    const {
      clientOptions,
      spinnerState,
      dashboardChart,
      isClientOptionsLoading,
      downloadTrendData,
      reactTableData,
      reactTableHeaders,
    } = this.state;

    const { RangePicker } = DatePicker;

    const breadCrumbs = [
      {
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: 'Volume Trend',
      },
    ];

    return (
      <div className="Dashboard">
        <Pageheader title="Package Volume Trend" routes={breadCrumbs} />
        <Spacer />
        <Container>
          <div className="dashboardContainer">
            <Card>
              <Row>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Date Reference">
                    <ReactSelect
                      defaultValue="Handover Date"
                      style={{ width: '100%' }}
                      placeholder="Select date reference..."
                      options={[
                        { value: 'handover_date', label: 'Handover Date' },
                        { value: 'created_at', label: 'Creation Date' },
                        { value: 'updated_at', label: 'Last Update' },
                      ]}
                      onChange={e => this.setState({ selectedDateReference: e })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Select Date">
                    <RangePicker
                      style={{ width: '100%' }}
                      defaultValue={[
                        moment().subtract(15, 'days').startOf('day'),
                        moment().endOf('day'),
                      ]}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment('00:00:00', 'HH:mm:ss'),
                          moment('23:59:59', 'HH:mm:ss'),
                        ],
                        format: 'HH:mm',
                      }}
                      onChange={e => this.dateHandler(e)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Clients">
                    <ReactSelect
                      defaultValue={[1]}
                      style={{ width: '100%' }}
                      placeholder="Type to search for clients..."
                      loading={isClientOptionsLoading}
                      onSearch={e => this.searchClient(e)}
                      options={clientOptions}
                      mode="multiple"
                      onChange={e => this.clientHandler(e)}
                      filterOption={false}
                      showSearch
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Button disabled={spinnerState} type="primary" onClick={() => this.submitSearch()} block>
                <Icon type="file-search" />
                Filter
              </Button>
            </Card>
            <Spacer />
            <Spin
              spinning={spinnerState}
              size="small"
              tip="Fetching package volume trend..."
            >
              <Card
                title="Volume Trend Per Client"
                extra={(
                  <CSVLink
                    data={downloadTrendData}
                    target="_blank"
                    filename="volume_trend_raw.csv"
                    onClick={this.downloadTrend}
                  >
                    <Button type="primary">
                      <Icon type="download" />
                      Download Volume Trend
                    </Button>
                  </CSVLink>
                )}
              >
                <LineChart data={dashboardChart} />
              </Card>
              <Spacer />
              <Card title="Volume Trend Overall Table">
                <ReactTable
                  data={reactTableData}
                  columns={reactTableHeaders}
                  loadingText="Fetching volume trend..."
                  loading={spinnerState}
                />
              </Card>
              <Spacer />
              <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  lg: 3,
                }}
                dataSource={dashboardChart.datasets}
                renderItem={item => this.renderClientData(item)}
              />
            </Spin>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = {
  doFetchVolumeTrend: volumeTrend,
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
};

VolumeTrend.propTypes = {
  doFetchVolumeTrend: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(VolumeTrend);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form, Input, Modal, message, Button, Row, Col,
} from 'antd';
import {
  updatePortClusterRequest,
} from '../ducks';
import Select from '../../../components/Select';

function PortCulsterUpdateModal({
  editModalVisibility,
  setEditModalVisibility,
  rowDetails,
  setRowDetails,
  tableFilter,
  onTableUpdate,
  branchCodeList,
  ...props
}) {
  const {
    form,
    doUpdatePortClusterRequest,
  } = props;
  const { getFieldDecorator, setFieldsValue } = form;

  const [isUpdating, setIsUpdating] = useState(false);

  function UpdateData(id) {
    const { validateFields } = form;
    setIsUpdating(true);
    validateFields((err, values) => {
      if (!err) {
        doUpdatePortClusterRequest(id, values).then((res) => {
          message.success(res.payload.data.message);
          onTableUpdate({
            ...tableFilter,
            page: tableFilter.page - 1,
          });
          setIsUpdating(false);
        }).catch((resErr) => {
          // let errorMessage;
          if (resErr.error.response.status === 422) {
            // const index = Object.values(resErr.error.response.data);

            // eslint-disable-next-line prefer-destructuring
            // errorMessage = index[0]['barangay'];

            message.error(resErr.error.response.data.message, 3);
          }
          setIsUpdating(false);
        });
      } else {
        setIsUpdating(false);
      }
    });
  }

  useEffect(() => {
    setFieldsValue({
      branch: rowDetails.branch,
      cluster: rowDetails.cluster,
      route_code: rowDetails.route_code,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModalVisibility]);


  return (
    <Modal
      title={`Port Cluster Edit - #${rowDetails.id}`}
      closable={false}
      visible={editModalVisibility}
      afterClose={() => {
        setRowDetails({
          id: null,
          name: '',
          code: '',
        });
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setEditModalVisibility(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          type="primary"
          loading={isUpdating}
          onClick={() => { UpdateData(rowDetails.id); }}
        >
          Update
        </Button>,
      ]}
    >
      <Form>
        <Row gutter={12}>
          <Col xs={24} sm={8} lg={8}>
            <Form.Item label="Cluster">
              {getFieldDecorator('cluster', {
                rules: [{
                  required: true,
                  message: 'Cluster field is required',
                }],
              })(<Input autoComplete="off" />)}
            </Form.Item>
          </Col>

          <Col xs={24} sm={8} lg={8}>
            <Form.Item label="Branch">
              {getFieldDecorator('branch', {
                rules: [
                  {
                    required: true,
                    message: 'Branch field is required',
                  },
                ],
                initialValue: rowDetails.branch,
              })(<Select
                options={branchCodeList}
                showSearch
                placeholder="Select branch here..."
              />)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <Form.Item label="Route Code">
              {getFieldDecorator('route_code', {
                rules: [
                  {
                    required: true,
                    message: 'Route code field is required',
                  },
                ],
              })(<Input autoComplete="off" />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>

  );
}

PortCulsterUpdateModal.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doUpdatePortClusterRequest: PropTypes.func.isRequired,
  editModalVisibility: PropTypes.bool.isRequired,
  setEditModalVisibility: PropTypes.func.isRequired,
  rowDetails: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setRowDetails: PropTypes.func.isRequired,
  tableFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  branchCodeList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onTableUpdate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doUpdatePortClusterRequest: updatePortClusterRequest,
};

const WrappedPortCluseterUpdateModal = Form.create({ name: 'PortCulsterUpdateModal' })(PortCulsterUpdateModal);

export default connect(null, mapDispatchToProps)(WrappedPortCluseterUpdateModal);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';
import {
 DatePicker, Col, Form, Button, Icon 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import { accountList } from './ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ReactTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactSelect from '../../components/Select';
import Spacer from '../../components/Spacer';

export class LZDfirstmile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {
        statistics: [],
        displayDate: {},
      },
      dates: null,
      loading: false,
      status: null,
      datesFilter: null,
    };

    this.selectStatus = this.selectStatus.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    const { doAccountList } = this.props;
    doAccountList('firstmile').then(res => this.setState({
        account: {
          statistics: res.payload.data.statistics,
          displayDate: res.payload.data.displayDate,
        },
        loading: false,
      }),);
  }

  submitHandler() {
    const { datesFilter, account } = this.state;
    const { doAccountList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        const date = [
          !datesFilter
            ? {
              dateFrom: `${account.displayDate.start}`,
              dateTo: `${account.displayDate.end}`,
            }
            : datesFilter,
        ];
        doAccountList('firstmile', date).then(res => this.setState({
            account: {
              statistics: res.payload.data.statistics,
              displayDate: res.payload.data.displayDate,
            },
            loading: false,
          }),);
      }
    });
  }

  submitHandlerExport() {
    const { dates } = this.state;
    const { doAccountList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        const dataHandler = {
          status: values.export ? values.export : null,
          dates,
        };
        doAccountList('exportFirstMile', dataHandler).then((res) => {
          const newBlob = new Blob([res.payload.data]);
          fileDownload(newBlob, 'firstmile.csv');
        });
        // console.log(dataHandler);
      }
    });
  }

  selectStatus(e) {
    this.setState({
      status: e,
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    this.setState({
      dates:
        formatDateOne !== undefined
          ? { start: `${formatDateOne}`, end: `${formatDateTwo}` }
          : null,
      datesFilter:
        formatDateOne !== undefined
          ? { dateFrom: `${formatDateOne}`, dateTo: `${formatDateTwo}` }
          : null,
    });
  }

  render() {
    const { account, loading, status } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Monitoring',
      },
      {
        breadcrumbName: 'First Mile',
      },
    ];
    return (
      <div className="LZDfirstmile">
        <PageHeader title="Lazada First Mile" routes={breadCrumbs} />
        <Container>
          <Card
            extra={
              status ? (
                <Button
                  onClick={() => this.submitHandlerExport()}
                  type="danger"
                  block
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faFileExport} fixedWidth />
                  </Icon>
                  Export
                </Button>
              ) : (
                <Button
                  disabled
                  onClick={() => this.submitHandlerExport()}
                  type="danger"
                  block
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faFileExport} fixedWidth />
                  </Icon>
                  Export
                </Button>
              )
            }
          >
            <Row>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item label="Date Range">
                  {getFieldDecorator('dateRange', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(
                    <DatePicker.RangePicker
                      onChange={e => this.dateHandler(e)}
                      style={{ width: '100%' }}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment('00:00', 'HH:mm'),
                          moment('00:00', 'HH:mm'),
                        ],
                      }}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item label="Export By Status">
                  {getFieldDecorator('export', {
                    rules: [
                      { required: false, message: 'Please insert value' },
                    ],
                    onChange: this.selectStatus,
                  })(
                    <ReactSelect
                      style={{ width: '100%' }}
                      placeholder="Select Status..."
                      options={[
                        { value: 'for_pickup', label: 'for_pickup' },
                        {
                          value: 'accepted_by_courier',
                          label: 'accepted_by_courier',
                        },
                        { value: 'released', label: 'released' },
                      ]}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Button onClick={() => this.submitHandler()} type="primary" block>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              </Icon>
              Filter
            </Button>
          </Card>
          <Spacer />
          <Card
            title={
              account.displayDate.start !== undefined
                ? `${account.displayDate.start} to ${account.displayDate.end}`
                : ''
            }
          >
            <ReactTable
              data={account.statistics}
              loading={loading}
              columns={[
                {
                  Header: 'Merchant',
                  accessor: 'shipper_name',
                },
                {
                  Header: 'Total Created',
                  Cell: row => (
                    <React.Fragment>
                      {row.original.total_for_pickup
                        + row.original.total_accepted
                        + row.original.total_released
                        + row.original.total_failed}
                    </React.Fragment>
                  ),
                },
                {
                  Header: 'For Pickup',
                  accessor: 'total_for_pickup',
                },
                {
                  Header: 'Accepted By Courier',
                  accessor: 'total_accepted',
                },
                {
                  Header: 'Released',
                  accessor: 'total_released',
                },
                {
                  Header: 'Refused',
                  accessor: 'total_failed',
                },
              ]}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  doAccountList: accountList,
};

LZDfirstmile.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doAccountList: PropTypes.func.isRequired,
};

const WrappedLZDfirstmile = Form.create({ name: 'Register' })(LZDfirstmile);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedLZDfirstmile);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
 DatePicker, Col, Form, Button, Icon 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import { rubixpickOptions } from '../../components/options/ducks';
import { waybill } from '../../components/waybill/ducks';
import { cashierRemittanceListVIew } from '../ducks';
import { crList } from './ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactSelect from '../../components/Select';
import Spacer from '../../components/Spacer';

export class RemittanceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputData: {
        branch: [],
        client_id: [],
      },
      handoverDate: [],
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      optionState: {
        branchCode: [],
        client: [],
      },
      payloadReq: {
        export: false,
        page: 0,
        pageSize: 0,
        sorted: [],
      },
      tableFilter: [],
      searchFilter: [],
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
  }

  componentDidMount() {
    const { doRubixpickOptions } = this.props;
    doRubixpickOptions('branch_code').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          branchCode: res.payload.data,
        },
      })),);
    doRubixpickOptions('client').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          client: res.payload.data,
        },
      })),);
  }

  onFetchDataFunc(state, instance) {
    this.setState({
      loading: true,
    });
    // console.log(state);
    const { searchFilter } = this.state;
    const { remittanceCashierRemittanceNonFilter } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };
    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    remittanceCashierRemittanceNonFilter('report-list', paramsReq).then(res => this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        payloadReq: paramsReq,
        tableFilter: state.filtered,
      }),);
  }

  getEditAccountHandler(e) {
    const { waybillComponent, history } = this.props;
    waybillComponent(e.tracking_number).then(() => history.push(`/waybill/${e.tracking_number}`),);
  }

  selectHandler(e, name) {
    this.setState(prevState => ({
      inputData: {
        ...prevState.inputData,
        [name]: [{ id: name, value: e }],
      },
    }));
  }

  submitHandler() {
    const {
 handoverDate, payloadReq, inputData, tableFilter 
} = this.state;
    const { form, remittanceCashierRemittanceNonFilter } = this.props;
    const { validateFields } = form;
    // remittanceList('', 'report-list');
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        const paramsReq = {
          export: false,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: [
            ...inputData.branch,
            ...inputData.client_id,
            ...handoverDate,
          ],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filter = paramsReq.filter.concat(tableFilter);
        }
        remittanceCashierRemittanceNonFilter('report-list', paramsReq).then(
          res => this.setState({
              loading: false,
              userData: {
                rows: res.payload.data.rows,
                pages: res.payload.data.pages,
                total: res.payload.data.total,
              },
              payloadReq: paramsReq,
              searchFilter: [
                ...inputData.branch,
                ...inputData.client_id,
                ...handoverDate,
              ],
            }),
        );
      }
    });
  }

  submitHandlerExport() {
    const { handoverDate, payloadReq, inputData } = this.state;
    const { form, remittanceCashierRemittanceNonFilter } = this.props;
    const { validateFields } = form;
    // remittanceList('', 'report-list');
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        const paramsReq = {
          export: true,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: [
            ...inputData.branch,
            ...inputData.client_id,
            ...handoverDate,
          ],
          sorted: payloadReq.sorted,
        };
        remittanceCashierRemittanceNonFilter('report-list', paramsReq).then(
          (res) => {
            const newBlob = new Blob([res.payload.data]);
            fileDownload(newBlob, 'Remittance-list.csv');
            this.setState({
              loading: false,
            });
          },
        );
      }
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    const dateForm = `${formatDateOne}|${formatDateTwo}`;
    this.setState({
      handoverDate: [
        {
          id: 'handover_date',
          value: formatDateOne !== 'Invalid Date' ? dateForm : '',
        },
      ],
    });
  }

  render() {
    const { loading, userData, optionState } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Cashier Remittance',
      },
      {
        breadcrumbName: 'Report',
      },
    ];
    return (
      <div className="RemittanceReport">
        <PageHeader title="GENERATE REMITTANCE REPORT" routes={breadCrumbs} />
        <Container>
          <Card>
            <Form>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Branch Code">
                    {getFieldDecorator('branchCode', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        style={{ width: '100%' }}
                        onChange={e => this.selectHandler(e, 'branch')}
                        placeholder="Select Branch Code..."
                        options={optionState.branchCode}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Client">
                    {getFieldDecorator('client', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        style={{ width: '100%' }}
                        onChange={e => this.selectHandler(e, 'client_id')}
                        placeholder="Select Client..."
                        options={optionState.client}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Delivery Date Until">
                <DatePicker.RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment('00:00', 'HH:mm'),
                      moment('00:00', 'HH:mm'),
                    ],
                  }}
                  style={{ width: '100%' }}
                  onChange={e => this.dateHandler(e)}
                />
              </Form.Item>
              <Button onClick={() => this.submitHandler()} type="primary" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Icon>
                Apply
              </Button>
            </Form>
          </Card>
          <Spacer />
          <Card
            title={(
<Button onClick={() => this.submitHandlerExport()} type="danger">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faFileExport} fixedWidth />
                </Icon>
                Export
              </Button>
)}
            extra={`Total: ${userData.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <ReactTable
              data={userData.rows}
              pages={userData.pages}
              columns={[
                {
                  Header: 'Client',
                  accessor: 'client',
                },
                {
                  Header: 'Tracking Number',
                  accessor: 'tracking_number',
                },
                {
                  Header: 'Branch Location',
                  accessor: 'branch_location',
                },
                {
                  Header: 'Ageing (Delivery Date)',
                  accessor: 'ageing',
                },
                {
                  Header: 'No. of Attempts',
                  accessor: 'attempts',
                },
                {
                  Header: 'Total Amount',
                  accessor: 'total_amount',
                },
                {
                  Header: 'Amount Collected',
                  accessor: 'amount_collected',
                },
                {
                  Header: 'Remitted ID',
                  accessor: 'remitted',
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: row => (
                    <center>
                      <Button
                        type="link"
                        onClick={() => this.getEditAccountHandler(row.original)}
                      >
                        <Icon type="eye" />
                        View
                      </Button>
                    </center>
                  ),
                },
              ]}
              loadingText="Fetching remittance report. . ."
              loading={loading}
              onFetchData={this.onFetchDataFunc}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  remittanceCashierList: state.cashierRemittance.dataReport,
  options: state.options.options,
});

const mapDispatchToProps = {
  remittanceList: crList,
  remittanceCashierRemittanceNonFilter: cashierRemittanceListVIew,
  doRubixpickOptions: rubixpickOptions,
  waybillComponent: waybill,
};

RemittanceReport.propTypes = {
  remittanceCashierRemittanceNonFilter: PropTypes.func.isRequired,
  doRubixpickOptions: PropTypes.func.isRequired,
  waybillComponent: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const WrappedRemittanceReport = Form.create({ name: 'Register' })(
  RemittanceReport,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedRemittanceReport);

import {
  apiFetchList, apiCreateOutsource, apiUpdateOutsource,
} from '../../services/apiEndpoints';

const FETCH_OUTSOURCE_LIST = 'outsource/FETCH_OUTSOURCE_LIST';
export const fetchOutsourceList = data => ({
  type: FETCH_OUTSOURCE_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchList('outsource'),
      data,
    },
  },
});

const CREATE_OUTSOURCE = 'outsource/CREATE_OUTSOURCE';
export const createOutsource = data => ({
  type: CREATE_OUTSOURCE,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateOutsource,
      data,
    },
  },
});

const UPDATE_OUTSOURCE = 'vehicle/UPDATE_OUTSOURCE';
export const updateOutsource = (outsourceId, data) => ({
  type: UPDATE_OUTSOURCE,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateOutsource(outsourceId),
      data,
    },
  },
});


const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;

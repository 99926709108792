import {
  waybillList,
  getOptions,
  postWaybill,
  getOptionsCity,
  massUpload,
  exportwaybill,
  getOptionsProvinceCity,
} from '../../services/api';

const EXPORT_WAYBILL = 'waybill/EXPORT_WAYBILL';

export const waybillExportData = (todo, bodyFormData) => ({
  type: EXPORT_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...exportwaybill(todo),
      data: bodyFormData,
    },
  },
});

const MASS_UPLOAD = 'waybill/MASS_UPLOAD';

export const massUploadData = bodyFormData => ({
  type: MASS_UPLOAD,
  payload: {
    client: 'secure',
    request: {
      ...massUpload,
      data: bodyFormData,
    },
  },
});

const WAYBILL_LIST = 'waybill/WAYBILL_LIST';
const WAYBILL_LIST_SUCCESS = 'waybill/WAYBILL_LIST_SUCCESS';

export const viewWaybillList = data => ({
  type: WAYBILL_LIST,
  payload: {
    client: 'secure',
    request: {
      ...waybillList,
      data,
    },
  },
});

const CREATE_WAYBILL = 'waybill/CREATE_WAYBILL';
const CREATE_WAYBILL_SUCCESS = 'waybill/CREATE_WAYBILL_SUCCESS';

export const createWaybill = (waybillPackage, items) => ({
  type: CREATE_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...postWaybill,
      data: {
        items,
        package: {
          address_barangay: waybillPackage.barangay,
          address_city: waybillPackage.city.value,
          address_province: waybillPackage.province.value,
          branch_code: waybillPackage.branchCode.value,
          category: waybillPackage.category.value,
          client_id: waybillPackage.client.value,
          consignee_address: waybillPackage.address,
          consignee_email: waybillPackage.email,
          consignee_mobile: waybillPackage.mobile,
          consignee_name: waybillPackage.name,
          declared_value: waybillPackage.declaredValue,
          delivery_type: waybillPackage.deliveryType.value,
          height: waybillPackage.packageHeight,
          length: waybillPackage.packageLength,
          package_id: waybillPackage.package,
          package_size: waybillPackage.packageSize.value,
          package_type: waybillPackage.packageType,
          payment_type: waybillPackage.paymentType.value,
          reference_1: waybillPackage.reference1 ? waybillPackage.reference1 : null,
          reference_2: waybillPackage.reference2 ? waybillPackage.reference1 : null,
          reference_3: waybillPackage.reference3 ? waybillPackage.reference1 : null,
          reference_4: waybillPackage.reference4 ? waybillPackage.reference1 : null,
          remarks: waybillPackage.remarks,
          total_price: waybillPackage.amountCollected,
          transport_mode: waybillPackage.transportMode.value,
          weight: waybillPackage.packageWeight,
          width: waybillPackage.packageWidth,
        },
      },
    },
  },
});

const WAYBILL_LIST_FILTER = 'waybill/WAYBILL_LIST_FILTER';
const WAYBILL_LIST_FILTER_SUCCESS = 'waybill/WAYBILL_LIST_FILTER_SUCCESS';

export const viewWaybillFilterList = data => ({
  type: WAYBILL_LIST_FILTER,
  payload: {
    client: 'secure',
    request: {
      ...waybillList,
      data,
    },
  },
});

const FETCH_OPTIONS_CLIENT = 'waybill/FETCH_OPTIONS_CLIENT';
const FETCH_OPTIONS_CLIENT_SUCCESS = 'waybill/FETCH_OPTIONS_CLIENT_SUCCESS';

const FETCH_OPTIONS_BRANCHCODE = 'waybill/FETCH_OPTIONS_BRANCHCODE';
const FETCH_OPTIONS_BRANCHCODE_SUCCESS = 'waybill/FETCH_OPTIONS_BRANCHCODE_SUCCESS';

const FETCH_OPTIONS_PROVINCE = 'waybill/FETCH_OPTIONS_PROVINCE';
const FETCH_OPTIONS_PROVINCE_SUCCESS = 'waybill/FETCH_OPTIONS_PROVINCE_SUCCESS';

const FETCH_OPTIONS_CITY = 'waybill/FETCH_OPTIONS_CITY';
const FETCH_OPTIONS_CITY_SUCCESS = 'waybill/FETCH_OPTIONS_CITY_SUCCESS';

const SEARCH_OPTION_PROVCITY = 'waybill/FETCH_OPTIONS_CITY';

export const getOptionsProvCity = (type, data) => ({
  type: SEARCH_OPTION_PROVCITY,
  payload: {
    client: 'secure',
    request: {
      ...getOptionsProvinceCity(type, data),
    },
  },
});

export const fetchCity = city => ({
  type: FETCH_OPTIONS_CITY,
  payload: {
    client: 'secure',
    request: {
      ...getOptionsCity(city),
    },
  },
});

export const fetchOptions = (option) => {
  switch (option) {
    case 'client':
      return {
        type: FETCH_OPTIONS_CLIENT,
        payload: {
          client: 'secure',
          request: {
            ...getOptions(option),
          },
        },
      };

    case 'branch_code':
      return {
        type: FETCH_OPTIONS_BRANCHCODE,
        payload: {
          client: 'secure',
          request: {
            ...getOptions(option),
          },
        },
      };

    case 'province':
      return {
        type: FETCH_OPTIONS_PROVINCE,
        payload: {
          client: 'secure',
          request: {
            ...getOptions(option),
          },
        },
      };

    default:
      return false;
  }
};

const initialState = {
  resCreate: {},
  listView: {},
  options: {
    client: [],
    branchCode: [],
    province: [],
    city: [],
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_WAYBILL_SUCCESS:
      return {
        ...state,
        resCreate: action.payload.data,
      };

    case WAYBILL_LIST_SUCCESS:
      return {
        ...state,
        listView: action.payload.data,
      };

    case WAYBILL_LIST_FILTER_SUCCESS:
      return {
        ...state,
        listView: action.payload.data,
      };

    case FETCH_OPTIONS_CLIENT_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          client: action.payload.data,
        },
      };

    case FETCH_OPTIONS_BRANCHCODE_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          branchCode: action.payload.data,
        },
      };

    case FETCH_OPTIONS_PROVINCE_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          province: action.payload.data,
        },
      };

    case FETCH_OPTIONS_CITY_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          city: action.payload.data,
        },
      };

    default:
      return state;
  }
}

export default reducer;

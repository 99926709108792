import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  Button, message, Icon, Popconfirm,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import StatusTaggingForm from '../../components/StatusTaggingForm';
import { fetchTrackingNumber, updateTrackingNumberStatus, fetchOptions } from '../ducks';
import Spacer from '../../components/Spacer';
import Select from '../../components/Select';

export class Claims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taggingType: 'claims',
      isClaimsTagging: true,
      trackingNumberRows: [],
      errors: [],
      refuse: [],
      success: [],
    };
    
    this.setTaggingTypeState = this.setTaggingTypeState.bind(this);
    this.validatePackage = this.validatePackage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions } = this.props;
    doFetchOptions('claims').then((action) => {
      this.setState(({
        refuse: action.payload.data,
      }));
    });
  }

  setTaggingTypeState(e) {
    this.setState({
      taggingType: e,
      isClaimsTagging: e === 'claims',
    });
  }

  submitForm() {
    const { trackingNumberRows } = this.state;
    const { doUpdateTrackingNumberStatus } = this.props;

    // eslint-disable-next-line no-unused-vars
    trackingNumberRows.forEach((item, key) => {
      const payload = {
        current_status: item.current_status,
        comment: item.comment,
        status: item.status,
        reference_code: item.reference_code,
        tracking_number: item.tracking_number,
        timestamp: item.timestamp,
      };

      doUpdateTrackingNumberStatus(payload, item.tracking_number).then((action) => {
        this.setState({
          trackingNumberRows: [],
        });
        message.success(`Successfully updated ${item.tracking_number}`);
        this.setState(prevState => ({
          success: [
            ...prevState.success,
            {
              tracking_number: action.payload.data.tracking_number,
              address_city: action.payload.data.address_city,
              reference_code: action.payload.data.status_reference,
              status: action.payload.data.status,
            },
          ],
        }));
      });
    });
  }

  validatePackage(values) {
    const {
      trackingNumberRows, taggingType
    } = this.state;
    const { doFetchTrackingNumber } = this.props;

    const splitTrackingNumber = values.trackingNumber ? values.trackingNumber.split('\n') : '';
    const filteredTrackingNumber = splitTrackingNumber.filter(
      (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
    );
    // eslint-disable-next-line no-unused-vars
    filteredTrackingNumber.forEach((item, key) => {
      const exists = trackingNumberRows.filter(tn => tn.tracking_number === item);

      if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else {
        doFetchTrackingNumber(taggingType, item).then((action) => {
          this.setState(prevState => ({
            trackingNumberRows: [
              ...prevState.trackingNumberRows,
              {
                tracking_number: action.payload.data.tracking_number,
                current_status: action.payload.data.status,
                status: taggingType,
                reference_code: values.reference,
                status_reference: action.payload.data.status_reference,
                comment: values.comment,
              },
            ],
          }));
          message.success('Successfully added tracking number!.');
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 400) {
            errorMessage = action.error.response.data.message;
            this.setState(prevState => ({
              errors: [
                ...prevState.errors,
                {
                  tracking_number: item,
                  message: action.error.response.data.message,
                },
              ],
            }));
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  confirmRemove(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber,
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber,
    });

    message.success('Successfully removed tracking number!');
  }

  render() {
    const {
      trackingNumberRows, errors, refuse, success, isClaimsTagging,
    } = this.state;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Claims',
      },
    ];

    const trackingNumberRowColumns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Current Status',
        accessor: 'current_status',
      },
      {
        Header: 'Target Status',
        accessor: 'status',
      },
      {
        Header: 'Reference',
        accessor: 'reference_code',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        Cell: data => (
          <React.Fragment>
            <Popconfirm
              placement="leftBottom"
              title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
              okText="Yes"
              onConfirm={() => this.confirmRemove(data.row.tracking_number)}
              cancelText="No"
            >
              <Button type="link" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                </Icon>
          Remove
              </Button>
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    const claimsTaggingOptions = [
      {
        label: 'Claims',
        value: 'claims',
      },
      {
        label: 'Claims For Sale',
        value: 'claims_for_sale',
      },
      {
        label: 'Claims Sold',
        value: 'claims_sold',
      },
      {
        label: 'Claims Damaged',
        value: 'claims_damaged',
      },
      {
        label: 'Package lost',
        value: 'package_lost',
      },
      {
        label: 'Claims Accounted',
        value: 'claims_accounted',
      },
    ];

    return (
      <div className="Claims">
        <PageHeader title="Claims" routes={breadCrumbs} />
        <Spacer />
        <Container>
          <StatusTaggingForm
            validateHandler={this.validatePackage}
            submitHandler={this.submitForm}
            trackingNumberList={trackingNumberRows}
            trackingNumberListColumns={trackingNumberRowColumns}
            errorList={errors}
            successList={success}
            options={refuse}
            hasReference={!isClaimsTagging}
            hasDropdownReference={isClaimsTagging}
            cardExtra={(
              <React.Fragment>
                <span>Select tagging type: </span>
                <Select
                  defaultValue="claims"
                  options={claimsTaggingOptions}
                  onChange={this.setTaggingTypeState}
                  disabled={trackingNumberRows.length > 0}
                />
              </React.Fragment>
            )}
          />
        </Container>
      </div>

    );
  }
}

Claims.propTypes = {
  doFetchTrackingNumber: PropTypes.func.isRequired,
  doUpdateTrackingNumberStatus: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchTrackingNumber: fetchTrackingNumber,
  doUpdateTrackingNumberStatus: updateTrackingNumberStatus,
  doFetchOptions: fetchOptions,
};

export default connect(null, mapDispatchToProps)(Claims);

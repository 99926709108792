import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import {
  DatePicker, Col, Form, Button, Icon, Input,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import { rubixpickOptions } from '../../components/options/ducks';
import { waybill } from '../../components/waybill/ducks';
import { remittedRemittance, remittedRemittanceList } from './ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactSelect from '../../components/Select';
import Spacer from '../../components/Spacer';

export class RemittedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      optionState: {
        status: [],
        client: [],
      },
      payloadReq: {
        page: 0,
        pageSize: 10,
        filter: [],
        sorted: [],
      },
      tableFilter: [],
      searchFilter: [],
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
  }

  componentDidMount() {
    const { doRubixpickOptions } = this.props;
    doRubixpickOptions('status').then(res => this.setState(prevState => ({
      optionState: {
        ...prevState.optionState,
        status: res.payload.data,
      },
    })));
    doRubixpickOptions('client').then(res => this.setState(prevState => ({
      optionState: {
        ...prevState.optionState,
        client: res.payload.data,
      },
    })));
  }

  onFetchDataFunc(state, instance) {
    this.setState({
      loading: true,
    });
    // console.log(state);
    const { searchFilter } = this.state;
    const { remittanceRemittedList } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };
    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    remittanceRemittedList(paramsReq).then(res => this.setState({
      loading: false,
      userData: {
        rows: res.payload.data.rows,
        pages: res.payload.data.pages,
        total: res.payload.data.total,
      },
      payloadReq: paramsReq,
      tableFilter: state.filtered,
    }));
  }

  getEditAccountHandler(e) {
    const { waybillComponent, history } = this.props;
    waybillComponent(e.tracking_number).then(() => history.push(`/waybill/${e.tracking_number}`));
    // console.log(e.tracking_number);
  }

  submitHandler() {
    const { payloadReq, tableFilter } = this.state;
    const { form, remittanceRemittedList } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        const newDateOne = values.dateRange[0]
          ? values.dateRange[0].format('YYYY-MM-DD HH:mm:ss')
          : null;
        const newDateTwo = values.dateRange[1]
          ? values.dateRange[1].format('YYYY-MM-DD HH:mm:ss')
          : null;
        const paramsReq = {
          export: false,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: [
            values.client
              ? { id: 'client', value: values.client }
              : { id: 'client' },
            newDateOne
              ? { id: 'dateFrom', value: newDateOne }
              : { id: 'dateFrom' },
            newDateTwo ? { id: 'dateTo', value: newDateTwo } : { id: 'dateTo' },
            values.status
              ? { id: 'location', value: values.statusl }
              : { id: 'location' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number', value: '' },
          ],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filter = paramsReq.filter.concat(tableFilter);
        }
        remittanceRemittedList(paramsReq).then(res => this.setState({
          loading: false,
          userData: {
            rows: res.payload.data.rows,
            pages: res.payload.data.pages,
            total: res.payload.data.total,
          },
          searchFilter: [
            values.client
              ? { id: 'client', value: values.client }
              : { id: 'client' },
            newDateOne
              ? { id: 'dateFrom', value: newDateOne }
              : { id: 'dateFrom' },
            newDateTwo
              ? { id: 'dateTo', value: newDateTwo }
              : { id: 'dateTo' },
            values.status
              ? { id: 'location', value: values.statusl }
              : { id: 'location' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number', value: '' },
          ],
        }));
      }
    });
  }

  submitHandlerExport() {
    const { payloadReq } = this.state;
    const { form, remittanceRemittedList } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        const newDateOne = values.dateRange[0]
          ? values.dateRange[0].format('YYYY-MM-DD HH:mm:ss')
          : null;
        const newDateTwo = values.dateRange[1]
          ? values.dateRange[0].format('YYYY-MM-DD HH:mm:ss')
          : null;
        const paramsReq = {
          export: true,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: [
            values.client
              ? { id: 'client', value: values.client }
              : { id: 'client' },
            newDateOne
              ? { id: 'dateFrom', value: newDateOne }
              : { id: 'dateFrom' },
            newDateTwo ? { id: 'dateTo', value: newDateTwo } : { id: 'dateTo' },
            values.status
              ? { id: 'location', value: values.statusl }
              : { id: 'location' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number', value: '' },
          ],
          sorted: payloadReq.sorted,
        };
        remittanceRemittedList(paramsReq).then((res) => {
          const newBlob = new Blob([res.payload.data]);
          fileDownload(newBlob, 'remitted-list.csv');
          this.setState({
            loading: false,
          });
        });
      }
    });
  }

  render() {
    const { userData, loading, optionState } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Courier Remittance',
      },
      {
        breadcrumbName: 'Remitted',
      },
    ];
    return (
      <div className="RemittedItems">
        <PageHeader title="REMITTANCE VIEW LIST" routes={breadCrumbs} />
        <Container>
          <Form>
            <Card>
              <Form.Item label="Dispatch Date">
                {getFieldDecorator('dateRange', {
                  rules: [{ required: false, message: 'Please insert value' }],
                })(
                  <DatePicker.RangePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00', 'HH:mm'),
                        moment('00:00', 'HH:mm'),
                      ],
                    }}
                    style={{ width: '100%' }}
                  />,
                )}
              </Form.Item>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Client">
                    {getFieldDecorator('client', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        style={{ width: '100%' }}
                        placeholder="Select Client..."
                        options={optionState.client}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Location">
                    {getFieldDecorator('status', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        style={{ width: '100%' }}
                        placeholder="Select Status..."
                        options={optionState.status}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Tracking Number">
                {getFieldDecorator('tNumber', {
                  rules: [{ required: false, message: 'Please insert value' }],
                })(<Input.TextArea />)}
              </Form.Item>
              <Button onClick={() => this.submitHandler()} type="primary" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Icon>
                Filter
              </Button>
            </Card>
            <Spacer />
            <Card
              title={(
                <Button
                  onClick={() => this.submitHandlerExport()}
                  type="danger"
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faFileExport} fixedWidth />
                  </Icon>
                  Export
                </Button>
)}
              extra={`Total: ${userData.total
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            >
              <ReactTable
                data={userData.rows}
                pages={userData.pages}
                columns={[
                  {
                    Header: 'Remitted Date',
                    accessor: 'created_at',
                  },
                  {
                    Header: 'Remittance Number',
                    accessor: 'id',
                  },
                  {
                    Header: 'Dispatch Number',
                    accessor: 'dh_id',
                  },
                  {
                    Header: 'Waybill Number',
                    accessor: 'tracking_number',
                  },
                  {
                    Header: 'Driver Name',
                    accessor: 'driver',
                  },
                  {
                    Header: 'Consignee Name',
                    accessor: 'consignee_name',
                  },
                  {
                    Header: 'City',
                    accessor: 'address_city',
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                      <center>
                        <Button
                          type="link"
                          onClick={() => this.getEditAccountHandler(row.original)
                          }
                        >
                          <Icon type="eye" />
                          View
                        </Button>
                      </center>
                    ),
                  },
                ]}
                loadingText="Fetching remitted items. . ."
                loading={loading}
                onFetchData={this.onFetchDataFunc}
              />
            </Card>
          </Form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  remittanceList: state.courierRemittance.dataRItems,
  options: state.options.options,
});

const mapDispatchToProps = {
  remittedList: remittedRemittance,
  remittanceRemittedList: remittedRemittanceList,
  waybillComponent: waybill,
  doRubixpickOptions: rubixpickOptions,
};

RemittedItems.propTypes = {
  remittanceRemittedList: PropTypes.func.isRequired,
  waybillComponent: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doRubixpickOptions: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const WrappedRemittedItems = Form.create({ name: 'Register' })(RemittedItems);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedRemittedItems);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Col, Button, Icon
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import Row from './Row';
import Card from './Card';

export class CardImageList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    const { pod } = this.props;

    let podList = pod.map((item) => (
        <Col  xs={12} sm={8} lg={6} key={item.id}>
            <Card title={`Pod #${item.id}`}>
                <img
                    style={{ width: '100%', marginTop: 0, height: '275px' }}
                    src={item.filename}
                />
                <Button
                    block
                    onClick={() => {
                        window.open(item.filename);
                    }}
                >
                    <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPrint} fixedWidth />
                    </Icon>
                    Download POD
                </Button>
            </Card>
        </Col>
    ));
    

    return (
     <React.Fragment>
         <Row>
             {podList}
         </Row>
     </React.Fragment>
    );
  }
}

CardImageList.propTypes = {
    pod: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

CardImageList.defaultProps = {
    pod: [],
}

export default CardImageList;

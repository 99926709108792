import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Button,
  Popconfirm,
  Spin,
  DatePicker,
  Col,
  Form,
  Icon,
  message,
} from 'antd';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LineChart from '../../components/LineChart';

import {
  dashboardBacklogs,
  dashboardBacklogsDownload,
  fetchOptions,
  fetchOptionsLike,
  dashboardBacklogsDownloadOverall,
} from '../ducks';
import Pageheader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import ReactSelect from '../../components/Select';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';

const styleTable = width => ({
  border: '0.5px solid black',
  fontSize: '10px',
  padding: '5px',
  width,
  borderCollapse: 'collapse',
});

class Backlogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBranchOptionsLoading: false,
      selectedBranches: [props.user.port_code],
      selectedClients: [1],
      branchOptions: [],
      dateHandler: {
        from: moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00'),
        to: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      isFetching: true,
      backlogs: [],
    };

    this.getBacklogs = this.getBacklogs.bind(this);
    this.renderBranchPrintView = this.renderBranchPrintView.bind(this);
    this.renderAllBranchPrintView = this.renderAllBranchPrintView.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions, doFetchOptionsLike, user } = this.props;
    doFetchOptions('destination').then((res) => {
      this.setState({
        branchOptions: res.payload.data,
        selectedBranches: [user.port_code],
      });
    });

    doFetchOptionsLike('client', 'l').then((res) => {
      this.setState({
        clientOptions: res.payload.data.slice(0, 50),
      });
    });

    this.getBacklogs();
  }

  getBacklogs() {
    const { doDashboardBacklogs } = this.props;
    const { dateHandler, selectedBranches, selectedClients } = this.state;

    const params = { ...dateHandler, clients: selectedClients };

    this.setState({ isFetching: true });
    doDashboardBacklogs(params, selectedBranches).then((res) => {
      let responseData = res.payload.data;
      responseData = responseData.map(data => ({
        ...data,
        delivery_successful: parseInt(data.delivery_successful, 10),
        open: parseInt(data.open, 10),
        fd_in_branch: parseInt(data.fd_in_branch, 10),
        fd_in_transit_to_main: parseInt(data.fd_in_transit_to_main, 10),
        fd_in_main_hub: parseInt(data.fd_in_main_hub, 10),
        fd_in_transit_for_return: parseInt(data.fd_in_transit_for_return, 10),
        fd_returned: parseInt(data.fd_returned, 10),
        for_transfer: parseInt(data.for_transfer, 10),
        in_transit_to_branch: parseInt(data.in_transit_to_branch, 10),
        ongoing_deliveries: parseInt(data.ongoing_deliveries, 10),
        claims: parseInt(data.claims, 10),
      }));

      const parsedResponseData = [];
      selectedBranches.forEach((branch) => {
        const filteredResponseData = responseData.filter(backlog => backlog.branch === branch);

        // Get total for each columns
        let totalVolume = 0;
        let totalForTransfer = 0;
        let totalInTransitToBranch = 0;
        let totalOpen = 0;
        let totalOngoingDeliveries = 0;
        let totalDeliverySuccessful = 0;
        let totalFDInBranch = 0;
        let totalFDInTransitToMain = 0;
        let totalFDInMain = 0;
        let totalFDInTransitForReturn = 0;
        let totalFDReturned = 0;
        let totalClaims = 0;

        _.forEach(filteredResponseData, (backlog) => {
          totalVolume += backlog.volume;
          totalClaims += backlog.claims;
          totalDeliverySuccessful += backlog.delivery_successful;
          totalFDInBranch += backlog.fd_in_branch;
          totalFDInMain += backlog.fd_in_main_hub;
          totalFDInTransitForReturn += backlog.fd_in_transit_for_return;
          totalFDInTransitToMain += backlog.fd_in_transit_to_main;
          totalFDReturned += backlog.fd_returned;
          totalForTransfer += backlog.for_transfer;
          totalInTransitToBranch += backlog.in_transit_to_branch;
          totalOngoingDeliveries += backlog.ongoing_deliveries;
          totalOpen += backlog.open;
        });

        const volume = [];
        const open = [];
        const deliverySuccessful = [];
        const failedDelivery = [];
        filteredResponseData.forEach((data) => {
          volume.push(data.volume);
          open.push(data.open);
          deliverySuccessful.push(data.delivery_successful);
          failedDelivery.push(data.fd_in_branch
              + data.fd_in_transit_to_main
              + data.fd_in_main_hub
              + data.fd_in_transit_for_return
              + data.fd_returned);
        });

        parsedResponseData[branch] = {
          chart: {
            labels: _.map(filteredResponseData, 'package_handover_date'),
            datasets: [
              {
                label: 'Volume',
                data: volume,
                backgroundColor: 'rgb(77, 255, 77)',
                borderColor: 'rgb(77, 255, 77)',
                lineTension: 0.1,
                fill: false,
                pointHitRadius: 10,
              },
              {
                label: 'Open',
                data: open,
                backgroundColor: 'rgb(255, 204, 102)',
                borderColor: 'rgb(255, 204, 102)',
                lineTension: 0.1,
                fill: false,
                pointHitRadius: 10,
              },
              {
                label: 'Delivery Successful',
                data: deliverySuccessful,
                backgroundColor: 'rgb(140, 26, 255)',
                borderColor: 'rgb(140, 26, 255)',
                lineTension: 0.1,
                fill: false,
                pointHitRadius: 10,
              },
              {
                label: 'Failed Delivery',
                data: failedDelivery,
                backgroundColor: 'rgb(0, 102, 51)',
                borderColor: 'rgb(0, 102, 51)',
                lineTension: 0.1,
                fill: false,
                pointHitRadius: 10,
              },
            ],
          },
          tableData: filteredResponseData,
        };

        filteredResponseData.push(
          {
            branch,
            claims: totalClaims,
            delivery_successful: totalDeliverySuccessful,
            fd_in_branch: totalFDInBranch,
            fd_in_main_hub: totalFDInMain,
            fd_in_transit_for_return: totalFDInTransitForReturn,
            fd_in_transit_to_main: totalFDInTransitToMain,
            fd_returned: totalFDReturned,
            for_transfer: totalForTransfer,
            in_transit_to_branch: totalInTransitToBranch,
            ongoing_deliveries: totalOngoingDeliveries,
            open: totalOpen,
            package_handover_date: 'Total',
            volume: totalVolume,
          },
        );
      });
      this.setState({
        backlogs: parsedResponseData,
        selectedBranches,
        isFetching: false,
      });
    });
  }

  searchOption(e, type = 'destination') {
    const { doFetchOptionsLike } = this.props;

    if (type === 'client') {
      this.setState({
        isClientOptionsLoading: true,
      });

      doFetchOptionsLike('client', e).then((action) => {
        this.setState({
          clientOptions: action.payload.data.slice(0, 50),
          isClientOptionsLoading: false,
        });
      });
    } else {
      this.setState({
        isBranchOptionsLoading: true,
      });

      doFetchOptionsLike('destination', e).then((action) => {
        this.setState({
          branchOptions: action.payload.data.slice(0, 50),
          isBranchOptionsLoading: false,
        });
      });
    }
  }

  branchHandler(e) {
    this.setState({
      selectedBranches: [...e],
    });
  }

  clientHandler(e) {
    this.setState({
      selectedClients: [...e],
    });
  }

  downloadSet(date, reference, branch) {
    this.setState({ isFetching: true });
    const { doDashboardBacklogsDownload } = this.props;
    const { dateHandler, selectedClients: clients } = this.state;
    let dateFrom = moment(date).format('YYYY-MM-DD 00:00:00');
    let dateTo = moment(date).format('YYYY-MM-DD 23:59:59');
    if (date === 'Total') {
      dateFrom = dateHandler.from;
      dateTo = dateHandler.to;
    }
    const params = {
      from: dateFrom,
      to: dateTo,
      branch: [branch],
      reference,
      clients,
    };

    doDashboardBacklogsDownload(params).then((res) => {
      const newBlob = new Blob([res.payload.data]);
      fileDownload(newBlob, `backlogs-${reference}.csv`);
      this.setState({
        isFetching: false,
      });
      message.success('The file is successfully downloaded!');
    });
  }

  downloadOverall(branch) {
    const { dateHandler, selectedClients: clients } = this.state;
    const { doDashboardBacklogsDownloadOverall } = this.props;
    const params = {
      from: dateHandler.from,
      to: dateHandler.to,
      branch: [branch],
      clients,
    };
    doDashboardBacklogsDownloadOverall(params).then((res) => {
      const newBlob = new Blob([res.payload.data]);
      fileDownload(newBlob, `backlogs-${dateHandler.from}_${dateHandler.to}.csv`);
      this.setState({
        isFetching: false,
      });
      message.success('The file is successfully downloaded!');
    });
  }

  dateHandler(date) {
    this.setState({
      dateHandler: {
        from: date[0].format('YYYY-MM-DD HH:mm:ss'),
        to: date[1].format('YYYY-MM-DD HH:mm:ss'),
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  printBacklogs(branch) {
    const content = document.getElementById(branch);
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  renderBranchPrintView(branch) {
    const { backlogs } = this.state;
    const branchBacklogs = backlogs[branch];
    return (
      <React.Fragment>
        <div style={{ display: 'none' }} id={branch}>
          <div style={{ marginBottom: '10px' }}>
            <center>
              <strong style={{ fontSize: '14px' }}>
                Backlogs Monitoring
                {' '}
                {branch}
                {' '}
                Branch
              </strong>
            </center>
          </div>
          <table className="tableStyle" style={styleTable()}>
            <thead>
              <tr>
                <th style={styleTable()}>Handover Date</th>
                <th style={styleTable()}>Volume</th>
                <th style={styleTable()}>For Transfer</th>
                <th style={styleTable()}>In Transit to Branch</th>
                <th style={styleTable()}>Open</th>
                <th style={styleTable()}>Ongoing Deliveries</th>
                <th style={styleTable()}>Delivery Successful</th>
                <th style={styleTable()}>FD in Branch</th>
                <th style={styleTable()}>FD in Transit to Main</th>
                <th style={styleTable()}>FD in Main Hub</th>
                <th style={styleTable()}>FD in Transit for Return</th>
                <th style={styleTable()}>FD Returned</th>
                <th style={styleTable()}>Claims</th>
              </tr>
            </thead>
            <tbody>
              {branchBacklogs ? branchBacklogs.tableData.map(backlog => (
                <tr key={backlog.package_handover_date}>
                  <td style={styleTable()}>
                    {backlog.package_handover_date}
                  </td>
                  <td style={styleTable()}>{backlog.volume}</td>
                  <td style={styleTable()}>{backlog.for_transfer}</td>
                  <td style={styleTable()}>{backlog.in_transit_to_branch}</td>
                  <td style={styleTable()}>{backlog.open}</td>
                  <td style={styleTable()}>{backlog.ongoing_deliveries}</td>
                  <td style={styleTable()}>{backlog.delivery_successful}</td>
                  <td style={styleTable()}>{backlog.fd_in_branch}</td>
                  <td style={styleTable()}>
                    {backlog.fd_in_transit_to_main}
                  </td>
                  <td style={styleTable()}>{backlog.fd_in_main_hub}</td>
                  <td style={styleTable()}>
                    {backlog.fd_in_transit_for_return}
                  </td>
                  <td style={styleTable()}>{backlog.fd_returned}</td>
                  <td style={styleTable()}>{backlog.claims}</td>
                </tr>
              )) : null}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }

  renderAllBranchPrintView() {
    const { backlogs } = this.state;
    const branches = Object.keys(backlogs);
    const allBacklogs = branches.map(branch => (
      <React.Fragment>
        <strong>
            Branch:
          {branch}
        </strong>
        <table className="tableStyle" style={styleTable()}>
          <thead>
            <tr>
              <th style={styleTable()}>Handover Date</th>
              <th style={styleTable()}>Volume</th>
              <th style={styleTable()}>For Transfer</th>
              <th style={styleTable()}>In Transit to Branch</th>
              <th style={styleTable()}>Open</th>
              <th style={styleTable()}>Ongoing Deliveries</th>
              <th style={styleTable()}>Delivery Successful</th>
              <th style={styleTable()}>FD in Branch</th>
              <th style={styleTable()}>FD in Transit to Main</th>
              <th style={styleTable()}>FD in Main Hub</th>
              <th style={styleTable()}>FD in Transit for Return</th>
              <th style={styleTable()}>FD Returned</th>
              <th style={styleTable()}>Claims</th>
            </tr>
          </thead>
          <tbody>
            {backlogs[branch].tableData.map(backlog => (
              <tr key={backlog.package_handover_date}>
                <td style={styleTable()}>
                  {backlog.package_handover_date}
                </td>
                <td style={styleTable()}>{backlog.volume}</td>
                <td style={styleTable()}>{backlog.for_transfer}</td>
                <td style={styleTable()}>{backlog.in_transit_to_branch}</td>
                <td style={styleTable()}>{backlog.open}</td>
                <td style={styleTable()}>{backlog.ongoing_deliveries}</td>
                <td style={styleTable()}>{backlog.delivery_successful}</td>
                <td style={styleTable()}>{backlog.fd_in_branch}</td>
                <td style={styleTable()}>
                  {backlog.fd_in_transit_to_main}
                </td>
                <td style={styleTable()}>{backlog.fd_in_main_hub}</td>
                <td style={styleTable()}>
                  {backlog.fd_in_transit_for_return}
                </td>
                <td style={styleTable()}>{backlog.returned}</td>
                <td style={styleTable()}>{backlog.claims}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    ));

    return allBacklogs;
  }

  render() {
    const {
      branchOptions,
      clientOptions,
      isFetching,
      backlogs,
      selectedBranches,
      isBranchOptionsLoading,
      isClientOptionsLoading,
    } = this.state;
    const { user } = this.props;
    const { RangePicker } = DatePicker;
    const breadCrumbs = [
      {
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: 'Backlogs',
      },
    ];

    return (
      <div className="Dashboard">
        {selectedBranches.map(branch => (
          this.renderBranchPrintView(branch)
        ))}
        <div id="printAllTable" style={{ display: 'none' }}>
          <div style={{ marginBottom: '10px' }}>
            <center>
              <strong style={{ fontSize: '14px' }}>
                Backlogs Monitorings
              </strong>
            </center>
          </div>
          {this.renderAllBranchPrintView()}
        </div>

        <iframe
          title="myFrame"
          id="ifmcontentstoprint"
          style={{ height: '0px', width: '0px', position: 'absolute' }}
        />

        <Pageheader title="Backlogs Dashboard" routes={breadCrumbs} />
        <Spacer />
        <Container>
          <div className="dashboardContainer">
            <Card>
              <Row>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Select Date">
                    <RangePicker
                      style={{ width: '100%' }}
                      defaultValue={[
                        moment().subtract(1, 'days').startOf('day'),
                        moment(),
                      ]}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment('00:00:00', 'HH:mm:ss'),
                          moment('00:00:00', 'HH:mm:ss'),
                        ],
                        format: 'HH:mm',
                      }}
                      onChange={e => this.dateHandler(e)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Branches">
                    <ReactSelect
                      defaultValue={[user.port_code]}
                      style={{ width: '100%' }}
                      placeholder="Select branch..."
                      loading={isBranchOptionsLoading}
                      onSearch={e => this.searchOption(e)}
                      options={branchOptions}
                      mode="multiple"
                      onChange={e => this.branchHandler(e)}
                      filterOption={false}
                      showSearch
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Clients">
                    <ReactSelect
                      defaultValue={[1]}
                      style={{ width: '100%' }}
                      placeholder="Type to search for clients..."
                      loading={isClientOptionsLoading}
                      onSearch={e => this.searchOption(e, 'client')}
                      options={clientOptions}
                      mode="multiple"
                      onChange={e => this.clientHandler(e)}
                      filterOption={false}
                      showSearch
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Button type="primary" onClick={() => this.getBacklogs()} block>
                    <Icon type="file-search" />
                    Filter
                  </Button>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Button type="primary" onClick={() => this.printBacklogs('printAllTable')} block>
                    <Icon type="file-search" />
                    Print All
                  </Button>
                </Col>
              </Row>
            </Card>
            <Spacer />
            <Spin
              spinning={isFetching}
              size="small"
              tip="Fetching Backlog Filter. . ."
            >
              {selectedBranches.map(branch => (
                <React.Fragment key={branch}>
                  <Card title={`Chart Branch: ${branch}`}>
                    <LineChart
                      data={backlogs[branch]
                        ? backlogs[branch].chart
                        : { labels: [], datasets: [] }}
                    />
                  </Card>
                  <Spacer />
                  <Card
                    title={`Branch: ${branch}`}
                    extra={(
                      <React.Fragment>
                        <Button
                          onClick={() => this.downloadOverall(
                            branch,
                          )}
                          type="primary"
                          style={{ marginRight: '5px' }}
                        >
                          <Icon type="download" />
                        Download
                        </Button>
                        <Button
                          onClick={() => this.printBacklogs(branch)}
                          type="primary"
                        >
                          <Icon type="printer" />
                        Print Backlogs Report
                        </Button>
                      </React.Fragment>

)}
                  >
                    <table className="tableStyle">
                      <thead>
                        <tr>
                          <th>Handover Date</th>
                          <th>Volume</th>
                          <th>For Transfer</th>
                          <th>In Transit to Branch</th>
                          <th>Open</th>
                          <th>Ongoing Deliveries</th>
                          <th>Delivery Successful</th>
                          <th>FD in Branch</th>
                          <th>FD in Transit to Main</th>
                          <th>FD in Main Hub</th>
                          <th>FD in Transit for Return</th>
                          <th>FD Returned</th>
                          <th>Claims</th>
                        </tr>
                      </thead>
                      <tbody>
                        {backlogs[branch] ? backlogs[branch].tableData.map(data => (
                          <tr key={data.package_handover_date}>
                            <td>{data.package_handover_date}</td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'volume',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{data.volume}</Button>
                              </Popconfirm>
                            </td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'for_transfer',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">
                                  {data.for_transfer}
                                </Button>
                              </Popconfirm>
                            </td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'in_transit_to_branch',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">
                                  {data.in_transit_to_branch}
                                </Button>
                              </Popconfirm>
                            </td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'open',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{data.open}</Button>
                              </Popconfirm>
                            </td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'ongoing_deliveries',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">
                                  {data.ongoing_deliveries}
                                </Button>
                              </Popconfirm>
                            </td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'delivery_successful',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">
                                  {data.delivery_successful}
                                </Button>
                              </Popconfirm>
                            </td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'fd_in_branch',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">
                                  {data.fd_in_branch}
                                </Button>
                              </Popconfirm>
                            </td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'fd_in_transit_to_main',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">
                                  {data.fd_in_transit_to_main}
                                </Button>
                              </Popconfirm>
                            </td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'fd_in_main_hub',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">
                                  {data.fd_in_main_hub}
                                </Button>
                              </Popconfirm>
                            </td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'fd_in_transit_for_return',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">
                                  {data.fd_in_transit_for_return}
                                </Button>
                              </Popconfirm>
                            </td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'fd_returned',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">
                                  {data.fd_returned}
                                </Button>
                              </Popconfirm>
                            </td>
                            <td>
                              <Popconfirm
                                placement="topRight"
                                title="Are you sure to download this item?"
                                onConfirm={() => this.downloadSet(
                                  data.package_handover_date,
                                  'claims',
                                  data.branch,
                                )
                                      }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="link">{data.claims}</Button>
                              </Popconfirm>
                            </td>
                          </tr>
                        )) : null}
                      </tbody>
                    </table>
                  </Card>
                </React.Fragment>
              ))}
            </Spin>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  doFetchOptionsLike: fetchOptionsLike,
  doDashboardBacklogs: dashboardBacklogs,
  doDashboardBacklogsDownload: dashboardBacklogsDownload,
  doFetchOptions: fetchOptions,
  doDashboardBacklogsDownloadOverall: dashboardBacklogsDownloadOverall,
};
Backlogs.propTypes = {
  doFetchOptionsLike: PropTypes.func.isRequired,
  doDashboardBacklogs: PropTypes.func.isRequired,
  doDashboardBacklogsDownload: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doDashboardBacklogsDownloadOverall: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Backlogs);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Descriptions,
  Button, Icon, Spin,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import DispatchView from '../../components/DispatchView';
import { fetchCustomerPickupDetails } from './newDucks';

const styleTableHeader = width => ({
  borderCollapse: 'collapse',
  border: '1px solid black',
  fontSize: '12px',
  padding: '5px',
  width,
});

export class CustomerPickupView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchDetails: {},
      isMountingModule: true,
    };
  }

  componentDidMount() {
    const { doFetchCustomerPickupDetails, match } = this.props;

    doFetchCustomerPickupDetails(match.params.id).then((action) => {
      this.setState({
        dispatchDetails: action.payload.data,
        isMountingModule: false,
      });
    });
  }

  printDispatch() {
    const content = document.getElementById('divcontents');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
    // const { dataTablePickup } = this.state;
    // console.log(dataTablePickup.map(res => (res)));
  }

  render() {
    const { dispatchDetails, isMountingModule } = this.state;
    const { match } = this.props;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Customer Pickup',
      },
      {
        breadcrumbName: 'List',
      },
      {
        breadcrumbName: `View ${match.params.id}`,
      },
    ];

    const dispatchPackageListColumns = [
      {
        id: 'tracking_number',
        Header: 'Tracking Number',
        filterable: false,
        sortable: false,
        accessor: data => (data.details ? data.details.tracking_number : ''),
      },
      {
        id: 'consignee_name',
        Header: 'Consignee Name',
        filterable: false,
        sortable: false,
        accessor: data => (data.details ? data.details.shipper.shipper_name : ''),
      },
      {
        id: 'pickup_location',
        Header: 'Pickup Location',
        filterable: false,
        sortable: false,
        accessor: data => (data.details ? data.details.shipper.shipper_address : ''),
      },
      {
        id: 'pickup_contact',
        Header: 'Pickup Contact',
        filterable: false,
        sortable: false,
        accessor: data => (data.details ? data.details.shipper.shipper_contact : ''),
      },
    ];
    // console.log(dispatchDetails);
    return (
      <div className="CustomerPickupView">
        <div style={{ display: 'none' }} id="divcontents">
          <center>
            <strong style={{ fontSize: '14px' }}>PICKUP ROUTE SHEET</strong>
          </center>
          <div style={{ marginBottom: '20px' }}>
            <img
              style={{ float: 'left' }}
              src="http://portal.xdelogistics.com/public/img/xde-logo.png"
              alt="xdeLogo"
            />
            <div
              style={{
                float: 'left',
                paddingTop: '15px',
                marginLeft: '20px',
                width: '570px',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>XDE Logistics</strong>
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                Ximex Building, #9B , Industrial Complex, Bagumbayan, Bicutan, 1st Ave, Taguig, 1631
                Metro Manila
              </p>
            </div>
          </div>
          <div
            style={{
              content: '',
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                width: '33%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
                float: 'left',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Pickup ID: </strong>
                {match.params.id}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Vehicle/Plate Number: </strong>
                {dispatchDetails.vehicle ? dispatchDetails.vehicle.vehicle_name : ''}
                {'/'}
                {dispatchDetails.vehicle ? dispatchDetails.vehicle.plate_number : ''}
              </p>
            </div>
            <div
              style={{
                width: '33%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Created Date: </strong>
                {dispatchDetails.created_at ? dispatchDetails.created_at : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Operator: </strong>
                {dispatchDetails.driver ? dispatchDetails.driver.firstname : ''}
                {' '}
                {dispatchDetails.driver ? dispatchDetails.driver.lastname : ''}
              </p>
            </div>
            <div
              style={{
                width: '33%',
                display: 'inline-block',
                verticalAlign: 'middle',
                textAlign: 'middle',
              }}
            >
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Expected Arrival: </strong>
                {dispatchDetails.expected_arrival ? dispatchDetails.expected_arrival : ''}
              </p>
              <p style={{ fontSize: '12px', margin: '5px' }}>
                <strong>Mobile Number: </strong>
                {dispatchDetails.driver ? dispatchDetails.driver.mobile_no : ''}
              </p>
            </div>
          </div>
          <div
            style={{
              content: '',
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '100%',
              marginBottom: '15px',
            }}
          >
            <p style={{ fontSize: '12px', margin: '5px' }}>
              <strong>Remarks: </strong>
              {dispatchDetails.remarks ? dispatchDetails.remarks : ''}
            </p>
          </div>
          <div style={{ marginBottom: '30px' }}>
            <table style={{ borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={styleTableHeader('20%')}>Waybill #</th>
                  <th style={styleTableHeader('20%')}>Pickup Name</th>
                  <th style={styleTableHeader('10%')}>Pickup Contact</th>
                  <th style={styleTableHeader('50%')}>Item</th>
                  <th style={styleTableHeader('10%')}>Signature</th>
                </tr>
              </thead>
              <tbody>
                {
                  dispatchDetails.packages
                    ? dispatchDetails.packages.map((val, key) => (
                      <React.Fragment key={key}>
                        <tr key={`${key}a`}>
                          <td style={styleTableHeader('20%')}>{val.tracking_number}</td>
                          <td style={styleTableHeader('20%')}>{val.details ? val.details.shipper.shipper_name : ''}</td>
                          <td style={styleTableHeader('10%')}>{val.details ? val.details.shipper.shipper_contact : ''}</td>
                          <td style={styleTableHeader('50%')}>{val.details ? val.details.description : ''}</td>
                          <td style={styleTableHeader('10%')}>{val.details ? val.details.signature : ''}</td>
                          <tr>
                            <td
                              colSpan={24}
                              style={{
                                borderCollapse: 'collapse', border: '1px solid black', fontSize: '12px', padding: '5px',
                              }}
                            >
                              <strong>Pickup Location:</strong>
                              {val.details ? val.details.shipper.shipper_address : ''}
                            </td>
                          </tr>
                        </tr>
                      </React.Fragment>
                    )) : null
                }
              </tbody>
            </table>
          </div>
          <p style={{ fontSize: '12px', margin: '5px' }}>
            {'Total Transaction:'}
            {' '}
            <span style={{ color: 'red' }}>{dispatchDetails.packages ? dispatchDetails.packages.length : 0}</span>
          </p>
          <div style={{ marginTop: '40px' }}>
            <strong style={{ fontSize: '12px', float: 'left' }}>
              {'Signature of Warehouse Representative'}
            </strong>
            <strong style={{ fontSize: '12px', float: 'left', marginLeft: '250px' }}>
              {'Signature of Operator'}
            </strong>
          </div>
        </div>
        <iframe
          title="myFrame"
          id="ifmcontentstoprint"
          style={{ height: '0px', width: '0px', position: 'absolute' }}
        />
        <Spin spinning={isMountingModule} size="small" tip="Fetching customer pickup details. . .">
          <PageHeader title={`Customer Pickup #${match.params.id}`} routes={breadCrumbs} />
          <Container>
            <DispatchView
              dispatchTitle="Customer Pickup Information"
              dispatchPackageListColumns={dispatchPackageListColumns}
              dispatchItems={dispatchDetails.packages}
              hasExport
              exportType="pickup_dispatch"
              dispatchExportName="Customer-Pickup"
              exportId={match.params.id}
              dispatchDetails={(
                <React.Fragment>
                  <Descriptions bordered size="small">
                    <Descriptions.Item label="Vehicle: ">{dispatchDetails.vehicle ? `${dispatchDetails.vehicle.vehicle_name} / ${dispatchDetails.vehicle.plate_number}` : ''}</Descriptions.Item>
                    <Descriptions.Item label="Destination Address: " span={2}>{dispatchDetails.packages ? (dispatchDetails.packages[0].details ? dispatchDetails.packages[0].details.consignee_address : '') : ''}</Descriptions.Item>
                    <Descriptions.Item label="Pickup Date: ">{dispatchDetails ? dispatchDetails.created_at : ''}</Descriptions.Item>
                    <Descriptions.Item label="Expected Pickup Time: ">{dispatchDetails ? dispatchDetails.expected_arrival : ''}</Descriptions.Item>
                    <Descriptions.Item label="Processed by: ">{dispatchDetails.creator ? `${dispatchDetails.creator.firstname} ${dispatchDetails.creator.lastname}` : ''}</Descriptions.Item>
                    <Descriptions.Item label="Destination Point: ">{dispatchDetails.packages ? (dispatchDetails.packages[0].details ? dispatchDetails.packages[0].details.address_city : '') : ''}</Descriptions.Item>
                    <Descriptions.Item label="Type: ">{dispatchDetails ? dispatchDetails.type : ''}</Descriptions.Item>
                    <Descriptions.Item label="Remarks: ">{dispatchDetails.remarks ? dispatchDetails.remarks : ''}</Descriptions.Item>
                  </Descriptions>
                </React.Fragment>
              )}
              printTypes={(
                <React.Fragment>
                  <Button onClick={() => this.printDispatch()} type="primary" block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faPrint} fixedWidth />
                    </Icon>
                      Print
                  </Button>
                </React.Fragment>
              )}
            />
          </Container>
        </Spin>
      </div>
    );
  }
}

CustomerPickupView.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchCustomerPickupDetails: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchCustomerPickupDetails: fetchCustomerPickupDetails,
};

export default connect(null, mapDispatchToProps)(CustomerPickupView);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faEye, faTrash, faArrowRight, faList,
} from '@fortawesome/free-solid-svg-icons';
import {
  Button, Icon, Col, Form, Input, Tag, Modal, Descriptions, Checkbox, message,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Row from '../../components/Row';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import { itemTrackerByReference, itemTrackerByTrackingNumber, generateBarcode } from './ducks';
// import { fetchOptions, fetchOptionsLike } from '../ducks';

export class Barcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isVisibleConfirmModal: false,
      itemsForTag: [],
      selectAllChecker: null,
      sucesstagTable: [],
      isVisible: false,
      dataforView: null,
      dataFilter: [],
      dataFailedFilter: [],
      isDownloading: false,
    };
  }

  setAddTag(e, itemChecked) {
    // console.log(e);
    const { itemsForTag } = this.state;

    if (e.target.checked) {
      this.setState(prevState => ({
        itemsForTag: [
          ...prevState.itemsForTag,
          {
            ...itemChecked,
            checker: e.target.checked,
          },
        ],
      }));
    } else {
      const itemTag = itemsForTag.filter(res => res.item_id !== itemChecked.item_id);

      this.setState({
        itemsForTag: itemTag,
      });
    }
  }

  removeTag(removeItems) {
    const { sucesstagTable, itemsForTag } = this.state;

    const removeSuccessItems = sucesstagTable.filter(res => res.item_id !== removeItems);
    const removeSuccessItemsTag = itemsForTag.filter(res => res.item_id !== removeItems);
    this.setState({
      sucesstagTable: removeSuccessItems,
      itemsForTag: removeSuccessItemsTag,
    });
  }

  checkAll(e) {
    const { dataFilter } = this.state;
    if (e === 'check') {
      dataFilter.forEach((item) => {
        this.setState(prevState => ({
          itemsForTag: [
            ...prevState.itemsForTag,
            {
              ...item,
              checker: true,
            },
          ],
          selectAllChecker: true,
        }));
      });
    } else {
      this.setState({
        itemsForTag: [],
        selectAllChecker: false,
      });
    }
  }

  successTag() {
    const { itemsForTag, sucesstagTable } = this.state;
    // console.log(data)
    const removeDuplicates = Array.from(new Set(itemsForTag.map(a => a.item_id))).map(id => itemsForTag.find(a => a.item_id === id));
    // console.log(removeDuplicates);
    removeDuplicates.forEach((item) => {
      const filteredItems = sucesstagTable.filter(res => res.item_id === item.item_id);
      if (!filteredItems.length > 0) {
        this.setState(prevState => ({
          sucesstagTable: [
            ...prevState.sucesstagTable,
            item,
          ],
        }));
      }
    });

    this.setState({
      dataFilter: [],
      isVisibleConfirmModal: false,
      selectAllChecker: null,
    });
  }

  openViewModal(type, rows) {
    if (type === 'viewItemInfo') {
      this.setState({
        isVisible: true,
        dataforView: rows,
      });
    }
  }

  closeViewModal(type) {
    if (type === 'viewItemInfo') {
      this.setState({
        isVisible: false,
        dataforView: null,
      });
    } else {
      this.setState({
        isVisibleConfirmModal: false,
        dataFilter: [],
      });
    }
  }

  XformSubmit() {
    const { form, doItemTrackerByReference, doItemTrackerByTrackingNumber } = this.props;
    const { validateFields } = form;

    validateFields(['tracking_number', 'reference'], (err, values) => {
      if (!err) {
        this.setState({
          isLoading: true,
        });

        if (values.tracking_number) {
          const trackingNumber = values.tracking_number;
          const splitTrackingNumber = trackingNumber.split('\n');

          const filteredTrackingNumber = splitTrackingNumber.filter(
            (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
          );

          filteredTrackingNumber.forEach((item) => {
            doItemTrackerByTrackingNumber(item, 'for_barcoding').then((res) => {
              const { dataFilter: dataFilterTNSuccess, dataFailedFilter: dataFailedTNFilter } = this.state;
              /* eslint-disable */
              const itemIDFilter = new Set(dataFilterTNSuccess.map(({ item_id }) => item_id));
              const dataFilter = [
                ...dataFilterTNSuccess,
                ...res.payload.data.success.filter(({ item_id }) => !itemIDFilter.has(item_id)),
              ];

              const failedObject = res.payload.data.failed;
              const failedArray = Object.keys(failedObject).map(i => failedObject[i]);

              const dataFailedFilter = [...dataFailedTNFilter, ...failedArray];

              this.setState({
                dataFilter,
                dataFailedFilter,
                isLoading: false,
              });
            });
          });

          this.setState({
            isVisibleConfirmModal: true,
          });
        }

        if (values.reference) {
          const referenceNumber = values.reference;
          const splitReferenceNumber = referenceNumber.split('\n');

          const filteredReferenceNumber = splitReferenceNumber.filter(
            (item, key) => splitReferenceNumber.indexOf(item) >= key && item !== '',
          );

          filteredReferenceNumber.forEach((item) => {
            doItemTrackerByReference(item, 'for_barcoding').then((res) => {
              const { dataFilter: dataFilterRNSuccess, dataFailedFilter: dataFailedRNFilter } = this.state;
              /* eslint-disable */
              const itemIDFilter = new Set(dataFilterRNSuccess.map(({ item_id }) => item_id));
              const dataFilter = [
                ...dataFilterRNSuccess,
                ...res.payload.data.success.filter(({ item_id }) => !itemIDFilter.has(item_id)),
              ];

              const failedObject = res.payload.data.failed;
              const failedArray = Object.keys(failedObject).map(i => failedObject[i]);

              const dataFailedFilter = [...dataFailedRNFilter, ...failedArray];

              this.setState({
                dataFilter,
                dataFailedFilter,
                isLoading: false,
              });
            });
          });

          this.setState({
            isVisibleConfirmModal: true,
          });
        }
      }
    });
  }

  sendFormArray() {
    const { doGenerateBarcode } = this.props;

    const { sucesstagTable } = this.state;
    const getItemID = sucesstagTable.map(res => res.item_id);

    if (getItemID.length) {
      this.setState({ isDownloading: true });
      doGenerateBarcode({
        item_id: getItemID
      })
        .then((res) => {
          const file = new Blob([res.payload.data]);
          fileDownload(file, 'Item-barcodes.pdf');
          this.setState({ isDownloading: false });
        })
        .catch((err) => {
          message.error('Server error. Please contact IT for support.');
          this.setState({ isDownloading: false });
        });
    } else {
      message.error('No items selected.');
    }
  }

  render() {
    const {
      isLoading, isVisibleConfirmModal, selectAllChecker, dataFilter, itemsForTag, sucesstagTable, isVisible, dataforView, isDownloading,
    } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Items',
      },
      {
        breadcrumbName: 'Barcode Generator',
      },
    ];

    const successColumnsToBeTagged = [
      {
        Header: 'Tracking Number',
        sortable: false,
        filterable: false,
        accessor: 'tracking_number',
      },
      {
        Header: 'Reference',
        sortable: false,
        filterable: false,
        accessor: 'reference',
      },
      {
        Header: 'Status',
        sortable: false,
        filterable: false,
        accessor: 'status',
      },
      {
        Header: 'Quantity',
        sortable: false,
        filterable: false,
        accessor: 'quantity',
      },
      {
        Header: 'Description',
        sortable: false,
        filterable: false,
        accessor: 'description',
      },
      {
        Header: 'Client Name',
        sortable: false,
        filterable: false,
        accessor: 'client_name',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        width: 280,
        Cell: row => (
          <center>
            <Row>
              <Col xs={24} sm={12} lg={12}>
                <Button
                  type="link"
                  onClick={() => this.openViewModal('viewItemInfo', row.original)}
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faEye} fixedWidth />
                  </Icon>
                  view
                </Button>
              </Col>
              <Col xs={24} sm={12} lg={12}>
                <Button
                  type="link"
                  onClick={() => this.removeTag(row.original.item_id)}
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                  </Icon>
                  remove
                </Button>
              </Col>
            </Row>
          </center>
        ),
      },
    ];

    const successColumns = [
      {
        Header: null,
        sortable: false,
        filterable: false,
        width: 40,
        Cell: row => (
          <Checkbox
            onChange={e => this.setAddTag(e, row.original)}
            checked={itemsForTag.filter(res => res.item_id === row.original.item_id).length !== 0}
          />
        ),
      },
      {
        Header: 'Tracking Number',
        sortable: true,
        filterable: true,
        accessor: 'tracking_number',
      },
      {
        Header: 'Reference',
        sortable: true,
        filterable: true,
        accessor: 'reference',
      },
      {
        Header: 'Description',
        sortable: true,
        filterable: true,
        accessor: 'description',
      },
      {
        Header: 'Status',
        sortable: true,
        filterable: true,
        accessor: 'status',
      },
      {
        Header: 'Client Name',
        sortable: true,
        filterable: true,
        accessor: 'client_name',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        Cell: row => (
          <center>
            <Button
              type="link"
              onClick={() => this.openViewModal('viewItemInfo', row.original)}
            >
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faEye} fixedWidth />
              </Icon>
              view
            </Button>
          </center>
        ),
      },
    ];

    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div className="Barcode">
        <Modal
          title="View Items Information"
          closable
          onCancel={() => this.closeViewModal('viewItemInfo')}
          visible={isVisible}
          footer={null}
          width={950}
        >
          <Descriptions bordered size="small">
            <Descriptions.Item label="Tracking Number: "><b>{dataforView ? dataforView.tracking_number : ' '}</b></Descriptions.Item>
            <Descriptions.Item label="Reference: "><Tag color="orange">{dataforView ? dataforView.reference : ' '}</Tag></Descriptions.Item>
            <Descriptions.Item label="Client Name: ">{dataforView ? dataforView.client_name : ' '}</Descriptions.Item>
            <Descriptions.Item label="Quantity: ">{dataforView ? dataforView.quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="UOM: ">{dataforView ? dataforView.uom : ' '}</Descriptions.Item>
            <Descriptions.Item label="Length: ">{dataforView ? dataforView.length : ' '}</Descriptions.Item>
            <Descriptions.Item label="Width: ">{dataforView ? dataforView.width : ' '}</Descriptions.Item>
            <Descriptions.Item label="Height: ">{dataforView ? dataforView.height : ' '}</Descriptions.Item>
            <Descriptions.Item label="Weight: ">{dataforView ? dataforView.weight : ' '}</Descriptions.Item>
            <Descriptions.Item label="Volume: ">{dataforView ? dataforView.volume : ' '}</Descriptions.Item>
            <Descriptions.Item label="Value: ">{dataforView ? dataforView.value : ' '}</Descriptions.Item>
            <Descriptions.Item label="Comment: ">{dataforView ? dataforView.comment : ' '}</Descriptions.Item>
            <Descriptions.Item label="Status: "><Tag color="orange">{dataforView ? dataforView.status : ' '}</Tag></Descriptions.Item>
            <Descriptions.Item label="Current Location: ">{dataforView ? dataforView.current_location : ' '}</Descriptions.Item>
            <Descriptions.Item label="Handover Quantity: ">{dataforView ? dataforView.handover_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Deliverd Quantity: ">{dataforView ? dataforView.delivered_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Returned Quantity: ">{dataforView ? dataforView.returned_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Claims Quantity: ">{dataforView ? dataforView.claims_quantity : ' '}</Descriptions.Item>
            <Descriptions.Item label="Description: ">{dataforView ? dataforView.description : ' '}</Descriptions.Item>
          </Descriptions>
        </Modal>
        <Modal
          title={`Total Success Items after add item: ${dataFilter.length}`}
          onCancel={() => this.closeViewModal()}
          closable
          footer={null}
          visible={isVisibleConfirmModal}
          width
          style={{ top: 20 }}
        >
          <Card title={
            !selectAllChecker
              ? (
                <Checkbox checked={false} onClick={() => this.checkAll('check')}>Select All</Checkbox>
              ) : (
                <Checkbox checked onClick={() => this.checkAll('uncheck')}>Unselect All</Checkbox>
              )
          }
          >
            <ReactTable
              data={dataFilter}
              columns={successColumns}
              loading={isLoading}
            />
            <br />
            <Button type="primary" onClick={() => this.successTag()} block>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faList} fixedWidth />
              </Icon>
              Add to List
            </Button>
          </Card>
        </Modal>
        <PageHeader title="Barcode Generate" routes={breadCrumbs} />
        <Container>
          <Card>
            <Form>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Tracking Number">
                    {getFieldDecorator('tracking_number', {
                      rules: [
                        {
                          required: false,
                          message: 'Package number cannot be blank.',
                        },
                      ],
                    })(
                      <Input.TextArea
                        placeholder="Enter Tracking Number"
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Reference Number">
                    {getFieldDecorator('reference', {
                      rules: [
                        {
                          required: false,
                          message: 'Reference number cannot be blank.',
                        },
                      ],
                    })(
                      <Input.TextArea
                        placeholder="Enter Reference Number"
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Button type="primary" onClick={() => this.XformSubmit()} block>
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                  </Icon>
                    Add Item
                </Button>
              </Row>
            </Form>
          </Card>
          <Spacer />
          <Card
            title={`Total Items: ${sucesstagTable.length}`}
          >
            <ReactTable
              data={sucesstagTable}
              columns={successColumnsToBeTagged}
              loading={false}
            />
            <br />
            {sucesstagTable.length !== 0
              ? (
                <Button type="primary" onClick={() => this.sendFormArray()} block disabled={isDownloading}>
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                  </Icon>
                    Continue
                </Button>
              ) : (
                <Button type="primary" disabled block>
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                  </Icon>
                    Continue
                </Button>
              )
              }
          </Card>
        </Container>
      </div>
    );
  }
}

Barcode.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doItemTrackerByReference: PropTypes.func.isRequired,
  doItemTrackerByTrackingNumber: PropTypes.func.isRequired,
  doGenerateBarcode: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doItemTrackerByReference: itemTrackerByReference,
  doItemTrackerByTrackingNumber: itemTrackerByTrackingNumber,
  doGenerateBarcode: generateBarcode,
};

const WrappedBarcode = Form.create({ name: 'create' })(Barcode);
export default connect(null, mapDispatchToProps)(WrappedBarcode);

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LoginPage from '../pages/auth/Login';
import LogoutPage from '../pages/auth/Logout';
import MainLayout from './MainLayout';

const Routes = (props) => {
  const { user } = props;

  return (
    <Switch>
      {user ? (
        <Switch>
          <Route path="/logout" component={LogoutPage} />
          <Route path="/" component={MainLayout} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Redirect from="/" to="/login" />
        </Switch>
      )}
    </Switch>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

Routes.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
};

Routes.defaultProps = {
  user: null,
};

export default connect(
  mapStateToProps,
  null,
)(Routes);

import { viewPickups } from '../../services/api';

const PICKUP_ACCOUNT_VIEW = 'viewPickups/PICKUP_ACCOUNT_VIEW';
const PICKUP_ACCOUNT_VIEW_SUCCESS = 'viewPickups/PICKUP_ACCOUNT_VIEW_SUCCESS';

export const getPickupViews = (page, id) => ({
  type: PICKUP_ACCOUNT_VIEW,
  payload: {
    client: 'secure',
    request: {
      ...viewPickups(page, id),
    },
  },
});

const initialState = {
  views: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case PICKUP_ACCOUNT_VIEW_SUCCESS:
      return {
        ...state,
        views: action.payload.data,
      };

    default:
      return state;
  }
}

export default reducer;

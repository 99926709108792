import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  Button, message, Icon, Popconfirm,
} from 'antd';
import moment from 'moment';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import StatusTaggingForm from '../../components/StatusTaggingForm';
import { fetchTransmittalTrackingNumber, submitTransmittal } from '../ducks';

export class MerchantPickupReleased extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingNumberRows: [],
      errors: [],
    };

    this.validatePackage = this.validatePackage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
  }

  submitForm(values) {
    const { trackingNumberRows } = this.state;
    const { doSubmitTransmittal, history } = this.props;
    const packageList = [];

    // eslint-disable-next-line no-unused-vars
    trackingNumberRows.forEach((item, key) => {
      packageList.push(item.tracking_number);
    });

    const payload = {
      packages: packageList,
      // from: moment(values.dateFrom).format('YYYY-MM-DD'),
      // to: moment(values.dateTo).format('YYYY-MM-DD'),
      from: values.dateFrom,
      to: values.dateTo,
      remarks: values.comment,
      type: 'released',
      via: values.mode,
    };

    doSubmitTransmittal(payload)
      .then((action) => {
        this.setState({
          trackingNumberRows: [],
        });

        history.push(
          `/merchant-pickup/transmittal/${action.payload.data.transmittal_id}`,
        );
      })
      .catch(() => {
        message.error('Something went wrong, please try again later.');
      });
  }

  validatePackage(values) {
    const { trackingNumberRows } = this.state;
    const { doFetchTransmittalTrackingNumber } = this.props;

    const splitTrackingNumber = values.trackingNumber
      ? values.trackingNumber.split('\n')
      : '';
    const filteredTrackingNumber = splitTrackingNumber.filter(
      (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
    );
    // eslint-disable-next-line no-unused-vars
    filteredTrackingNumber.forEach((item, key) => {
      const exists = trackingNumberRows.filter(
        tn => tn.tracking_number === item,
      );

      if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else {
        doFetchTransmittalTrackingNumber('released', item)
          .then((action) => {
            this.setState(prevState => ({
              trackingNumberRows: [
                ...prevState.trackingNumberRows,
                {
                  tracking_number: action.payload.data.tracking_number,
                  address_city: action.payload.data.address_city,
                  consignee_address: action.payload.data.consignee_address,
                  remarks: values.comment,
                  shipment_provider: action.payload.data.shipment_provider,
                },
              ],
            }));
            message.success('Successfully added tracking number!.');
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 400) {
              errorMessage = action.error.response.data.message;
              this.setState(prevState => ({
                errors: [
                  ...prevState.errors,
                  {
                    tracking_number: item,
                    message: action.error.response.data.message,
                  },
                ],
              }));
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  confirmRemove(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber,
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber,
    });

    message.success('Successfully removed tracking number!');
  }

  render() {
    const { trackingNumberRows, errors } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Transmittal Released',
      },
      {
        breadcrumbName: 'Released',
      },
    ];

    const trackingNumberRowColumns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'City',
        accessor: 'address_city',
      },
      {
        Header: 'Consignee Address',
        accessor: 'consignee_address',
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
      },
      {
        Header: 'Shipment Provider',
        accessor: 'shipment_provider',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        Cell: data => (
          <React.Fragment>
            <Popconfirm
              placement="leftBottom"
              title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
              okText="Yes"
              onConfirm={() => this.confirmRemove(data.row.tracking_number)}
              cancelText="No"
            >
              <Button type="link" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                </Icon>
                Remove
              </Button>
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    return (
      <div className="MerchantPickupReleased">
        <PageHeader title="Transmittal" routes={breadCrumbs} />
        <Container>
          <StatusTaggingForm
            hasDatePicker={false}
            validateHandler={this.validatePackage}
            submitHandler={this.submitForm}
            trackingNumberList={trackingNumberRows}
            trackingNumberListColumns={trackingNumberRowColumns}
            errorList={errors}
            hasTransmittalMode
          />
        </Container>
      </div>
    );
  }
}

MerchantPickupReleased.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchTransmittalTrackingNumber: PropTypes.func.isRequired,
  doSubmitTransmittal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchTransmittalTrackingNumber: fetchTransmittalTrackingNumber,
  doSubmitTransmittal: submitTransmittal,
};

export default connect(null, mapDispatchToProps)(MerchantPickupReleased);

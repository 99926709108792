import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Icon,
  Upload as AntUpload,
  Descriptions,
  Divider,
  Col,
  Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faArrowLeft, faPrint } from '@fortawesome/free-solid-svg-icons';

import { uploadBilling, uploadConfirmation } from './ducks';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ReactTable';
import Row from '../../components/Row';

export class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resPayloadUpload: {
        details: [],
        errors: [],
        status: null,
        total_payment: 0,
        uploaded_by: 0,
      },
    };
  }

  submitUpload() {
    const { doUploadBilling, form } = this.props;
    const { validateFields, setFieldsValue } = form;
    validateFields((err, values) => {
      if (!err) {
        const bodyFormData = new FormData();
        bodyFormData.append('file', values.uploadB.fileList[0].originFileObj);
        doUploadBilling('billing', bodyFormData).then((res) => {
          setFieldsValue({
            uploadB: null,
          });
          this.setState({
            resPayloadUpload: res.payload.data,
          });
        });
      }
    });
  }

  continueUpload() {
    const { resPayloadUpload } = this.state;
    const { doUploadConfirmation, history } = this.props;
    doUploadConfirmation('billing', resPayloadUpload).then(res => history.push(`/billing/details/${res.payload.data.id}`));
  }

  backUpload() {
    this.setState({
      resPayloadUpload: {
        details: [],
        errors: [],
        status: null,
        total_payment: 0,
        uploaded_by: 0,
      },
    });
  }

  render() {
    const { resPayloadUpload } = this.state;
    const { Title } = Typography;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { Dragger } = AntUpload;
    const breadCrumbs = [
      {
        breadcrumbName: 'Billing',
      },
      {
        breadcrumbName: 'Upload',
      },
    ];
    return (
      <div className="Upload">
        <PageHeader title="UPLOAD BILLING" routes={breadCrumbs} />
        <Container>
          <Card>
            <Button
              type="primary"
              shape="round"
              onClick={() => window.open(
                `${process.env.REACT_APP_API_URL}/templates/billing_upload_template.xlsx`,
              )
              }
            >
              Get Template
            </Button>
            <Divider />
            <Descriptions title="This feature is used to mark the tracking numbers as Billed in the system.">
              <Descriptions.Item>
                1. Download the Template and edit the file by entering the correct values.
                <br />
                2.Save the file, then upload the the file by clicking Next.
                <br />
                3.Confirm your report and then click Save.
              </Descriptions.Item>
            </Descriptions>
            {(() => {
              switch (resPayloadUpload.status) {
                case 'new':
                  return (
                    <React.Fragment>
                      <Divider />
                      <Title level={2}>Confirm Upload</Title>
                      <div>
                        <Row>
                          <Col xs={12} sm={12} lg={12}>
                            <strong>Total Payment: </strong>
                            {resPayloadUpload.total_payment}
                          </Col>
                          <Col xs={12} sm={12} lg={12}>
                            <strong>Total Records: </strong>
                            {resPayloadUpload.details.length + resPayloadUpload.errors.length}
                          </Col>
                        </Row>
                      </div>
                      <Card
                        title={`Upload Billing: ${
                          resPayloadUpload.details ? resPayloadUpload.details.length : 0
                        } records`}
                      >
                        <ReactTable
                          data={resPayloadUpload.details}
                          columns={[
                            {
                              Header: 'Tracking Number',
                              accessor: 'tracking_number',
                            },
                            {
                              Header: 'Total Payment',
                              accessor: 'total_payment',
                            },
                            {
                              Header: 'Invoice Number',
                              accessor: 'invoice_number',
                            },
                            {
                              Header: 'Invoice Date',
                              accessor: 'invoice_date',
                            },
                          ]}
                        />
                      </Card>
                      <Card
                        title={`Invalid: ${
                          resPayloadUpload.errors ? resPayloadUpload.errors.length : 0
                        } records`}
                      >
                        <ReactTable
                          data={resPayloadUpload.errors}
                          columns={[
                            {
                              Header: 'Tracking Number',
                              accessor: 'tracking_number',
                            },
                            {
                              Header: 'Error',
                              accessor: 'message',
                            },
                          ]}
                        />
                      </Card>
                      <Divider />
                      <Row>
                        <Col xs={24} sm={12} lg={12}>
                          <Button onClick={() => this.backUpload()} type="danger" block>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faArrowLeft} fixedWidth />
                            </Icon>
                            Back
                          </Button>
                        </Col>
                        <Col xs={24} sm={12} lg={12}>
                          <Button onClick={() => this.continueUpload()} type="primary" block>
                            Continue
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faArrowAltCircleRight} fixedWidth />
                            </Icon>
                          </Button>
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                default:
                  return (
                    <Card>
                      <Form>
                        <Form.Item label="Billing File">
                          {getFieldDecorator('uploadB', {
                            rules: [{ required: true, message: 'Please insert value' }],
                          })(
                            <Dragger
                              beforeUpload={() => false}
                              multiple={false}
                              accept=".csv, .xlsx"
                            >
                              <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                            </Dragger>,
                          )}
                        </Form.Item>
                        <Button onClick={e => this.submitUpload(e)} type="primary" block>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faPrint} fixedWidth />
                          </Icon>
                          Upload
                        </Button>
                      </Form>
                    </Card>
                  );
              }
            })()}
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  doUploadBilling: uploadBilling,
  doUploadConfirmation: uploadConfirmation,
};

Upload.propTypes = {
  doUploadBilling: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doUploadConfirmation: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const WrappedUpload = Form.create({ name: 'Register' })(Upload);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedUpload);

import {
  deliveryList,
  repushStatus,
  remittanceCreateCancel,
  getOptionsDispatch,
} from '../../services/api';

const RTS_LIST = 'rts/RTS_LIST';
const RTS_LIST_SUCCESS = 'rts/RTS_LIST_SUCCESS';

export const viewList = data => ({
  type: RTS_LIST,
  payload: {
    client: 'secure',
    request: {
      ...deliveryList,
      data,
    },
  },
});

const REASON = 'rts/REASON';

export const reasonType = data => ({
  type: REASON,
  payload: {
    client: 'secure',
    request: {
      ...getOptionsDispatch(data),
    },
  },
});

const RTS_PACKAGE_RETURNED_VALIDATE = 'rts/RTS_PACKAGE_RETURNED_VALIDATE';
const RTS_PACKAGE_RETURNED_VALIDATE_SUCCESS = 'rts/RTS_PACKAGE_RETURNED_VALIDATE_SUCCESS';

const RTS_PACKAGE_REFUSED_VALIDATE = 'rts/RTS_PACKAGE_REFUSED_VALIDATE';
const RTS_PACKAGE_REFUSED_VALIDATE_SUCCESS = 'rts/RTS_PACKAGE_REFUSED_VALIDATE_SUCCESS';

const RTS_PACKAGE_CLAIMS_VALIDATE = 'rts/RTS_PACKAGE_CLAIMS_VALIDATE';
const RTS_PACKAGE_CLAIMS_VALIDATE_SUCCESS = 'rts/RTS_PACKAGE_CLAIMS_VALIDATE_SUCCESS';

export const packageValidate = (page, tNumber) => {
  switch (page) {
    case 'returned':
      return {
        type: RTS_PACKAGE_RETURNED_VALIDATE,
        payload: {
          client: 'secure',
          request: {
            ...repushStatus('returned', tNumber),
          },
        },
      };

    case 'refused':
      return {
        type: RTS_PACKAGE_REFUSED_VALIDATE,
        payload: {
          client: 'secure',
          request: {
            ...repushStatus('refused', tNumber),
          },
        },
      };

    case 'claims':
      return {
        type: RTS_PACKAGE_CLAIMS_VALIDATE,
        payload: {
          client: 'secure',
          request: {
            ...repushStatus('claims', tNumber),
          },
        },
      };

    default:
      return false;
  }
};

const RTS_CANCEL = 'rts/RTS_PACKAGE_CANCEL';

export const rtsCancel = tNumber => ({
  type: RTS_CANCEL,
  payload: {
    client: 'secure',
    request: {
      ...remittanceCreateCancel(tNumber),
    },
  },
});

const initialState = {
  tableList: [],
  tableListReturned: [],
  tableListRefused: [],
  tableListClaims: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case RTS_LIST_SUCCESS:
      return {
        ...state,
        tableList: action.payload.data.rows,
      };

    case RTS_PACKAGE_RETURNED_VALIDATE_SUCCESS:
      return {
        ...state,
        tableListReturned: [...state.tableList, action.payload.data],
      };

    case RTS_PACKAGE_REFUSED_VALIDATE_SUCCESS:
      return {
        ...state,
        tableListRefused: [...state.tableList, action.payload.data],
      };

    case RTS_PACKAGE_CLAIMS_VALIDATE_SUCCESS:
      return {
        ...state,
        tableListClaims: [...state.tableList, action.payload.data],
      };

    default:
      return state;
  }
}

export default reducer;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  withRouter, NavLink, Switch, Route, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Layout, Button, Icon, Drawer, Menu, Popover, BackTop,
} from 'antd';
import sidebarItems from '../navigation/sidebarItems';
import appRoutes from '../navigation/appRoutes';
import RouteComponent from '../components/routes/RouteComponent';
import MissingComponent from '../pages/errors/404';
import { fetchOptions } from '../pages/ducks';
import { logout } from '../pages/auth/ducks';

const {
  Header, Footer, Sider, Content,
} = Layout;

const { SubMenu, Item, ItemGroup } = Menu;

function fetchWindowWidth() {
  const { innerWidth: width } = window;

  return width;
}

function UseWindowWidth() {
  const [windowDimensions, setWindowDimensions] = useState(fetchWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(fetchWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function MainLayout(props) {

  const [role, setRole] = useState([]);

  const width = UseWindowWidth();

  useEffect(() => {
    const { doFetchOptions, user, doLogout } = props;

    doFetchOptions('role').then((action) => {
      const filterRole = action.payload.data.filter(item => item.value === user.role_id);
      setRole(filterRole[0]);
    });
    // 1200000 (20 mins timeout)
    var timeoutHandler = window.setTimeout(() => {
      doLogout();
    }, 1200000);

    window.addEventListener("mouseup", (e) => {
      if (e.isTrusted) {
        window.clearTimeout(timeoutHandler);
        timeoutHandler = window.setTimeout(() => {
          doLogout();
        }, 1200000);
      }
    });

    window.addEventListener("keyup", (e) => {
      if (e.isTrusted) {
        window.clearTimeout(timeoutHandler);
        timeoutHandler = window.setTimeout(() => {
          doLogout();
        }, 1200000);
      }
    });

  }, [props]);

  const { user } = props;

  let isLayoutSiderUsable = true;

  if (width <= 768) {
    // Set to false to use drawer component instead of Layout Sider as a sidebar
    isLayoutSiderUsable = false;
  }

  const [isSidebarCollapsed, setDrawerCollapsed] = useState(false);

  const menuItems = [];

  Object.keys(sidebarItems).forEach((root) => {
    const menuSubLayeredItems = [];

    // Uncomment if user roles are set
    //
    if (!sidebarItems[root].roles.includes(user.role)) {
      return null;
    }

    sidebarItems[root].routes.forEach((content) => {
      // Uncomment if user roles are set
      //
      if (!content.roles.includes(user.role)) {
        return null;
      }

      if (content.path) {
        menuSubLayeredItems.push(
          <Item key={content.key}>
            <NavLink to={content.path}>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={content.icon} fixedWidth />
              </Icon>
              <span>{content.text}</span>
            </NavLink>
          </Item>,
        );
      } else {
        menuSubLayeredItems.push(
          <SubMenu
            key={content.key}
            title={(
              <span>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={content.icon} fixedWidth />
                </Icon>
                <span>{content.text}</span>
              </span>
          )}
          >

            {
              content.items.map(subContent => (
                <Item key={subContent.key}>
                  <NavLink to={subContent.path}>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={subContent.icon} fixedWidth />
                    </Icon>
                    <span>{subContent.text}</span>
                  </NavLink>
                </Item>
              ))
            }
          </SubMenu>,
        );
      }
    });

    menuItems.push(
      <ItemGroup
        key={root}
        style={
        {
          textAlign: isSidebarCollapsed && width > 768 ? 'center' : '',
          transition: '0.1s ease-in',
        }
        }
      >
        <ItemGroup key={root} title={root} style={{ fontWeight: 550 }} />
        {menuSubLayeredItems}
      </ItemGroup>,
    );
  });

  return (
    <div>
      {
        isLayoutSiderUsable ? (
          <Layout>
            <Sider
              width={260}
              collapsed={isSidebarCollapsed}
            >
              <div className="app-logo-container" style={{ padding: '16px 40px' }}>
                <img
                  className="app-logo"
                  src="/img/logo-colored.png"
                  alt="Logo"
                  style={{
                    width: !isSidebarCollapsed ? '100px' : '50px',
                    height: !isSidebarCollapsed ? '50px' : '30px',
                    transition: '0.2s ease',
                  }}
                />
              </div>
              <Menu
                mode="inline"
                theme="dark"
              >
                { Object.keys(sidebarItems).map((group) => {
                  if (!sidebarItems[group].roles.includes(user.role_id)) {
                    return null;
                  }

                  // Render menu links
                  const renderedItems = sidebarItems[group].routes.map((route) => {
                    if (!route.roles.includes(user.role_id)) {
                      return null;
                    }

                    // Render menu link directly
                    if (route.path) {
                      return (
                        <Item key={route.key}>
                          <NavLink to={route.path}>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={route.icon} fixedWidth />
                            </Icon>
                            <span>{route.text}</span>
                          </NavLink>
                        </Item>
                      );
                    }

                    return (
                      <SubMenu
                        key={route.key}
                        /* eslint-disable react/jsx-wrap-multilines */
                        title={
                          <span>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={route.icon} fixedWidth />
                            </Icon>
                            <span>{route.text}</span>
                          </span>
                        }
                        /* eslint-enable react/jsx-wrap-multilines */
                      >
                        { route.items.map(item => (item.roles.includes(user.role_id) ? (
                          !item.path && !item.icon ? (
                            <div style={{ marginLeft: 10, margin: 25, color: 'white' }} key={item.key}><strong>{item.text}</strong></div>
                          ) : (
                            <Item key={item.key}>
                              <NavLink
                                to={{ pathname: item.path }}
                              >
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={item.icon} fixedWidth />
                                </Icon>
                                <span>{item.text}</span>
                              </NavLink>
                            </Item>
                          )
                        ) : null))}
                      </SubMenu>
                    );
                  });

                  // Render menu group name
                  return (
                    <ItemGroup
                      className="item-group"
                      key={group}
                      title={group}
                      style={{
                        textAlign: isSidebarCollapsed ? 'center' : 'left',
                        transition: 'all 200ms ease 0ms',
                      }}
                    >
                      {renderedItems}
                    </ItemGroup>
                  );
                })}
              </Menu>
            </Sider>
            <Layout>
              <Header>
                <div className="app-header">
                  <div className="app-left-header">
                    <Button type="link" onClick={() => setDrawerCollapsed(!isSidebarCollapsed)}>
                      <Icon
                        className="icon-screen-collapse"
                        type={isSidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
                      />
                    </Button>
                  </div>
                  <div className="app-right-header">
                    <Popover
                      placement="bottomLeft"
                      trigger="click"
                      content={(
                        <React.Fragment>
                          <p>
                            <span style={{ fontWeight: 600 }}>Username: </span>
                            <span>{user.username}</span>
                          </p>
                          <p>
                            <span style={{ fontWeight: 600 }}>Role: </span>
                            <span>{role !== null ? role.label : ''}</span>
                          </p>
                          <p>
                            <span style={{ fontWeight: 600 }}>Branch: </span>
                            <span>{user.port_code}</span>
                          </p>
                          <Button type="danger" block>
                            <NavLink to="/logout">Logout</NavLink>
                          </Button>
                        </React.Fragment>
                      )}
                    >
                      <div className="right-header-content" style={{ marginRight: 15 }}>
                        <Icon style={{ marginRight: '8px', fontSize: '15px' }} type="smile" />
                        <span>{`${user.firstname} ${user.lastname}`}</span>
                      </div>
                    </Popover>
                  </div>
                </div>
              </Header>
              <Content className="app-container">
                <Switch>
                  {appRoutes.map(route => (route.roles.includes(user.role_id) ? (
                    <RouteComponent
                      key={route.path}
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                    />
                  ) : null))}
                  <Route path="/404" component={MissingComponent} />
                  <Redirect to="/404" />
                </Switch>
                <BackTop />
              </Content>
              <Footer className="app-footer">
                <p className="app-footer-title">Ximex Delivery Express Inc.</p>
                <p className="app-footer-subtitle">
                  IT Development Team -
                  {' '}
                  { process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ' Development' }
                  {' - '}
                  { process.env.REACT_APP_GIT_SHA }
                </p>
              </Footer>
            </Layout>
          </Layout>
        ) : (
          <Layout>
            <Header>
              <div className="app-header">
                <div className="app-left-header" style={{ padding: '0 16px' }}>
                  <img
                    className="app-logo"
                    src="/img/logo-colored.png"
                    alt="Logo"
                    style={{
                      width: '50px',
                      height: '30px',
                      transition: '0.1s ease-in',
                    }}
                  />
                  <Button type="link" onClick={() => setDrawerCollapsed(!isSidebarCollapsed)}>
                    <Icon
                      className="icon-screen-collapse"
                      type={!isSidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
                    />
                  </Button>
                </div>
                <div className="app-right-header" style={{ padding: '0 16px' }}>
                  <Popover
                    placement="bottomLeft"
                    trigger="click"
                    content={(
                      <React.Fragment>
                        <p>
                          <span style={{ fontWeight: 600 }}>Username: </span>
                          <span>{user.username}</span>
                        </p>
                        <p>
                          <span style={{ fontWeight: 600 }}>Role: </span>
                          <span>{role !== null ? role.label : ''}</span>
                        </p>
                        <p>
                          <span style={{ fontWeight: 600 }}>Branch: </span>
                          <span>{user.port_code}</span>
                        </p>
                        <Button type="danger" block>
                          <NavLink to="/logout">Logout</NavLink>
                        </Button>
                      </React.Fragment>
                    )}
                  >
                    <div className="right-header-content">
                      <Icon style={{ marginRight: '8px', fontSize: '15px' }} type="smile" />
                      <span>{`${user.firstname} ${user.lastname}`}</span>
                    </div>
                  </Popover>
                </div>
              </div>
              <Drawer
                title={(
                  <div className="app-drawer-logo">
                    <img
                      className="app-logo"
                      src="/img/logo-colored.png"
                      alt="Logo"
                      style={{
                        width: '80px',
                        height: '40px',
                        transition: '0.1s ease-in',
                      }}
                    />
                  </div>
                )}
                placement="left"
                visible={isSidebarCollapsed}
                closable={false}
                onClose={() => setDrawerCollapsed(!isSidebarCollapsed)}
                zIndex
              >
                <Menu
                  mode="inline"
                  theme="dark"
                >
                  { Object.keys(sidebarItems).map((group) => {
                    if (!sidebarItems[group].roles.includes(user.role_id)) {
                      return null;
                    }

                    // Render menu links
                    const renderedItems = sidebarItems[group].routes.map((route) => {
                      if (!route.roles.includes(user.role_id)) {
                        return null;
                      }

                      // Render menu link directly
                      if (route.path) {
                        return (
                          <Item key={route.key}>
                            <NavLink to={route.path}>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={route.icon} fixedWidth />
                              </Icon>
                              <span>{route.text}</span>
                            </NavLink>
                          </Item>
                        );
                      }

                      return (
                        <SubMenu
                          key={route.key}
                          /* eslint-disable react/jsx-wrap-multilines */
                          title={
                            <span>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={route.icon} fixedWidth />
                              </Icon>
                              <span>{route.text}</span>
                            </span>
                          }
                          /* eslint-enable react/jsx-wrap-multilines */
                        >
                          { route.items.map(item => (item.roles.includes(user.role_id) ? (
                            !item.path && !item.icon ? (
                              <div style={{ marginLeft: 10, margin: 25, color: 'white' }} key={item.key}><strong>{item.text}</strong></div>
                            ) : (
                              <Item key={item.key}>
                                <NavLink
                                  to={{ pathname: item.path }}
                                >
                                  <Icon viewBox="0 0 1024 1024">
                                    <FontAwesomeIcon icon={item.icon} fixedWidth />
                                  </Icon>
                                  <span>{item.text}</span>
                                </NavLink>
                              </Item>
                            )
                          ) : null))}
                        </SubMenu>
                      );
                    });

                    // Render menu group name
                    return (
                      <ItemGroup key={group}>
                        <ItemGroup className="item-group" key={group} title={group} />
                        {renderedItems}
                      </ItemGroup>
                    );
                  })}
                </Menu>
              </Drawer>
            </Header>
            <Content>
              <Switch>
                {appRoutes.map(route => (route.roles.includes(user.role_id) ? (
                  <RouteComponent
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                  />
                ) : null))}
                <Route path="/404" component={MissingComponent} />
                <Redirect to="/404" />
              </Switch>
              <BackTop />
            </Content>
            <Footer className="app-footer">
              <p className="app-footer-title">Ximex Delivery Express Inc.</p>
              <p className="app-footer-subtitle">
                IT Development Team -
                {' '}
                { process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'Development' }
                {' - '}
                { process.env.REACT_APP_GIT_SHA }
              </p>
            </Footer>
          </Layout>
        )
      }
    </div>
  );
}

MainLayout.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptions: PropTypes.func.isRequired,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({
  user: state.auth.user,
  branch: state.auth.branch,
});

const mapDispatchToProps = {
  doFetchOptions: fetchOptions,
  doLogout: logout,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MainLayout),
);

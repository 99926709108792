import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Col,
  Button,
  Divider,
  Input,
  message,
  Modal,
  Form,
  Spin,
} from 'antd';
import PropTypes from 'prop-types';

import { unRemittedViewsAccount } from '../../pages/courierRemittance/ducks';
import {
  createRemittanceTrackingNumber,
  finalRemittance,
  dispatchNumber,
} from './ducks';
import Row from '../Row';
import PageHeader from '../PageHeader';
import ReactTable from '../ReactTable';
import Card from '../Card';
import Spacer from '../Spacer';
import Container from '../Container';

export class CreateDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadSpin: false,
      dispatchData: [],
      dataState: [],
      trackingNumber: null,
      itemScanned: [],
      packageState: [],
      visible: false,
      amount: 0,
      remarks: null,
    };

    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    this.setState({ loadSpin: true });
    const { enterDispatchNumber, match } = this.props;
    enterDispatchNumber(match.params.id).then(res => this.setState({
        dataState: res.payload.data.packages,
        dispatchData: res.payload.data.dispatch,
        loadSpin: false,
      }),);
  }

  changeHandler(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  openModalFinalSubmit() {
    this.setState({
      visible: true,
    });
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  inputHandler(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  addFinalSubmit() {
    const { packageState, amount } = this.state;
    const packageAmount = packageState.map(amountInt => parseFloat(amountInt.amount, 10),);
    const amountAdd = packageAmount.reduce((a, b) => a + b);
    const stringAmount = amount.toString();
    const {
      doFinalRemittance,
      doUnRemittedViewsAccount,
      history,
      match,
    } = this.props;
    if (amount >= amountAdd) {
      const payloadReq = {
        dispatch_id: match.params.id,
        packages: packageState,
        remitted_amount: stringAmount,
        total_amount: amountAdd,
      };
      doFinalRemittance(payloadReq).then(res => doUnRemittedViewsAccount(res.payload.data.id).then(() => history.push(`/courier-remittance/${res.payload.data.id}`),),);
    } else {
      message.error('Amount is not enough.', 5);
      this.setState({
        visible: false,
        amount: 0,
        remarks: null,
      });
    }
  }

  submitTN() {
    const { trackingNumber } = this.state;
    const { addTrackingNumber, match } = this.props;
    const trackingNumberSplit = trackingNumber
      ? trackingNumber.split('\n')
      : '';
    for (let d = 0; d < trackingNumberSplit.length; d++) {
      // console.log(dataState);
      addTrackingNumber(match.params.id, trackingNumberSplit[d])
        .then((res) => {
          const {
            itemScanned: updatedPackages,
            dataState: updateDataState,
          } = this.state;
          if (res.payload) {
            const filterDataState = updateDataState.filter(
              tnumber => tnumber.tracking_number !== res.payload.data.tracking_number,
            );
            const filterData = updatedPackages.filter(
              tnumber => tnumber.tracking_number === res.payload.data.tracking_number,
            );
            if (filterData.length) {
              message.error('Duplicate Package!', 5);
              this.setState({
                trackingNumber: null,
              });
              // console.log(filterData);
            } else {
              message.success(
                `Package ${trackingNumberSplit[d]} is Successfully Added!`,
                5,
              );
              this.setState(prevState => ({
                itemScanned: [
                  ...prevState.itemScanned,
                  {
                    tracking_number: res.payload.data.tracking_number,
                    handover_date: res.payload.data.handover_date,
                    consignee_name: res.payload.data.consignee_name,
                    payment_type: res.payload.data.payment_type,
                    total_price: res.payload.data.total_price,
                    amount: res.payload.data.amount,
                  },
                ],
                packageState: [
                  ...prevState.packageState,
                  {
                    tracking_number: res.payload.data.tracking_number,
                    amount: res.payload.data.amount,
                  },
                ],
                trackingNumber: null,
                dataState: filterDataState,
              }));
              // console.log(filterData);
            }
          }
        })
        .catch((catcher) => {
          if (catcher.error.response.status === 400) {
            message.error(catcher.error.response.data.message, 5);
            this.setState({
              trackingNumber: null,
            });
          }
        });
    }
  }

  submitTNKeypress(event) {
    const { trackingNumber } = this.state;
    const { addTrackingNumber, match } = this.props;
    const trackingNumberSplit = trackingNumber
      ? trackingNumber.split('\n')
      : '';
    if (event.keyCode === 13) {
      for (let d = 0; d < trackingNumberSplit.length; d++) {
        // console.log(dataState);
        addTrackingNumber(match.params.id, trackingNumberSplit[d])
          .then((res) => {
            const {
              itemScanned: updatedPackages,
              dataState: updateDataState,
            } = this.state;
            if (res.payload) {
              const filterDataState = updateDataState.filter(
                tnumber => tnumber.tracking_number !== res.payload.data.tracking_number,
              );
              const filterData = updatedPackages.filter(
                tnumber => tnumber.tracking_number === res.payload.data.tracking_number,
              );
              if (filterData.length) {
                message.error('Duplicate Package!', 5);
                this.setState({
                  trackingNumber: null,
                });
                // console.log(filterData);
              } else {
                message.success(
                  `Package ${trackingNumberSplit[d]} is Successfully Added!`,
                  5,
                );
                this.setState(prevState => ({
                  itemScanned: [
                    ...prevState.itemScanned,
                    {
                      tracking_number: res.payload.data.tracking_number,
                      handover_date: res.payload.data.handover_date,
                      consignee_name: res.payload.data.consignee_name,
                      payment_type: res.payload.data.payment_type,
                      total_price: res.payload.data.total_price,
                      amount: res.payload.data.amount,
                    },
                  ],
                  packageState: [
                    ...prevState.packageState,
                    {
                      tracking_number: res.payload.data.tracking_number,
                      amount: res.payload.data.amount,
                    },
                  ],
                  trackingNumber: null,
                  dataState: filterDataState,
                }));
                // console.log(filterData);
              }
            }
          })
          .catch((catcher) => {
            if (catcher.error.response.status === 400) {
              message.error(catcher.error.response.data.message, 5);
              this.setState({
                trackingNumber: null,
              });
            }
          });
      }
    }
  }

  render() {
    const {
      trackingNumber,
      dataState,
      itemScanned,
      visible,
      packageState,
      amount,
      remarks,
      dispatchData,
      loadSpin,
    } = this.state;
    const { match } = this.props;
    // const creatorName = `${viewAccount.creator.firstname}${viewAccount.creator.lastname}`;
    const { Paragraph, Text } = Typography;
    const packageAmount = packageState
      .map(amountInt => parseFloat(amountInt.amount, 10))
      .reduce((a, b) => a + b, 0)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return (
      <div className="ListView">
        <Modal
          title={`Total Amount: ₱${packageAmount}`}
          visible={visible}
          onOk={e => this.addFinalSubmit(e)}
          onCancel={this.handleCancel}
        >
          <Form.Item label="Amount">
            <Input
              type="number"
              name="amount"
              onChange={e => this.inputHandler(e)}
              value={amount}
            />
          </Form.Item>
          <Form.Item label="Remarks">
            <Input
              name="remarks"
              onChange={e => this.inputHandler(e)}
              value={remarks}
            />
          </Form.Item>
        </Modal>
        <PageHeader title="VIEW CREATE DISPATCH" />
        <Container>
          <Card>
            <Spin spinning={loadSpin} tip="Loading Create Dispatch...">
              <Row>
                <Col xs={24} sm={24} lg={8}>
                  <span>
                    <Paragraph>
                      <Text strong>Dispatch Number: </Text>
                      <br />
                      {match.params.id}
                    </Paragraph>
                    <Paragraph>
                      <Text strong>Type: </Text>
                      <br />
                      {dispatchData ? dispatchData.type : ''}
                    </Paragraph>
                    <Paragraph>
                      <Text strong>Dispatch Date: </Text>
                      <br />
                      {dispatchData ? dispatchData.dispatch_date : ''}
                    </Paragraph>
                  </span>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                  <span>
                    <Paragraph>
                      <Text strong>Driver: </Text>
                      <br />
                      {dispatchData ? dispatchData.driver : ''}
                    </Paragraph>
                    <Paragraph>
                      <Text strong>Vehicle: </Text>
                      <br />
                      {dispatchData ? dispatchData.vehicle_name : ''}
                    </Paragraph>
                    <Paragraph>
                      <Text strong>Outsource: </Text>
                      <br />
                      {dispatchData ? dispatchData.outsource : ''}
                    </Paragraph>
                  </span>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                  <span>
                    <Paragraph>
                      <Text strong>Origin: </Text>
                      <br />
                      {dispatchData ? dispatchData.port_code : ''}
                    </Paragraph>
                    <Paragraph>
                      <Text strong>Destination: </Text>
                      <br />
                      {dispatchData ? dispatchData.destination : ''}
                    </Paragraph>
                    <Paragraph>
                      <Text strong>Processed By: </Text>
                      <br />
                      {dispatchData ? dispatchData.added_by : ''}
                    </Paragraph>
                  </span>
                </Col>
              </Row>
            </Spin>
            <Divider />
            <center>
              <Text strong>Tracking Number</Text>
            </center>
            <Input.TextArea
              name="trackingNumber"
              onKeyUp={e => this.submitTNKeypress(e)}
              onChange={e => this.changeHandler(e)}
              value={trackingNumber}
            />
            <Divider />
            {!trackingNumber ? (
              <Button
                onClick={e => this.submitTN(e)}
                type="primary"
                disabled
                block
              >
                Submit Tracking Number
              </Button>
            ) : (
              <Button onClick={e => this.submitTN(e)} type="primary" block>
                Submit Tracking Number
              </Button>
            )}
          </Card>
          <Spacer />
          <Card
            title="Dispatch Details"
            extra={`Total: ${dataState.length
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <Spin spinning={loadSpin} tip="Loading Create Dispatch...">
              <ReactTable
                data={dataState}
                columns={[
                  {
                    Header: 'Tracking Number',
                    accessor: 'tracking_number',
                  },
                  {
                    Header: 'Handover Date',
                    accessor: 'handover_date',
                  },
                  {
                    Header: 'Consignee Name',
                    accessor: 'consignee_name',
                  },
                  {
                    Header: 'Payment Type',
                    accessor: 'payment_type',
                  },
                  {
                    Header: 'Declared Amount',
                    accessor: 'total_price',
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                  },
                  {
                    Header: 'Status',
                    Cell: row => (() => {
                        switch (row.original.remitted) {
                          case 0:
                            return 'Unremitted';
                          case 1:
                            return 'Remitted';
                          default:
                            return 'Unremitted';
                        }
                      })(),
                  },
                ]}
              />
            </Spin>
          </Card>
          <Spacer />
          {(() => {
            switch (itemScanned.length) {
              case 0:
                return false;
              default:
                return (
                  <Card
                    title="Item Scanned"
                    extra={`Total: ${itemScanned.length
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                  >
                    <Text>Total Amount: ₱</Text>
                    <Text strong>
                      {itemScanned
                        .map(item => parseFloat(item.amount, 10))
                        .reduce((a, b) => a + b)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Text>
                    <Spacer />
                    <ReactTable
                      data={itemScanned}
                      columns={[
                        {
                          Header: 'Tracking Number',
                          accessor: 'tracking_number',
                        },
                        {
                          Header: 'Handover Date',
                          accessor: 'handover_date',
                        },
                        {
                          Header: 'Consignee Name',
                          accessor: 'consignee_name',
                        },
                        {
                          Header: 'Payment Type',
                          accessor: 'payment_type',
                        },
                        {
                          Header: 'Declared Amount',
                          accessor: 'total_price',
                        },
                        {
                          Header: 'POD Amount',
                          accessor: 'amount',
                        },
                      ]}
                    />
                  </Card>
                );
            }
          })()}
          <Spacer />
          {itemScanned.length === 0 ? (
            <Button
              onClick={() => this.openModalFinalSubmit()}
              disabled
              type="primary"
              block
            >
              Submit Remittance
            </Button>
          ) : (
            <Button
              onClick={() => this.openModalFinalSubmit()}
              type="primary"
              block
            >
              Submit Remittance
            </Button>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  viewAccount: state.dispatch.views,
});

const mapDispatchToProps = {
  addTrackingNumber: createRemittanceTrackingNumber,
  doFinalRemittance: finalRemittance,
  doUnRemittedViewsAccount: unRemittedViewsAccount,
  enterDispatchNumber: dispatchNumber,
};

CreateDispatch.propTypes = {
  addTrackingNumber: PropTypes.func.isRequired,
  doFinalRemittance: PropTypes.func.isRequired,
  doUnRemittedViewsAccount: PropTypes.func.isRequired,
  enterDispatchNumber: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDispatch);

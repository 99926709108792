import { viewPickups, pickupDispatchEdit } from '../../services/api';

const DELIVERY_EDIT = 'editDispatchPackage/DELIVERY_EDIT';
const DELIVERY_EDIT_SUCCESS = 'editDispatchPackage/DELIVERY_EDIT_SUCCESS';

const TRANSFER_EDIT = 'editDispatchPackage/TRANSFER_EDIT';
const TRANSFER_EDIT_SUCCESS = 'editDispatchPackage/TRANSFER_EDIT_SUCCESS';

const RTS_EDIT = 'editDispatchPackage/RTS_EDIT';
const RTS_EDIT_SUCCESS = 'editDispatchPackage/RTS_EDIT_SUCCESS';

const PICKUP_EDIT = 'editDispatchPackage/PICKUP_EDIT';
const PICKUP_EDIT_SUCCESS = 'editDispatchPackage/PICKUP_EDIT_SUCCESS';

const CUSTOMER_EDIT = 'editDispatchPackage/CUSTOMER_EDIT';
const CUSTOMER_EDIT_SUCCESS = 'editDispatchPackage/CUSTOMER_EDIT_SUCCESS';

const MERCHANT_EDIT = 'editDispatchPackage/MERCHANT_EDIT';
const MERCHANT_EDIT_SUCCESS = 'editDispatchPackage/MERCHANT_EDIT_SUCCESS';

export const packageDeliveryEdit = (page, id) => {
  switch (page) {
    case 'delivery':
      return {
        type: DELIVERY_EDIT,
        payload: {
          client: 'secure',
          request: {
            ...viewPickups('dispatch', id),
          },
        },
      };

    case 'transfer':
      return {
        type: TRANSFER_EDIT,
        payload: {
          client: 'secure',
          request: {
            ...viewPickups('dispatch', id),
          },
        },
      };

    case 'pickup':
      return {
        type: PICKUP_EDIT,
        payload: {
          client: 'secure',
          request: {
            ...viewPickups('dispatch', id),
          },
        },
      };

    case 'rts':
      return {
        type: RTS_EDIT,
        payload: {
          client: 'secure',
          request: {
            ...viewPickups('dispatch', id),
          },
        },
      };

    case 'customer-pickup':
      return {
        type: CUSTOMER_EDIT,
        payload: {
          client: 'secure',
          request: {
            ...viewPickups('pickup', id),
          },
        },
      };

    case 'merchant-pickup':
      return {
        type: MERCHANT_EDIT,
        payload: {
          client: 'secure',
          request: {
            ...viewPickups('pickup', id),
          },
        },
      };

    default:
      return false;
  }
};

const DELIVERY_EDIT_SUBMIT = 'editDispatchPackage/DELIVERY_EDIT_SUBMIT';

const TRANSFER_EDIT_SUBMIT = 'editDispatchPackage/TRANSFER_EDIT_SUBMIT';

const RTS_EDIT_SUBMIT = 'editDispatchPackage/RTS_EDIT_SUBMIT';

const PICKUP_EDIT_SUBMIT = 'editDispatchPackage/PICKUP_EDIT_SUBMIT';

const CUSTOMER_EDIT_SUBMIT = 'editDispatchPackage/CUSTOMER_EDIT_SUBMIT';

const MERCHANT_EDIT_SUBMIT = 'editDispatchPackage/MERCHANT_EDIT_SUBMIT';

export const packageDeliveryEditSubmit = (page, id, dataEdit) => {
  switch (page) {
    case 'delivery':
      return {
        type: DELIVERY_EDIT_SUBMIT,
        payload: {
          client: 'secure',
          request: {
            ...pickupDispatchEdit('dispatch', id),
            data: dataEdit,
          },
        },
      };

    case 'transfer':
      return {
        type: TRANSFER_EDIT_SUBMIT,
        payload: {
          client: 'secure',
          request: {
            ...pickupDispatchEdit('dispatch', id),
            data: dataEdit,
          },
        },
      };

    case 'pickup':
      return {
        type: PICKUP_EDIT_SUBMIT,
        payload: {
          client: 'secure',
          request: {
            ...pickupDispatchEdit('dispatch', id),
            data: dataEdit,
          },
        },
      };

    case 'rts':
      return {
        type: RTS_EDIT_SUBMIT,
        payload: {
          client: 'secure',
          request: {
            ...pickupDispatchEdit('dispatch', id),
            data: dataEdit,
          },
        },
      };

    case 'customer-pickup':
      return {
        type: CUSTOMER_EDIT_SUBMIT,
        payload: {
          client: 'secure',
          request: {
            ...pickupDispatchEdit('pickup', id),
            data: dataEdit,
          },
        },
      };

    case 'merchant-pickup':
      return {
        type: MERCHANT_EDIT_SUBMIT,
        payload: {
          client: 'secure',
          request: {
            ...pickupDispatchEdit('pickup', id),
            data: dataEdit,
          },
        },
      };

    default:
      return false;
  }
};

const initialState = {
  deliveryView: {},
  transferView: {},
  pickupView: {},
  rtsView: {},
  customerView: {},
  merchantView: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case DELIVERY_EDIT_SUCCESS:
      return {
        ...state,
        deliveryView: action.payload.data,
      };

    case TRANSFER_EDIT_SUCCESS:
      return {
        ...state,
        transferView: action.payload.data,
      };

    case RTS_EDIT_SUCCESS:
      return {
        ...state,
        rtsView: action.payload.data,
      };

    case PICKUP_EDIT_SUCCESS:
      return {
        ...state,
        pickupView: action.payload.data,
      };

    case CUSTOMER_EDIT_SUCCESS:
      return {
        ...state,
        customerView: action.payload.data,
      };

    case MERCHANT_EDIT_SUCCESS:
      return {
        ...state,
        merchantView: action.payload.data,
      };

    default:
      return state;
  }
}

export default reducer;

import React, { Component } from 'react';
import Table from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';

class ReactTable extends Component {
  constructor(props) {
    super(props);

    this.data = [];
    this.columns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Current Status',
        accessor: 'current_status',
      },
      {
        Header: 'Target Status',
        accessor: 'target_status',
      },
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
      {
        Header: 'Options',
        accessor: 'options',
      },
    ];
  }

  render() {
    const {
      columns, data, getTrProps, defaultPageSize, loading,
    } = this.props;
    return (
      <Table
        data={data}
        columns={columns || this.columns}
        loading={loading}
        className="-highlight"
        defaultPageSize={defaultPageSize}
        getTrProps={getTrProps}
      />
    );
  }
}

ReactTable.defaultProps = {
  data: [],
  defaultPageSize: 10,
  loading: false,
  getTrProps: () => [],
};

ReactTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  getTrProps: PropTypes.func,
  defaultPageSize: PropTypes.number,
  loading: PropTypes.bool,
};

export default ReactTable;

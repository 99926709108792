import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
 DatePicker, Form, Button, Icon 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import { reportUnremittedNoFilterList } from './ducks';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';

export class CashierUnremitted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFilter: false,
      handoverDate: [],
      loading: false,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      payloadReq: {
        export: false,
        page: 0,
        pageSize: 0,
        filter: [],
        sorted: [],
      },
      tableFilter: [],
      searchFilter: [],
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
  }

  onFetchDataFunc(state, instance) {
    // console.log(state);
    const { searchFilter, hasFilter, handoverDate, tableFilter } = this.state;
    const { reportCashier } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };
    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    this.setState({
      payloadReq: paramsReq,
    });
    if (hasFilter) {
      this.setState({
        loading: true,
      });

      reportCashier(paramsReq).then(res => this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        tableFilter: state.filtered,
        searchFilter: [...handoverDate],
      }));
      // console.log(paramsReq);
    }
  }

  submitHandler() {
    this.setState({
      loading: true,
    });
    const { handoverDate, payloadReq, tableFilter } = this.state;
    // const { reportCashier } = this.props;
    const paramsReq = {
      export: false,
      page: payloadReq.page,
      pageSize: payloadReq.pageSize,
      filtered: [...handoverDate],
      sorted: payloadReq.sorted,
    };
    if (tableFilter.length) {
      paramsReq.filtered = paramsReq.filtered.concat(tableFilter);
    }
    // reportCashier(paramsReq).then(res => this.setState({
    //   loading: false,
    //   userData: {
    //     rows: res.payload.data.rows,
    //     pages: res.payload.data.pages,
    //     total: res.payload.data.total,
    //   },
    //   payloadReq: paramsReq,
    //   searchFilter: [...handoverDate],
    // }));
    this.setState({
      hasFilter: true,
    }, () => this.onFetchDataFunc(paramsReq));
  }

  submitHandlerExport() {
    this.setState({
      loading: true,
    });
    const { handoverDate, payloadReq, tableFilter } = this.state;
    const { reportCashier } = this.props;
    const paramsReq = {
      export: true,
      page: payloadReq.page,
      pageSize: payloadReq.pageSize,
      filter: [...handoverDate],
      sorted: payloadReq.sorted,
    };
    if (tableFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(tableFilter);
    }
    reportCashier(paramsReq).then((res) => {
      const newBlob = new Blob([res.payload.data]);
      fileDownload(newBlob, 'Cashier-unremitted.csv');
      this.setState({
        loading: false,
      });
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    const dateForm = `${formatDateOne}|${formatDateTwo}`;

    this.setState({
      handoverDate: [
        dateForm !== 'Invalid Date|Invalid Date'
          ? { id: 'remittance_date', value: dateForm }
          : { id: 'remittance_date' },
      ],
    });
  }

  render() {
    const { userData, loading, hasFilter } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Report',
      },
      {
        breadcrumbName: 'Cashier Unremitted',
      },
    ];
    return (
      <div className="CashierUnremitted">
        <PageHeader title="CASHIER UNREMITTED" routes={breadCrumbs} />
        <Container>
          <Form>
            <Card>
              <Form.Item label="Date Range">
                <DatePicker.RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment('00:00', 'HH:mm'),
                      moment('00:00', 'HH:mm'),
                    ],
                  }}
                  onChange={e => this.dateHandler(e)}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Button onClick={() => this.submitHandler()} type="primary" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Icon>
                Filter
              </Button>
            </Card>
            <Spacer />
            <Card
              title={(
<Button
                  onClick={() => this.submitHandlerExport()}
                  type="danger"
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faFileExport} fixedWidth />
                  </Icon>
                  Export
                </Button>
)}
              extra={`Total: ${userData.total
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            >
              <ReactTable
                data={userData.rows}
                pages={userData.pages}
                columns={[
                  {
                    Header: 'Date',
                    accessor: 'date',
                  },
                  {
                    Header: 'Remittance ID',
                    accessor: 'remittance',
                  },
                  {
                    Header: 'Branch',
                    accessor: 'branch',
                  },
                  {
                    Header: 'Total Amount',
                    accessor: 'total_amount',
                  },
                  {
                    Header: 'Remittance Amount',
                    accessor: 'remittance_amount',
                  },
                  {
                    Header: 'Variance',
                    accessor: 'variance',
                  },
                  {
                    Header: 'Processed By',
                    accessor: 'processed_by',
                  },
                ]}
                loadingText={!hasFilter ? 'Use the filters to search for Cashier Unremitted' : 'Fetching cashier unremitted. . .'}
                loading={loading}
                onFetchData={this.onFetchDataFunc}
              />
            </Card>
          </Form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reportListTable: state.report.data,
});

const mapDispatchToProps = {
  reportCashier: reportUnremittedNoFilterList,
};

CashierUnremitted.propTypes = {
  reportCashier: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CashierUnremitted);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faRedo } from '@fortawesome/free-solid-svg-icons';
import {
  Form, Button, Icon, Input, Col, message, Empty, List, Popconfirm,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Row from '../../components/Row';
import { fetchGroupTrackingNumber } from './ducks';
import Spacer from '../../components/Spacer';


export class GroupChecker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packageList: {},
      packageGroup: [],
      errorList: [],
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
  }

  formSubmit(e) {
    e.preventDefault();

    const { packageList, packageGroup } = this.state;
    const { form, doFetchGroupTrackingNumber } = this.props;
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (packageGroup.length !== 0) {
          if (packageGroup.length === packageList.length) {
            message.info('You have already scanned all the tracking within this group.');
          } else if (packageGroup.includes(values.tracking_number)) {
            message.error('Package is already scanned!');
            this.setState(prevState => ({
              errorList: [
                ...prevState.errorList,
                `${values.tracking_number} is already scanned!`,
              ],
            }));
          } else if (Object.keys(packageList).includes(values.tracking_number)) {

            if (packageList[values.tracking_number] === '' || packageList[values.tracking_number] === '0') {
              message.error('Tracking number does not have a declared weight!');
            } else {
              message.success('Package is valid');
            }

            this.setState(prevState => ({
              packageGroup: [
                ...prevState.packageGroup,
                values.tracking_number,
              ],
            }));
          } else {
            message.error('Package is not part of this group!');
            this.setState(prevState => ({
              errorList: [
                ...prevState.errorList,
                `${values.tracking_number} is not part of this group`,
              ],
            }));
          }
        } else {
          doFetchGroupTrackingNumber(values.tracking_number).then((action) => {
            if (typeof action.payload.data.code !== 'undefined') {
              message.error(action.payload.data.message);
            } else {

              if (action.payload.data.packages[values.tracking_number] === '' || action.payload.data.packages[values.tracking_number] === '0') {
                message.error('Tracking number does not have a declared weight!');
              }

              this.setState({
                packageList: action.payload.data.packages,
                packageGroup: [values.tracking_number],
              });
              
            }
          }).catch(() => {
            message.error('Something went wrong, please try again later.');
          });
        }
        form.resetFields();
      }
    });
  }

  resetHandler() {
    this.setState({
      packageList: {},
      packageGroup: [],
      errorList: [],
    }, () => {
      message.success('List reset success!');
    });
  }

  render() {
    const { packageList, packageGroup, errorList } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Tools',
      },
      {
        breadcrumbName: 'Group Checker',
      },
    ];

    console.log(packageList);

    return (
      <div className="GroupChecker">
        <PageHeader title="Tools" routes={breadCrumbs} />
        <Container>
          <Card title="Package Form">
            <Form onSubmit={this.formSubmit}>
              <Row>
                <Col>
                  <Form.Item label="Tracking Number">
                    {getFieldDecorator('tracking_number', {
                      rules: [
                        {
                          required: true,
                          message: 'Tracking Number cannot be blank.',
                        },
                      ],
                    })(<Input placeholder="Enter tracking number" />)}
                  </Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    block
                  >
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faSearch} fixedWidth />
                    </Icon>
                    Search
                  </Button>
                </Col>
              </Row>

            </Form>
          </Card>
          <Spacer />
          <Card
            title="Package Group"
            extra={(
              <React.Fragment>
                <Popconfirm
                  placement="leftBottom"
                  title="Are you sure you want to reset the list?"
                  okText="Yes"
                  onConfirm={this.resetHandler}
                  cancelText="No"
                  disabled={Object.keys(packageList).length === 0}
                >
                  <Button type="primary" disabled={Object.keys(packageList).length === 0} block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faRedo} fixedWidth />
                    </Icon>
                              Reset
                  </Button>
                </Popconfirm>
              </React.Fragment>
                    )}
          >

            { Object.keys(packageList).length === 0 ? (
              <Empty />
            ) : (
              <List
                size="small"
                bordered
                header={`Total Scanned: ${packageGroup.length} / ${Object.keys(packageList).length}`}
                pagination={{ pageSize: 5 }}
                dataSource={Object.keys(packageList)}
                renderItem={data => 
                  (
                  <List.Item>
                    <h4>
                    <p style={{ color: packageGroup.includes(data) ? 'green' : '' }}>
                      { `${data}, tracking number is ${packageGroup.includes(data) ? 'scanned' : ' not scanned'}` }
                    </p>
                    <p style={{ color: packageList[data] !== '' && packageList[data] !== '0' ? '' : 'red' }}>
                      {`( weight: ${packageList[data] !== '' && packageList[data] !== '0' 
                      ? packageList[data] : 'Tracking number does not have declared weight!'})`}
                    </p>
                    </h4>
                  </List.Item>
                )}
              />
            )}
          </Card>
          <Spacer />
          { errorList.length === 0 ? null : (
            <Card
              title="Error List"
            >
              <List
                size="small"
                bordered
                pagination={{ pageSize: 5 }}
                dataSource={errorList}
                renderItem={data => (
                  <List.Item>
                    <h4>
                      { `${data}` }
                    </h4>
                  </List.Item>
                )}
              />
            </Card>
          )}
        </Container>
      </div>
    );
  }
}

GroupChecker.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchGroupTrackingNumber: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchGroupTrackingNumber: fetchGroupTrackingNumber,

};

const WrappedGroupChecker = Form.create({ name: 'GroupChecker ' })(GroupChecker);

export default connect(null, mapDispatchToProps)(WrappedGroupChecker);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
 DatePicker, Col, Form, Button, Icon, Input 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import { waybill } from '../../components/waybill/ducks';
import {
  reportList,
  reportNoFilterList,
  reportPickOptions,
  fetchOptions,
} from './ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactSelect from '../../components/Select';
import Spacer from '../../components/Spacer';

export class PickedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFilter: false,
      loading: true,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      optionState: {
        destination: [],
        client: [],
      },
      payloadReq: {
        export: false,
        page: 0,
        pageSize: 0,
        sorted: [],
      },
      handoverDate: {
        dateFrom: null,
        dateTo: null,
      },
      tableFilter: [],
      searchFilter: [],
      isFetchingSelect: false,
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
  }

  componentDidMount() {
    const { doCallreportPickOptions } = this.props;
    doCallreportPickOptions('client').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          client: res.payload.data.slice(0, 50),
        },
      })),);
    doCallreportPickOptions('destination').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          destination: res.payload.data.slice(0, 50),
        },
      })),);
  }

  onFetchDataFunc(state, instance) {
    // console.log(state);
    const { searchFilter, hasFilter } = this.state;
    const { pickedItemsNoFilterList } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };
    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    this.setState({
      payloadReq: paramsReq,
    });
    if (hasFilter) {
      this.setState({
        loading: true,
      });
      pickedItemsNoFilterList('pickup/items', paramsReq).then(res => this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        tableFilter: state.filtered,
      }));
    }
  }

  getEditAccountHandler(e) {
    const { waybillComponent, history } = this.props;
    waybillComponent(e.tracking_number).then(() => history.push(`/waybill/${e.tracking_number}`),);
    // console.log(e);
  }

  searchFilter(value, name) {
    this.setState({
      isFetchingSelect: true,
    });
    const { doFetchOptions } = this.props;
    doFetchOptions(name, value).then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          [name]: res.payload.data.slice(0, 50),
        },
        isFetchingSelect: false,
      })),);
  }

  submitHandler() {
    const { payloadReq, handoverDate, tableFilter } = this.state;
    const { pickedItemsNoFilterList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        // const valueValues = values.client.map(res => res.value);
        const paramsReq = {
          export: false,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filtered: [
            values.client
              ? { id: 'client', value: values.client }
              : { id: 'client' },
            handoverDate.dateFrom
              ? { id: 'dateFrom', value: handoverDate.dateFrom }
              : { id: 'dateFrom' },
            handoverDate.dateTo
              ? { id: 'dateTo', value: handoverDate.dateTo }
              : { id: 'dateTo' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number' },
          ],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filtered = paramsReq.filtered.concat(tableFilter);
        }
        // pickedItemsNoFilterList('pickup/items', paramsReq).then(res => this.setState({
        //     loading: false,
        //     userData: {
        //       rows: res.payload.data.rows,
        //       pages: res.payload.data.pages,
        //       total: res.payload.data.total,
        //     },
        //     payloadReq: paramsReq,
        //     searchFilter: [
        //       values.client
        //         ? { id: 'client', value: values.client }
        //         : { id: 'client' },
        //       handoverDate.dateFrom
        //         ? { id: 'dateFrom', value: handoverDate.dateFrom }
        //         : { id: 'dateFrom' },
        //       handoverDate.dateTo
        //         ? { id: 'dateTo', value: handoverDate.dateTo }
        //         : { id: 'dateTo' },
        //       values.tNumber
        //         ? { id: 'tracking_number', value: values.tNumber }
        //         : { id: 'tracking_number' },
        //     ],
        //   }),);
        this.setState({
          hasFilter: true,
        }, () => this.onFetchDataFunc(paramsReq));
      }
    });
  }

  submitHandlerExport() {
    const { payloadReq, handoverDate, tableFilter } = this.state;
    const { pickedItemsNoFilterList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        // const valueValues = values.client.map(res => res.value);
        const paramsReq = {
          export: true,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: [
            values.client
              ? { id: 'client', value: values.client }
              : { id: 'client' },
            handoverDate.dateFrom
              ? { id: 'dateFrom', value: handoverDate.dateFrom }
              : { id: 'dateFrom' },
            handoverDate.dateTo
              ? { id: 'dateTo', value: handoverDate.dateTo }
              : { id: 'dateTo' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number', value: '' },
          ],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filter = paramsReq.filter.concat(tableFilter);
        }
        pickedItemsNoFilterList('pickup/items', paramsReq).then((res) => {
          const newBlob = new Blob([res.payload.data]);
          fileDownload(newBlob, 'Picked-items.csv');
          this.setState({
            loading: false,
          });
        });
      }
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    this.setState({
      handoverDate: {
        dateFrom: formatDateOne !== 'Invalid Date' ? formatDateOne : '',
        dateTo: formatDateTwo !== 'Invalid Date' ? formatDateTwo : '',
      },
    });
  }

  render() {
    const {
 loading, userData, optionState, isFetchingSelect, hasFilter,
} = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Report',
      },
      {
        breadcrumbName: 'Picked',
      },
    ];
    return (
      <div className="DispatchedItems">
        <PageHeader title="PICKED ITEMS" routes={breadCrumbs} />
        <Container>
          <Card>
            <Row>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Client">
                  {getFieldDecorator('client', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(
                    <ReactSelect
                      allowClear
                      showSearch
                      loading={isFetchingSelect}
                      filterOption={false}
                      onSearch={e => this.searchFilter(e, 'client')}
                      style={{ width: '100%' }}
                      placeholder="Select Client..."
                      options={optionState.client}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Date Range">
                  {getFieldDecorator('dateRange', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(
                    <DatePicker.RangePicker
                      onChange={e => this.dateHandler(e)}
                      style={{ width: '100%' }}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment('00:00', 'HH:mm'),
                          moment('00:00', 'HH:mm'),
                        ],
                      }}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Tracking Number">
              {getFieldDecorator('tNumber', {
                rules: [{ required: false, message: 'Please insert value' }],
              })(<Input.TextArea />)}
            </Form.Item>
            <br />
            <Button onClick={() => this.submitHandler()} type="primary" block>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              </Icon>
              Filter
            </Button>
          </Card>
          <Spacer />
          <Card
            title={(
<Button onClick={() => this.submitHandlerExport()} type="danger">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faFileExport} fixedWidth />
                </Icon>
                Export
              </Button>
)}
            extra={`Total: ${userData.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <ReactTable
              data={userData.rows}
              pages={userData.pages}
              columns={[
                {
                  Header: 'Pickup ID',
                  accessor: 'ph_id',
                },
                {
                  Header: 'Picked Date',
                  accessor: 'created_at',
                },
                {
                  Header: 'Waybill Number',
                  accessor: 'tracking_number',
                },
                {
                  Header: 'Driver Name',
                  accessor: 'driver',
                },
                {
                  Header: 'Consignee Name',
                  accessor: 'consignee_name',
                },
                {
                  Header: 'City',
                  accessor: 'address_city',
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: row => (
                    <center>
                      <Button
                        type="link"
                        onClick={() => this.getEditAccountHandler(row.original)}
                      >
                        <Icon type="eye" />
                        View
                      </Button>
                    </center>
                  ),
                },
              ]}
              loadingText={!hasFilter ? 'Use the filters to search for Picked Items' : 'Fetching Picked Items. . .'}
              loading={loading}
              onFetchData={this.onFetchDataFunc}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reportListTable: state.report.data,
  options: state.report.options,
});

const mapDispatchToProps = {
  pickedItemList: reportList,
  pickedItemsNoFilterList: reportNoFilterList,
  waybillComponent: waybill,
  doCallreportPickOptions: reportPickOptions,
  doFetchOptions: fetchOptions,
};

PickedItems.propTypes = {
  pickedItemsNoFilterList: PropTypes.func.isRequired,
  waybillComponent: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doCallreportPickOptions: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptions: PropTypes.func.isRequired,
};

const WrappedPickedItems = Form.create({ name: 'Register' })(PickedItems);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedPickedItems);

import { getOptionsDispatch, carrierShipment } from '../../services/api';

const REPORT_OPTIONS_USER = 'reports/REPORT_OPTIONS_USER';
const REPORT_OPTIONS_USER_SUCCESS = 'reports/REPORT_OPTIONS_USER_SUCCESS';

const REPORT_OPTIONS_CLIENT = 'reports/REPORT_OPTIONS_CLIENT';
const REPORT_OPTIONS_CLIENT_SUCCESS = 'reports/REPORT_OPTIONS_CLIENT_SUCCESS';

const REPORT_OPTIONS_BRANCHCODE = 'reports/REPORT_OPTIONS_BRANCHCODE';
const REPORT_OPTIONS_BRANCHCODE_SUCCESS = 'reports/REPORT_OPTIONS_BRANCHCODE_SUCCESS';

const REPORT_OPTIONS_DESTINATION = 'reports/REPORT_OPTIONS_DESTINATION';
const REPORT_OPTIONS_DESTINATION_SUCCESS = 'reports/REPORT_OPTIONS_DESTINATION_SUCCESS';

const REPORT_OPTIONS_STATUS = 'reports/REPORT_OPTIONS_STATUS';
const REPORT_OPTIONS_STATUS_SUCCESS = 'reports/REPORT_OPTIONS_STATUS_SUCCESS';

const REPORT_OPTIONS_MERCHANT = 'reports/REPORT_OPTIONS_MERCHANT';
const REPORT_OPTIONS_MERCHANT_SUCCESS = 'reports/REPORT_OPTIONS_MERCHANT_SUCCESS';

const REPORT_OPTIONS_ROLE = 'reports/REPORT_OPTIONS_ROLE';
const REPORT_OPTIONS_ROLE_SUCCESS = 'reports/REPORT_OPTIONS_ROLE_SUCCESS';

const REPORT_OPTIONS_REASON = 'reports/REPORT_OPTIONS_REASON';
const REPORT_OPTIONS_REASON_SUCCESS = 'reports/REPORT_OPTIONS_REASON_SUCCESS';

const REPORT_OPTIONS_CARRIER = 'reports/REPORT_OPTIONS_CARRIER';
const REPORT_OPTIONS_CARRIER_SUCCESS = 'reports/REPORT_OPTIONS_CARRIER_SUCCESS';

const REPORT_OPTIONS_OUTSOURCE = 'reports/REPORT_OPTIONS_OUTSOURCE';
const REPORT_OPTIONS_OUTSOURCE_SUCCESS = 'reports/REPORT_OPTIONS_OUTSOURCE_SUCCESS';

const REPORT_OPTIONS_SHIPPING_TYPE = 'reports/REPORT_OPTIONS_SHIPPING_TYPE';
const REPORT_OPTIONS_SHIPPING_TYPE_SUCCESS = 'reports/REPORT_OPTIONS_OUTSOURCE_SUCCESS';

export const rubixpickOptions = (tNumber) => {
  switch (tNumber) {
    case 'user':
      return {
        type: REPORT_OPTIONS_USER,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('user'),
          },
        },
      };

    case 'client':
      return {
        type: REPORT_OPTIONS_CLIENT,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('client'),
          },
        },
      };

    case 'branch_code':
      return {
        type: REPORT_OPTIONS_BRANCHCODE,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('branch_code'),
          },
        },
      };

    case 'destination':
      return {
        type: REPORT_OPTIONS_DESTINATION,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('destination'),
          },
        },
      };

    case 'status':
      return {
        type: REPORT_OPTIONS_STATUS,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('status'),
          },
        },
      };

    case 'merchant':
      return {
        type: REPORT_OPTIONS_MERCHANT,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('merchant'),
          },
        },
      };

    case 'role':
      return {
        type: REPORT_OPTIONS_ROLE,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('role'),
          },
        },
      };

    case 'reason':
      return {
        type: REPORT_OPTIONS_REASON,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('reason'),
          },
        },
      };

    case 'carrier':
      return {
        type: REPORT_OPTIONS_CARRIER,
        payload: {
          client: 'secure',
          request: {
            ...carrierShipment,
          },
        },
      };

    case 'outsource':
      return {
        type: REPORT_OPTIONS_OUTSOURCE,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('outsource'),
          },
        },
      };

    case 'shipping_type':
      return {
        type: REPORT_OPTIONS_SHIPPING_TYPE,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('shipping_type'),
          },
        },
      };

    default:
      return false;
  }
};

const initialState = {
  options: {
    users: [],
    clients: [],
    branchCodes: [],
    destinations: [],
    status: [],
    merchant: [],
    role: [],
    reason: [],
    carrier: [],
    outsource: [],
    shipping_type: [],
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_OPTIONS_USER_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          users: action.payload.data,
        },
      };

    case REPORT_OPTIONS_CLIENT_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          clients: action.payload.data,
        },
      };

    case REPORT_OPTIONS_BRANCHCODE_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          branchCodes: action.payload.data,
        },
      };

    case REPORT_OPTIONS_DESTINATION_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          destinations: action.payload.data,
        },
      };

    case REPORT_OPTIONS_STATUS_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          status: action.payload.data,
        },
      };

    case REPORT_OPTIONS_MERCHANT_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          merchant: action.payload.data,
        },
      };

    case REPORT_OPTIONS_ROLE_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          role: action.payload.data,
        },
      };

    case REPORT_OPTIONS_REASON_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          reason: action.payload.data,
        },
      };

    case REPORT_OPTIONS_CARRIER_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          carrier: action.payload.data,
        },
      };

    case REPORT_OPTIONS_OUTSOURCE_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          outsource: action.payload.data,
        },
      };

    case REPORT_OPTIONS_SHIPPING_TYPE_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          outsource: action.payload.data,
        },
      };

    default:
      return state;
  }
}

export default reducer;

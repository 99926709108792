import {
  apiFetchList,
  apiCreateClient,
  apiUpdateClient,
  apiCreateClientStatusMap,
  apiFetchClientStatusMap,
  apiUpdateClientStatusMap,
  apiDeleteClientStatusMap,
  apiFetchStatusMapList,
} from '../../services/apiEndpoints';

const FETCH_CLIENT_LIST = 'client/FETCH_CLIENT_LIST';
export const fetchClientList = data => ({
  type: FETCH_CLIENT_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchList('client'),
      data,
    },
  },
});

const CREATE_CLIENT = 'client/CREATE_CLIENT';
export const createClient = data => ({
  type: CREATE_CLIENT,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateClient,
      data,
    },
  },
});

const UPDATE_CLIENT = 'vehicle/UPDATE_CLIENT';
export const updateClient = (clientId, data) => ({
  type: UPDATE_CLIENT,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateClient(clientId),
      data,
    },
  },
});

const CREATE_STATUS_MAP = 'client/CREATE_STATUS_MAP';
export const createClientStatusMap = data => ({
  type: CREATE_STATUS_MAP,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateClientStatusMap,
      data,
    },
  },
});

const FETCH_STATUS_MAP_LIST = 'client/FETCH_STATUS_MAP_LIST';
export const fetchStatusMapList = () => ({
  type: FETCH_STATUS_MAP_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchStatusMapList,
    },
  },
});

const FETCH_CLIENT_STATUS_MAP = 'client/FETCH_CLIENT_STATUS_MAP';
export const fetchClientStatusMap = (id) => ({
  type: FETCH_CLIENT_STATUS_MAP,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchClientStatusMap(id),
    },
  },
});

const UPDATE_STATUS_MAP = 'client/UPDATE_STATUS_MAP';
export const updateClientStatusMap = (data, id) => ({
  type: UPDATE_STATUS_MAP,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateClientStatusMap(id),
      data,
    },
  },
});

const DELETE_STATUS_MAP = 'client/DELETE_STATUS_MAP';
export const deleteClientStatusMap = id => ({
  type: DELETE_STATUS_MAP,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteClientStatusMap(id),
    },
  },
});


const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;

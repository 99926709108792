import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMinus,
  faTrash,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import {
  Form,
  Input,
  Button,
  Col,
  Icon,
  Popconfirm,
  DatePicker,
  Slider,
  Statistic,
} from 'antd';
import moment from 'moment';
import Row from './Row';
import Select from './Select';
import Card from './Card';
import Spacer from './Spacer';
import ReactTable from './ReactTable';
import {
  fetchOptions,
  fetchOptionsLike,
  fetchShipmentProviders,
} from '../pages/ducks';

class DispatchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outsourcePicker: null,
      outsourceGroupSearch: [],
      options: {
        vehicle: [],
        outsource: [],
        destination: [],
        courier: [],
        merchant: [],
      },

      mode: 'land',
      shipmentProvider: [],
      carrierOptions: [],
      isFetching: false,
      showSecondDriverandHelper: false,

      sizeState: 50,
      saveTrackingNumber: null,

      driverMobile: false,
      helperMobile: false,
    };

    this.validateTrackingNumber = this.validateTrackingNumber.bind(this);
    this.searchOption = this.searchOption.bind(this);
    this.setRowVisible = this.setRowVisible.bind(this);
    this.setRowHidden = this.setRowHidden.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.selectMode = this.selectMode.bind(this);
  }

  componentDidMount() {
    const {
      selectOptions,
      doFetchOptions,
      isDispatchEdit,
      hasDestination,
      doFetchShipmentProviders,
    } = this.props;

    window.scrollTo(0, 0);

    Object.keys(selectOptions).forEach((key) => {
      doFetchOptions(selectOptions[key]).then((action) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            [selectOptions[key]]: isDispatchEdit
              ? action.payload.data
              : (selectOptions[key] === 'outsource' ? action.payload.data : action.payload.data.slice(0, 50)),
          },
        }));
      });
    });

    doFetchOptions('outsource').then(resAction => this.setState({ outsourceGroupSearch: resAction.payload.data }));

    if (hasDestination) {
      doFetchShipmentProviders().then((action) => {
        this.setState({
          shipmentProvider: action.payload.data,
        });
      });
    }
  }

  getOutsourceID(e) {
    const { options, outsourceGroupSearch } = this.state;

    // console.log(outsourceGroupSearch);

    const branch = options.courier.filter(res => res.value === e);

    const outsource = outsourceGroupSearch.filter(
      res => res.value === branch[0].outsource_group,
    );
    // console.log(branch[0].outsource_group);
    this.setState({
      outsourcePicker: outsource[0] !== undefined ? outsource[0].value : null,
    });

    // console.log(outsource[0] !== undefined ? outsource[0] : null);
  }

  setRowVisible() {
    this.setState({
      showSecondDriverandHelper: true,
    });
  }

  setRowHidden() {
    this.setState({
      showSecondDriverandHelper: false,
    });
  }

  searchOption(type, e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isFetching: true,
    });

    doFetchOptionsLike(type, e).then((action) => {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          [type]: action.payload.data.slice(0, 50),
        },
        isFetching: false,
      }));
    });
  }

  searchOptionShipment(mode, e) {
    const { doFetchShipmentProviders } = this.props;
    this.setState({
      isFetching: true,
    });
    doFetchShipmentProviders().then((action) => {
      const shipmentSearch = action.payload.data[mode].filter(
        item => item.label.indexOf(e) > -1,
      );
      this.setState({
        carrierOptions: shipmentSearch,
        isFetching: false,
      });
    });
  }

  validateTrackingNumber(e) {
    e.preventDefault();
    const { form, packageFormHandler, requiredPackageFormFields } = this.props;

    const { validateFields } = form;

    // eslint-disable-next-line no-unused-vars
    validateFields(requiredPackageFormFields, (err, values) => {
      if (!err) {
        packageFormHandler(values);
        form.resetFields(['tracking_number']);

        this.setState({ saveTrackingNumber: values.tracking_number });
      }
    });
  }

  confirmDelete(trackingNumber) {
    const { deleteHandler } = this.props;
    deleteHandler(trackingNumber);
  }

  formSubmit(e) {
    e.preventDefault();
    const { form, requiredFormSubmitFields, formSubmitHandler } = this.props;

    const { validateFieldsAndScroll } = form;
    // eslint-disable-next-line no-unused-vars
    validateFieldsAndScroll(requiredFormSubmitFields, (err, values) => {
      if (!err) {
        formSubmitHandler(values);
      }
    });
  }

  selectMode(e) {
    const { shipmentProvider } = this.state;
    const { form } = this.props;
    if (e === 'land') {
      this.setState({
        mode: e,
        carrierOptions: [],
      });
    } else {
      this.setState({
        mode: e,
        carrierOptions: shipmentProvider[e],
      });
    }
    form.setFieldsValue({ shipment_provider: undefined });
  }

  render() {
    const {
      options,
      isFetching,
      showSecondDriverandHelper,
      mode,
      carrierOptions,
      sizeState,
      saveTrackingNumber,
      outsourcePicker,
      driverMobile,
      helperMobile,
    } = this.state;

    const {
      form,
      formInformation,
      tablInformation,
      listColumns,
      hasDestination,
      hasExpectedArrival,
      hasOutsource,
      hasPackageMerchant,
      hasFormMerchant,
      dispatchItems,
      totalPackageWeight,
      errorItems,
      dispatchDetails,
      errorMessageDispatch,
      isDispatchEdit,
      isSubmitting,
      doPreventDuplicate,
      hasNoChargableWeight,
    } = this.props;
    const { getFieldDecorator } = form;
    const shipmentMode = [
      {
        label: 'Land',
        value: 'land',
      },
      {
        label: 'Sea',
        value: 'sea',
      },
      {
        label: 'Air',
        value: 'air',
      },
    ];

    // console.log(options.outsource);
    return (
      <div className="DispatchForm">
        <Form>
          <Card title={formInformation}>
            <Row>
              <Col xs={24} sm={24} lg={8}>
                <Form.Item label="Driver">
                  {getFieldDecorator('driver_id', {
                    rules: [
                      {
                        required: true,
                        message: 'Driver cannot be blank.',
                      },
                    ],
                    initialValue: dispatchDetails
                      ? dispatchDetails.driver_id
                      : undefined,
                    onChange: (e) => {
                      this.getOutsourceID(e);
                      this.setState({
                        driverMobile: options.courier.find(user => e === user.value).mobile_no,
                      });
                    },
                  })(
                    <Select
                      allowClear
                      loading={isFetching}
                      options={options.courier}
                      onSearch={e => this.searchOption('courier', e)}
                      filterOption={false}
                      placeholder="Type to search for desired driver"
                      name="driver1"
                      showSearch
                    />,
                  )}
                  { driverMobile ? (
                    <p>
                      <strong>Mobile: </strong>
                      { driverMobile }
                    </p>
                  ) : null}
                  {showSecondDriverandHelper ? null : (
                    <Button type="link" onClick={this.setRowVisible}>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                      </Icon>

                      {// eslint-disable-next-line no-nested-ternary
                      dispatchDetails
                        ? dispatchDetails.driver2_id !== null
                          ? 'View second driver and helper'
                          : 'Add second driver and helper'
                        : 'Add second driver and helper'}
                    </Button>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={4}>
                <Form.Item label="Rate">
                  {getFieldDecorator('driver_rate', {
                    rules: [
                      {
                        required: true,
                        message: 'Driver rate cannot be blank.',
                      },
                    ],
                    initialValue: dispatchDetails
                      ? dispatchDetails.driver_rate
                      : undefined,
                  })(
                    <Input
                      type="number"
                      placeholder="Type to put driver rate."
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Col xs={24} sm={24} lg={8}>
                  <Form.Item label="Helper">
                    {getFieldDecorator('helper_id', {
                      rules: [
                        {
                          required: false,
                          message: 'Helper cannot be blank.',
                        },
                      ],
                      initialValue: dispatchDetails
                        ? dispatchDetails.helper_id
                        : undefined,
                      onChange: (e) => {
                        this.setState({
                          helperMobile: options.courier.find(user => e === user.value).mobile_no,
                        });
                      },
                    })(
                      <Select
                        allowClear
                        loading={isFetching}
                        options={options.courier}
                        onSearch={e => this.searchOption('courier', e)}
                        filterOption={false}
                        placeholder="Type to search for desired helper"
                        name="helper1"
                        showSearch
                      />,
                    )}
                    { helperMobile ? (
                      <p>
                        <strong>Mobile: </strong>
                        { helperMobile }
                      </p>
                    ) : null}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={4}>
                  <Form.Item label="Rate">
                    {getFieldDecorator('helper_rate', {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                      initialValue: dispatchDetails
                        ? dispatchDetails.driver_rate
                        : undefined,
                    })(
                      <Input
                        type="number"
                        placeholder="Type to put helper rate."
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            {showSecondDriverandHelper ? (
              <Row>
                <Col xs={24} sm={24} lg={8}>
                  <Form.Item label="Driver2">
                    {getFieldDecorator('driver2_id', {
                      rules: [
                        {
                          required: false,
                          message: 'Driver cannot be blank.',
                        },
                      ],
                      initialValue: dispatchDetails
                        ? dispatchDetails.driver2_id
                        : undefined,
                    })(
                      <Select
                        allowClear
                        loading={isFetching}
                        options={options.courier}
                        onSearch={e => this.searchOption('courier', e)}
                        filterOption={false}
                        placeholder="Type to search for desired driver"
                        name="driver2"
                        showSearch
                      />,
                    )}
                    <Button type="link" onClick={this.setRowHidden}>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faMinus} fixedWidth />
                      </Icon>
                      {// eslint-disable-next-line no-nested-ternary
                      dispatchDetails
                        ? dispatchDetails.driver2_id !== null
                          ? 'Hide second driver and helper'
                          : 'Remove second driver and helper'
                        : 'Remove second driver and helper'}
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={4}>
                  <Form.Item label="Rate2">
                    {getFieldDecorator('driver2_rate', {
                      rules: [
                        {
                          required: false,
                          message: 'Driver rate cannot be blank.',
                        },
                      ],
                      initialValue: dispatchDetails
                        ? dispatchDetails.driver2_rate
                        : undefined,
                    })(
                      <Input
                        type="number"
                        placeholder="Type to put driver rate."
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col>
                  <Col xs={24} sm={24} lg={8}>
                    <Form.Item label="Helper2">
                      {getFieldDecorator('helper2_id', {
                        rules: [
                          {
                            required: false,
                            message: 'Helper cannot be blank.',
                          },
                        ],
                        initialValue: dispatchDetails
                          ? dispatchDetails.driver2_id
                          : undefined,
                      })(
                        <Select
                          allowClear
                          loading={isFetching}
                          options={options.courier}
                          onSearch={e => this.searchOption('courier', e)}
                          filterOption={false}
                          placeholder="Type to search for desired helper"
                          name="helper2"
                          showSearch
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={4}>
                    <Form.Item label="Rate2">
                      {getFieldDecorator('helper2_rate', {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                        initialValue: dispatchDetails
                          ? dispatchDetails.helper2_rate
                          : undefined,
                      })(
                        <Input
                          type="number"
                          placeholder="Type to put driver rate."
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            ) : null}
            {hasDestination ? (
              <React.Fragment>
                <Row>
                  <Col xs={24} sm={24} lg={12}>
                    <Form.Item label="Mode of shipment">
                      {getFieldDecorator('mode_of_shipment', {
                        rules: [
                          {
                            required: true,
                            message: 'Mode of shipment cannot be blank.',
                          },
                        ],
                        initialValue: dispatchDetails
                          ? dispatchDetails.mode_of_shipment
                          : 'land',
                      })(
                        <Select
                          options={shipmentMode}
                          onChange={this.selectMode}
                          placeholder="Select mode of shipment"
                          name="mode_of_shipment"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12}>
                    <Form.Item label="Shipment Provider">
                      {getFieldDecorator('shipment_provider', {
                        rules: [
                          {
                            required: mode !== 'land',
                            message: 'Shipment Provider cannot be blank.',
                          },
                        ],
                        // eslint-disable-next-line no-nested-ternary
                        initialValue: dispatchDetails
                          ? dispatchDetails.carrier_id
                          : undefined,
                      })(
                        <Select
                          allowClear
                          showSearch
                          loading={isFetching}
                          filterOption={false}
                          onSearch={e => this.searchOptionShipment(mode, e)}
                          options={carrierOptions}
                          placeholder="Select shipment provider"
                          name="carrier"
                          disabled={mode === 'land'}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item label="Destination">
                      {getFieldDecorator('destination', {
                        rules: [
                          {
                            required: true,
                            message: 'Destination cannot be blank.',
                          },
                        ],
                        initialValue: dispatchDetails
                          ? dispatchDetails.destination
                          : undefined,
                      })(
                        <Select
                          allowClear
                          showSearch
                          loading={isFetching}
                          filterOption={false}
                          onSearch={e => this.searchOption('destination', e)}
                          options={options.destination}
                          placeholder="Select destination"
                          name="destination"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
            ) : null}
            <Row>
              <Col
                xs={24}
                sm={hasOutsource || hasFormMerchant ? 12 : 24}
                lg={hasOutsource || hasFormMerchant ? 12 : 24}
              >
                <Form.Item label="Vehicle">
                  {getFieldDecorator('vehicle_id', {
                    rules: [
                      {
                        required: true,
                        message: 'Vehicle cannot be blank.',
                      },
                    ],
                    initialValue: dispatchDetails
                      ? dispatchDetails.vehicle_id
                      : undefined,
                  })(
                    <Select
                      allowClear
                      loading={isFetching}
                      options={options.vehicle}
                      onSearch={e => this.searchOption('vehicle', e)}
                      filterOption={false}
                      placeholder="Type to search for desired vehicle"
                      name="shipper_id"
                      showSearch
                    />,
                  )}
                </Form.Item>
              </Col>
              {hasOutsource ? (
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Outsource">
                    {getFieldDecorator('outsource', {
                      rules: [
                        {
                          required: true,
                          message: 'Outsource cannot be blank.',
                        },
                      ],
                      initialValue: dispatchDetails
                        ? dispatchDetails.outsource_id
                        : outsourcePicker,
                    })(
                      <Select
                        allowClear
                        showSearch
                        loading={isFetching}
                        filterOption={false}
                        onSearch={e => this.searchOption('outsource', e)}
                        options={options.outsource}
                        placeholder="Select outsource"
                        name="outsource"
                      />,
                    )}
                  </Form.Item>
                </Col>
              ) : null}
              {hasFormMerchant ? (
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Merchant">
                    {getFieldDecorator('shipper_id', {
                      rules: [
                        {
                          required: true,
                          message: 'Merchant cannot be blank.',
                        },
                      ],
                      initialValue: 'Lazada',
                    })(
                      <Select
                        allowClear
                        loading={isFetching}
                        filterOption={false}
                        placeholder="Type to search for desired merchant"
                        name="shipper_id"
                        showSearch
                        disabled
                      />,
                    )}
                  </Form.Item>
                </Col>
              ) : null}
            </Row>

            {hasExpectedArrival ? (
              <Row>
                <Col>
                  <Form.Item label="Expected Arrival">
                    {getFieldDecorator('expected_arrival', {
                      rules: [
                        {
                          required: true,
                          message: 'Expected Arrival cannot be blank.',
                        },
                      ],
                      initialValue: dispatchDetails
                        ? moment(dispatchDetails.expected_arrival)
                        : undefined,
                    })(
                      <DatePicker
                        style={{ width: '100%' }}
                        showTime={{
                          hideDisabledOptions: true,
                          defaultValue: [moment('00:00:00', 'HH:mm:ss')],
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    rules: [
                      {
                        required: false,
                        message: 'Remarks cannot be blank.',
                      },
                    ],
                    initialValue: dispatchDetails
                      ? dispatchDetails.remarks
                      : undefined,
                  })(
                    <Input.TextArea
                      placeholder="Enter remarks"
                      name="remarks"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Spacer />
          <Card title="Package Form">
            {hasPackageMerchant ? (
              <Row>
                <Col>
                  <Form.Item label="Merchant">
                    {getFieldDecorator('shipper_id', {
                      rules: [
                        {
                          required: true,
                          message: 'Merchant cannot be blank.',
                        },
                      ],
                      initialValue: dispatchDetails
                        ? dispatchDetails.shipper_id
                        : undefined,
                    })(
                      <Select
                        allowClear
                        loading={isFetching}
                        options={options.merchant}
                        onSearch={e => this.searchOption('merchant', e)}
                        filterOption={false}
                        placeholder="Type to search for desired merchant"
                        name="shipper_id"
                        showSearch
                        disabled={dispatchItems.length > 0}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col>
                <Form.Item label="Tracking Number">
                  {getFieldDecorator('tracking_number', {
                    rules: [
                      {
                        required: true,
                        message: 'Tracking Number cannot be blank.',
                      },
                    ],
                    defaultValue: null,
                  })(
                    <Input.TextArea
                      placeholder="Enter tracking number(s)"
                      onPressEnter={
                        doPreventDuplicate ? this.validateTrackingNumber : false
                      }
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.validateTrackingNumber}
                  block
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                  </Icon>
                  Add tracking number
                </Button>
              </Col>
            </Row>
          </Card>
          {saveTrackingNumber && !hasNoChargableWeight ? (
            <React.Fragment>
              {/* {console.log(errorMessageDispatch)} */}
              <Spacer />
              <Card>
                <Row>
                  <Col
                    style={{
                      backgroundColor:
                        errorMessageDispatch !== ' ' ? 'red' : null,
                    }}
                    xs={24}
                    sm={24}
                    lg={sizeState > 30 ? 24 : 8}
                  >
                    <Statistic
                      style={{ textAlign: 'center' }}
                      value={`${saveTrackingNumber}`}
                      valueStyle={{
                        fontSize: `${sizeState}0%`,
                        textAlign: 'center',
                        color: errorMessageDispatch !== ' ' ? 'white' : 'black',
                      }}
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    lg={sizeState > 30 ? 24 : 8}
                    style={{
                      backgroundColor: errorMessageDispatch !== ' ' ? 'red' : null,
                    }}
                  >
                    <Statistic
                      style={{ textAlign: 'center' }}
                      value={errorMessageDispatch}
                      valueStyle={{
                        fontSize: `${(sizeState / 2.5) * 10}%`,
                        textAlign: 'center',
                        color: 'white',
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} lg={sizeState > 30 ? 24 : 8}>
                    <Row>
                      <Col xs={24} sm={24} lg={8}>
                        <Statistic
                          style={{ textAlign: 'center' }}
                          title="Dispatch Item Success :"
                          value={dispatchItems.length}
                          valueStyle={{
                            fontSize: `${sizeState}0%`,
                            textAlign: 'center',
                            color: 'green',
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={24} lg={8}>
                        <Statistic
                          style={{ textAlign: 'center' }}
                          title="Dispatch Item Error :"
                          value={errorItems.length}
                          valueStyle={{
                            fontSize: `${sizeState}0%`,
                            textAlign: 'center',
                            color: 'red',
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={24} lg={8}>
                        <Statistic
                          style={{ textAlign: 'center' }}
                          title="Total Chargable Weight :"
                          value={
                            /* eslint-disable */
                            totalPackageWeight
                              ? (totalPackageWeight.length !== 0
                                ? totalPackageWeight
                                  .map(res => parseFloat(res.cwtTotalNum))
                                  .reduce((a, b) => a + b)
                                  .toFixed(2)
                                : 0)
                              : 0
                          }
                          valueStyle={{
                            fontSize: `${sizeState}0%`,
                            textAlign: 'center',
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Slider
                  defaultValue={50}
                  onChange={e => this.setState({ sizeState: e })}
                />
              </Card>
            </React.Fragment>
          ) : null}
          <Spacer />
          <Card
            title={tablInformation}
            extra={`Total: ${dispatchItems.length}`}
          >
            <ReactTable
              data={dispatchItems}
              columns={[
                ...listColumns,
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: data => (
                    <React.Fragment>
                      <Popconfirm
                        placement="leftBottom"
                        title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
                        okText="Yes"
                        onConfirm={() => this.confirmDelete(data.row.tracking_number)
                        }
                        cancelText="No"
                      >
                        <Button type="link" block>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faTrash} fixedWidth />
                          </Icon>
                          Remove
                        </Button>
                      </Popconfirm>
                    </React.Fragment>
                  ),
                },
              ]}
            />
          </Card>
          {errorItems.length > 0 ? (
            <React.Fragment>
              <Spacer />
              <Card title="Error List" extra={`Total: ${errorItems.length}`}>
                <ReactTable
                  data={errorItems}
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Message',
                      accessor: 'message',
                    },
                  ]}
                />
              </Card>
            </React.Fragment>
          ) : null}
          <Spacer />
          <Button
            type="primary"
            htmlType="submit"
            onClick={this.formSubmit}
            block
            disabled={dispatchItems.length === 0 || isSubmitting}
          >
            <Icon viewBox="0 0 1024 1024">
              <FontAwesomeIcon
                icon={isDispatchEdit ? faSave : faPlus}
                fixedWidth
              />
            </Icon>
            {isDispatchEdit ? 'Update' : 'Create'}
          </Button>
        </Form>
      </div>
    );
  }
}

DispatchForm.defaultProps = {
  dispatchDetails: false,
  hasDestination: false,
  hasExpectedArrival: false,
  hasOutsource: false,
  hasPackageMerchant: false,
  hasFormMerchant: false,
  isDispatchEdit: false,
  isSubmitting: false,
  totalPackageWeight: [],
  errorMessageDispatch: '',
};

DispatchForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectOptions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  requiredFormSubmitFields: PropTypes.oneOfType([PropTypes.array]).isRequired,
  requiredPackageFormFields: PropTypes.oneOfType([PropTypes.array]).isRequired,
  formInformation: PropTypes.string.isRequired,
  tablInformation: PropTypes.string.isRequired,
  hasDestination: PropTypes.bool,
  hasExpectedArrival: PropTypes.bool,
  hasOutsource: PropTypes.bool,
  doPreventDuplicate: PropTypes.bool.isRequired,
  hasNoChargableWeight: PropTypes.bool.isRequired,
  hasPackageMerchant: PropTypes.bool,
  hasFormMerchant: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isDispatchEdit: PropTypes.bool,
  dispatchItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  totalPackageWeight: PropTypes.oneOfType([PropTypes.array]),
  listColumns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  errorItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  errorMessageDispatch: PropTypes.string,
  dispatchDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  doFetchOptionsLike: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  packageFormHandler: PropTypes.func.isRequired,
  formSubmitHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  doFetchShipmentProviders: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
  doFetchShipmentProviders: fetchShipmentProviders,
};

const WrappedDispatchForm = Form.create({ name: 'DispatchForm' })(DispatchForm);

export default connect(null, mapDispatchToProps)(WrappedDispatchForm);

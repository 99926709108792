import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input, Icon, Button, Col, Form, message, Radio,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faTimes,
  faSave,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';
import ServerSideTable from '../../components/ServerSideTable';
import Select from '../../components/Select';
import { fetchClientList, createClient, updateClient } from './ducks';
import { fetchOptions } from '../ducks';

export class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      currentTable: {
        page: null,
        pageSize: null,
        filter: [],
        sorted: [],
      },
      isLoading: false,
      editedClient: null,
      clientPrefixOptions: [],
    };

    this.onFetchData = this.onFetchData.bind(this);
    this.formCreate = this.formCreate.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.formUpdate = this.formUpdate.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions } = this.props;

    doFetchOptions('branch_code').then((res) => {
      this.setState({
        clientPrefixOptions: res.payload.data,
      });
    });
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { doFetchClientList } = this.props;

    const params = {
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };

    this.setState({
      isLoading: true,
      currentTable: params,
    });

    doFetchClientList(params).then((action) => {
      this.setState({
        clientList: {
          rows: action.payload.data.rows,
          pages: action.payload.data.pages,
          total: action.payload.data.total,
        },
        isLoading: false,
      });
    });
  }

  onEdit(cellInfo) {
    const { clientList, editedClient, clientPrefixOptions } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    if (editedClient === cellInfo.original.client_id) {
      if (cellInfo.column.id === 'package_privacy') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: clientList.rows[cellInfo.index][cellInfo.column.id],
            })(<Select
              allowClear={false}
              options={[
                {
                  label: 'Private',
                  value: 'private',
                },
                {
                  label: 'Public',
                  value: 'public',
                },
              ]}
            />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'client_prefixes') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [
                { required: true, message: 'Client prefixes cannot be blank.' },
              ],
              initialValue: JSON.parse(clientList.rows[cellInfo.index][cellInfo.column.id]),
            })(<Select
              options={clientPrefixOptions}
              mode="multiple"
              placeholder="Select client prefixes"
              autoComplete="off"
            />)}
          </Form.Item>
        );
      }
      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}_update`, {
            rules: [
              {
                required: true,
                message: `${cellInfo.column.Header} cannot be blank.`,
              },
            ],
            initialValue: clientList.rows[cellInfo.index][cellInfo.column.id],
          })(
            <Input
              placeholder={`Enter ${cellInfo.column.Header}`}
              autoComplete="off"
            />,
          )}
        </Form.Item>
      );
    }

    return (
      <React.Fragment>
        {clientList.rows[cellInfo.index][cellInfo.column.id]}
      </React.Fragment>
    );
  }

  setToEdit(vehicleId) {
    this.setState({
      editedClient: vehicleId,
    });
  }

  cancelEdit() {
    this.setState({
      editedClient: null,
    });
  }

  formUpdate(e) {
    e.preventDefault();
    const { editedClient } = this.state;
    const { doUpdateClient, form } = this.props;
    const { validateFields } = form;

    validateFields(
      [
        'name_update',
        'username_update',
        'contact_number_update',
        'address_update',
        'package_privacy_update',
        'client_prefixes_update',
      ],
      (err, values) => {
        if (!err) {
          const data = {
            name: values.name_update,
            username: values.username_update,
            contact_number: values.contact_number_update,
            address: values.address_update,
            package_privacy: values.package_privacy_update,
            client_prefixes: values.client_prefixes_update,
          };
          doUpdateClient(editedClient, data)
            .then(() => {
              const { currentTable } = this.state;

              const params = {
                page: currentTable.page,
                pageSize: currentTable.pageSize,
                filtered: currentTable.filter,
                sorted: currentTable.sorted,
              };

              this.setState({
                editedClient: null,
              });

              message.success('Successfully updated client!');
              this.onFetchData(params);
            })
            .catch((action) => {
              let errorMessage;
              if (action.error.response.status === 400) {
                errorMessage = action.error.response.data.message;
              } else {
                errorMessage = 'Something went wrong, please try again later.';
              }
              message.error(errorMessage, 3);
            });
        }
      },
    );
  }

  formCreate(e) {
    e.preventDefault();

    const { doCreateClient, form } = this.props;
    const { validateFields } = form;

    validateFields(
      ['name', 'username', 'contact_number', 'address', 'package_privacy', 'client_prefixes'],
      (err, values) => {
        if (!err) {
          doCreateClient(values)
            .then(() => {
              const { currentTable } = this.state;

              const params = {
                page: currentTable.page,
                pageSize: currentTable.pageSize,
                filtered: currentTable.filter,
                sorted: currentTable.sorted,
              };

              message.success('Successfully created client!');
              this.onFetchData(params);
              form.resetFields();
            })
            .catch((action) => {
              let errorMessage;
              if (action.error.response.status === 400) {
                errorMessage = action.error.response.data.message;
              } else {
                errorMessage = 'Something went wrong, please try again later.';
              }
              message.error(errorMessage, 3);
            });
        }
      },
    );
  }

  render() {
    const {
      clientList, isLoading, editedClient, clientPrefixOptions,
    } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Admin',
      },
      {
        breadcrumbName: 'Client',
      },
    ];
    return (
      <div className="Client">
        <PageHeader title="Clients" routes={breadCrumbs} />
        <Container>
          <Card title="Client Information">
            <Form onSubmit={this.formCreate}>
              <Row>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Client Name">
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: 'Client Name cannot be blank.',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter client name"
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Username">
                    {getFieldDecorator('username', {
                      rules: [
                        { required: true, message: 'Username cannot be blank.' },
                      ],
                    })(
                      <Input placeholder="Enter username" autoComplete="off" />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Contact Number">
                    {getFieldDecorator('contact_number', {
                      rules: [
                        {
                          required: true,
                          message: 'Contact number cannot be blank.',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter contact number"
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item label="Address">
                    {getFieldDecorator('address', {
                      rules: [
                        { required: true, message: 'Address cannot be blank.' },
                      ],
                    })(<Input placeholder="Enter address" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item label="Client Prefixes">
                    {getFieldDecorator('client_prefixes', {
                      rules: [
                        { required: true, message: 'Client prefixes cannot be blank.' },
                      ],
                    })(<Select
                      options={clientPrefixOptions}
                      mode="multiple"
                      placeholder="Select client prefixes"
                      autoComplete="off"
                    />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item label="Package Privacy" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} labelAlign="left">
                    {getFieldDecorator('package_privacy', {
                      rules: [
                        {
                          required: true,
                        },
                      ],
                      initialValue: 'private',
                    })(
                      <Radio.Group>
                        <Radio value="private">Private</Radio>
                        <Radio value="public">Public</Radio>
                      </Radio.Group>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} lg={24}>
                  <Button type="primary" htmlType="submit" block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faPlus} fixedWidth />
                    </Icon>
                    Create
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          <Spacer />
          <Card
            title="Client List"
            extra={`Total: ${clientList.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <Form onSubmit={this.formUpdate}>
              <ServerSideTable
                data={clientList.rows}
                pages={clientList.pages}
                columns={[
                  {
                    Header: 'ID',
                    accessor: 'client_id',
                  },
                  {
                    Header: 'Client Name',
                    accessor: 'name',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Address',
                    accessor: 'address',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Contact Number',
                    accessor: 'contact_number',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Client Prefixes',
                    accessor: 'client_prefixes',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Package Privacy',
                    accessor: 'package_privacy',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Date Created',
                    accessor: 'created_at',
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    width: editedClient !== null ? 210 : 100,
                    Cell: data => (
                      <Row>
                        {editedClient !== data.row.client_id ? (
                          <Row>
                            <Col>
                              <Button
                                type="link"
                                onClick={() => this.setToEdit(data.row.client_id)
                                }
                                block
                              >
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faEdit} fixedWidth />
                                </Icon>
                                Edit
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <div>
                            <Col span={12}>
                              <Button type="link" htmlType="submit" block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faSave} fixedWidth />
                                </Icon>
                                Save
                              </Button>
                            </Col>

                            <Col span={12}>
                              <Button
                                type="link"
                                onClick={this.cancelEdit}
                                block
                              >
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faTimes} fixedWidth />
                                </Icon>
                                Cancel
                              </Button>
                            </Col>
                          </div>
                        )}
                      </Row>
                    ),
                  },
                ]}
                loadingText="Fetching client list . . ."
                loading={isLoading}
                onFetchData={this.onFetchData}
              />
            </Form>
          </Card>
        </Container>
      </div>
    );
  }
}

Client.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchClientList: PropTypes.func.isRequired,
  doCreateClient: PropTypes.func.isRequired,
  doUpdateClient: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchClientList: fetchClientList,
  doCreateClient: createClient,
  doUpdateClient: updateClient,
  doFetchOptions: fetchOptions,
};
const WrappedClient = Form.create({ name: 'Client' })(Client);

export default connect(null, mapDispatchToProps)(WrappedClient);

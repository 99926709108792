import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, Steps, Upload, Col, Icon, Button, Row, message, Result, Spin,
} from 'antd';
import Container from '../../../components/Container';
import Spacer from '../../../components/Spacer';
import PageHeader from '../../../components/PageHeader';
import Select from '../../../components/Select';
import {
  validateMassCreateUploadRequest,
  validateMassUpdateUploadRequest,
} from '../ducks';
import PortClusterMassCreate from './PortClusterMassCreate';
import PortClusterMassUpdate from './PortClusterMassUpdate';

function Main(props) {
  const breadCrumbs = [
    {
      breadcrumbName: 'Home',
    },
    {
      breadcrumbName: 'Admin',
    },
    {
      breadcrumbName: 'Port Cluster',
    },
    {
      breadcrumbName: 'Upload',
    },
  ];

  const { doValidateMassCreateUploadRequest, doValidateMassUpdateUploadRequest } = props;

  const { Step } = Steps;
  const { Dragger } = Upload;

  const [currentStep, setCurrentStep] = useState(0);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [spinText, setSpinText] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploadType, setUploadType] = useState('Create');
  const [fileContentPreview, setFileContentPreview] = useState({
    success: [],
    total_succes: 0,
    error: [],
    total_error: 0,
  });

  const beforeUpload = (file) => {
    setFileList([file]);
    return false;
  };

  const removeUpload = () => {
    setFileList([]);
  };

  // eslint-disable-next-line consistent-return
  const validateFile = () => {
    if (fileList.length === 0) {
      message.error('Please provide an excel file to be validated!');
    } else {
      setSpinText('Validating excel file...');
      setIsValidating(true);
      setUploadDisabled(true);

      const formData = new FormData();

      formData.append('file', fileList[0]);

      if (uploadType === 'Create') {
        if (fileList[0].name.includes('update')) {
          message.error('Cannot upload an update file format!');
          setIsValidating(false);
          setUploadDisabled(false);
          return false;
        }

        // perform dispatch
        doValidateMassCreateUploadRequest(formData).then((action) => {
          setCurrentStep(1);
          setFileContentPreview({
            success: action.payload.data.success,
            total_success: action.payload.data.total_success,
            errors: action.payload.data.errors,
            total_errors: action.payload.data.total_errors,
          });
          setIsValidating(false);
          setUploadDisabled(false);
          setFileList([]);
        }).catch(() => {
          setIsValidating(false);
          setUploadDisabled(false);
          setFileList([]);
        });
      } else if (uploadType === 'Update') {
        if (fileList[0].name.includes('create')) {
          message.error('Cannot upload a create file format!');
          setIsValidating(false);
          setUploadDisabled(false);
          return false;
        }

        // perform dispatch
        doValidateMassUpdateUploadRequest(formData).then((action) => {
          setCurrentStep(1);
          setFileContentPreview({
            success: action.payload.data.success,
            total_success: action.payload.data.total_success,
            errors: action.payload.data.errors,
            total_errors: action.payload.data.total_errors,
          });
          setIsValidating(false);
          setUploadDisabled(false);
          setFileList([]);
        }).catch(() => {
          setIsValidating(false);
          setUploadDisabled(false);
          setFileList([]);
        });
      }
    }
  };

  return (
    <Spin tip={spinText} spinning={isValidating || isUpdating}>
      <Container
        title="Port Cluster List"
      >
        <PageHeader title="PORT CLUSTER UPLOAD" routes={breadCrumbs} />
        <Spacer />
        <Card>
          <Steps current={currentStep}>
            <Step title="Select File" />
            <Step title="File Preview" />
            <Step title="Done" />
          </Steps>
        </Card>
        <Spacer />
        <Card
          title={`Upload Form - ${uploadType}`}
          extra={(
            <React.Fragment>
              {
                        currentStep === 0 ? (
                          <Row gutter={12}>
                            <Col span={8}>
                              <Select
                                allowClear={false}
                                onSelect={(e) => {
                                  setUploadType(e);
                                }}
                                defaultValue="Create"
                                style={{ widht: '100%' }}
                                options={[
                                  {
                                    value: 'Update',
                                    label: 'Update',
                                  },
                                  {
                                    value: 'Create',
                                    label: 'Create',
                                  },
                                ]}
                              />
                            </Col>
                            <Col span={8}>
                              {
                                        uploadType === 'Create' ? (
                                          <Button
                                            type="primary"
                                            onClick={() => window.open(
                                              `${process.env.REACT_APP_API_URL}/templates/template_port_cluster_create.xlsx`,
                                            )}
                                          >
                                                Download Create Template
                                          </Button>
                                        ) : (
                                          <Button
                                            type="primary"
                                            onClick={() => window.open(
                                              `${process.env.REACT_APP_API_URL}/templates/template_port_cluster_update.xlsx`,
                                            )}
                                          >
                                                Download Update Template
                                          </Button>
                                        )
                                    }
                            </Col>
                          </Row>
                        ) : ''
                    }
            </React.Fragment>
)}
        >
          {currentStep === 0 ? (
            <React.Fragment>
              <Dragger
                fileList={fileList}
                beforeUpload={file => beforeUpload(file)}
                onRemove={() => { removeUpload(); }}
                multiple={false}
                accept=".xlsx"
                disabled={uploadDisabled}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Download the template on the top right to use the correct format.</p>
              </Dragger>
              <Spacer />
              <Button onClick={() => { validateFile(); }} type="primary" style={{ float: 'right' }} disabled={isValidating}>
                {isValidating ? '...Validating' : 'Submit'}
              </Button>
            </React.Fragment>
          ) : ''}

          {currentStep === 1 ? (
            <React.Fragment>
              {
                            uploadType === 'Create' ? (
                              <PortClusterMassCreate
                                fileContentPreview={fileContentPreview}
                                currentStep={currentStep}
                                setCurrentStep={setCurrentStep}
                                setIsUpdating={setIsUpdating}
                                isUpdating={isUpdating}
                                setSpinText={setSpinText}
                              />
                            ) : (
                              <PortClusterMassUpdate
                                fileContentPreview={fileContentPreview}
                                currentStep={currentStep}
                                setCurrentStep={setCurrentStep}
                                setIsUpdating={setIsUpdating}
                                isUpdating={isUpdating}
                                setSpinText={setSpinText}
                              />
                            )
                        }
            </React.Fragment>
          ) : ''}

          {currentStep === 2 ? (
            <Result
              status="success"
              title="File successfully uploaded!"
              extra={[
                <Button
                  type="primary"
                  key="console"
                  onClick={() => {
                    setCurrentStep(0);
                  }}
                >
                        Finish
                </Button>,
              ]}
            />
          ) : ''}
        </Card>

      </Container>
    </Spin>
  );
}

Main.propTypes = {
  doValidateMassCreateUploadRequest: PropTypes.func.isRequired,
  doValidateMassUpdateUploadRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doValidateMassCreateUploadRequest: validateMassCreateUploadRequest,
  doValidateMassUpdateUploadRequest: validateMassUpdateUploadRequest,
};

export default connect(null, mapDispatchToProps)(Main);

import {
  apiCreateWaybill,
  apiFetchWaybillList,
  apiFetchWaybillDetails,
  apiUploadWaybill,
  apiPrintWaybillList,
  apiExportWaybillItems,
  apiCreateUploadTemplate,
  apiValidateWaybillUpload,
  apiOldUploadWaybill,
  apiExportWaybill,
  apiGenerateOtpEmail
} from '../../services/apiEndpoints';

const CREATE_WAYBILL = 'waybill/CREATE_WAYBILL';
export const createWaybill = data => ({
  type: CREATE_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateWaybill,
      data,
    },
  },
});

const GENERATE_OTP_EMAIL = 'otp/EMAIL';
export const generateOtpEmail = data => ({
  type: GENERATE_OTP_EMAIL,
  payload: {
    client: 'secure',
    request: {
      ...apiGenerateOtpEmail,
      data,
    },
  },
});

const FETCH_WAYBILL_LIST = 'waybill/FETCH_WAYBILL_LIST';
export const fetchWaybillList = data => ({
  type: FETCH_WAYBILL_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchWaybillList,
      data,
    },
  },
});

const PRINT_WAYBILL_LIST = 'waybill/PRINT_WAYBILL_LIST';
export const printWaybillList = data => ({
  type: PRINT_WAYBILL_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiPrintWaybillList,
      data,
    },
  },
});

const EXPORT_WAYBILL_ITEMS = 'waybill/EXPORT_WAYBILL_ITEMS';
export const exportWaybillItems = data => ({
  type: EXPORT_WAYBILL_ITEMS,
  payload: {
    client: 'secure',
    request: {
      ...apiExportWaybillItems,
      data,
    },
  },
});

const FETCH_WAYBILL_DETAILS = 'waybill/FETCH_WAYBILL_DETAILS';
export const fetchWaybillDetails = trackingNumber => ({
  type: FETCH_WAYBILL_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchWaybillDetails(trackingNumber),
    },
  },
});

const CREATE_WAYBILL_UPLOAD_TEMPLATE = 'waybill/CREATE_WAYBILL_UPLOAD_TEMPLATE';
export const createWaybillUploadTemplate = data => ({
  type: CREATE_WAYBILL_UPLOAD_TEMPLATE,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateUploadTemplate,
      data,
    },
  },
});

const VALIDATE_WAYBILL_UPLOAD = 'waybill/VALIDATE_WAYBILL_UPLOAD';
export const validateWaybillUpload = data => ({
  type: VALIDATE_WAYBILL_UPLOAD,
  payload: {
    client: 'secure',
    request: {
      ...apiValidateWaybillUpload,
      data,
    },
  },
});

const UPLOAD_WAYBILL = 'waybill/UPLOAD_WAYBILL';
export const uploadWaybill = data => ({
  type: UPLOAD_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiUploadWaybill,
      data,
    },
  },
});

const OLD_UPLOAD_WAYBILL = 'waybill/OLD_UPLOAD_WAYBILL';
export const oldUploadWaybill = data => ({
  type: OLD_UPLOAD_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiOldUploadWaybill,
      data,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    },
  },
});

const EXPORT_WAYBILL = 'waybill/EXPORT_WAYBILL';
export const exportWaybill = (data, progress) => ({
  type: EXPORT_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiExportWaybill,
      ...progress,
      data,
    },
  },
});

const SAVE_TEMPLATE_TO_STATE = 'waybill/SAVE_TEMPLATE_TO_STATE';
export const saveExportTemplateToState = exportTemplate => ({
  type: SAVE_TEMPLATE_TO_STATE,
  payload: {
    data: {
      exportTemplate,
    },
  },
});

export const defaultTemplate = {
  client: true,
  tracking_number: true,
  package_id: true,
  order_no: false,
  status: true,
  remitted: false,
  status_reference: true,
  reference_1: true,
  reference_2: false,
  reference_3: false,
  reference_4: false,
  reference_5: false,
  group_id: false,
  payment_type: true,
  total_price: true,
  declared_value: true,
  package_size: false,
  package_total_quantity: true,
  package_length: true,
  package_width: true,
  package_height: true,
  package_weight: true,
  package_type: true,
  package_remarks: false,
  delivery_type: true,
  transport_mode: false,
  shipping_type: true,
  journey_type: false,
  item_id: true,
  item_description: true,
  consignee_name: true,
  consignee_mobile_number: true,
  consignee_email_address: false,
  consignee_full_address: true,
  consignee_province: true,
  consignee_city: true,
  consignee_barangay: true,
  consignee_building_type: false,
  consignee_coordinate: false,
  shipper_id: false,
  shipper_name: false,
  shipper_contact_number: false,
  shipper_address: false,
  shipper_cluster: false,
  shipper_port_code: false,
  shipper_barangay: false,
  shipper_city: false,
  shipper_province: false,
  ageing: true,
  first_attempt_status: false,
  first_attempt_description: false,
  first_attempt_comment: false,
  first_attempt_date: false,
  second_attempt_status: false,
  second_attempt_description: false,
  second_attempt_comment: false,
  second_attempt_date: false,
  third_attempt_status: false,
  third_attempt_description: false,
  third_attempt_comment: false,
  third_attempt_date: false,
  last_status_date: true,
  last_status_reference: false,
  last_status_remarks: false,
  updated_by: false,
  picked_date: false,
  accepted_main_date: false,
  transfer_date: false,
  accepted_branch_reference: false,
  accepted_branch_date: false,
  delivery_date: false,
  last_delivery_date: false,
  handover_date: true,
  number_of_attempts: false,
  location: true,
  target_location: true,
  package_cluster: true,
  received_by: false,
  pickup_datetime: false,
  pickup_start_time: false,
  pickup_end_time: false,
  created_at: true,
  updated_at: false,
};

const initialState = {
  exportTemplate: defaultTemplate,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_TEMPLATE_TO_STATE:
      return {
        ...state,
        exportTemplate: action.payload.data.exportTemplate,
      };
    default:
      return state;
  }
}

export default reducer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, Col, Form, Upload, message, Card,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import Row from '../../components/Row';
import Select from '../../components/Select';
import Spacer from '../../components/Spacer';

export class OldWaybillUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
    };

    this.beforeUploadHandler = this.beforeUploadHandler.bind(this);
    this.removeUploadHandler = this.removeUploadHandler.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  beforeUploadHandler(file) {
    const { fileList } = this.state;

    if (fileList.length === 1) {
      message.error('Cannot upload more than 1 file!');
      return false;
    }
    this.setState(state => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  }

  // eslint-disable-next-line no-unused-vars
  removeUploadHandler(file) {
    this.setState({
      fileList: [],
    });

    // Multiple remove entries
    //
    // this.setState(state => {
    //     const index = state.fileList.indexOf(file);
    //     const newFileList = state.fileList.slice();
    //     newFileList.splice(index, 1);
    //     return {
    //       fileList: newFileList,
    //     };
    //   });
  }

  formSubmit() {
    const { fileList } = this.state;
    const { form, doUploadWaybill } = this.props;
    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        if (fileList.length > 0) {
          const formData = new FormData();
          fileList.forEach((file) => {
            formData.append('file', file);
          });

          formData.append('client_id', values.client_id);
          formData.append('branch_code', values.branch_code);

          doUploadWaybill(formData).then(() => {
            this.setState({
              fileList: [],
            });
            form.resetFields();
            message.success('Successfully uploaded file!');
          }).catch((action) => {
            let errorMessage;
            if (action.error.response.status === 400) {
              errorMessage = action.error.response.data.message;
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
        } else {
          message.error('Please upload an .csv file before submitting');
        }
      }
    });
  }

  render() {
    const { fileList } = this.state;
    const {
      form, clientOptions, branchCodeOptions, searchOption,
    } = this.props;
    const { getFieldDecorator } = form;
    const { Dragger } = Upload;

    return (
      <React.Fragment>
        <Form encType="multipart/form-data">
          <Card>
            <Row>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item label="Client">
                  {getFieldDecorator('client_id', {
                    rules: [{ required: true, message: 'Client cannot be blank' }],
                  })(<Select
                    options={clientOptions}
                    allowClear
                    onSearch={e => searchOption('client', e)}
                    filterOption={false}
                    placeholder="Type to search for client"
                    showSearch
                  />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item label="Branch Code">
                  {getFieldDecorator('branch_code', {
                    rules: [{ required: true, message: 'Branch code cannot be blank' }],
                  })(<Select
                    options={branchCodeOptions}
                    placeholder="Selet branch code"
                    allowClear
                    onSearch={e => searchOption('branch_code', e)}
                    filterOption={false}
                    showSearch
                  />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Dragger
                  fileList={fileList}
                  onRemove={this.removeUploadHandler}
                  beforeUpload={this.beforeUploadHandler}
                  accept=".csv"
                >
                  <p className="ant-upload-drag-icon">
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faInbox} fixedWidth />
                    </Icon>
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                        Note: single file upload of .csv is allowed
                  </p>
                </Dragger>
              </Col>
            </Row>
            <Spacer />
            <Row>
              <Button type="primary" onClick={this.formSubmit} block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faFileUpload} fixedWidth />
                </Icon>
                      Upload
              </Button>
            </Row>
          </Card>
        </Form>
      </React.Fragment>
    );
  }
}

OldWaybillUpload.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  clientOptions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  branchCodeOptions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  searchOption: PropTypes.func.isRequired,
  doUploadWaybill: PropTypes.func.isRequired,
};

const WrappedOldWaybillUpload = Form.create({ name: 'OldWaybillUpload' })(OldWaybillUpload);

export default WrappedOldWaybillUpload;

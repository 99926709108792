import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  DatePicker, Col, Form, Button, Icon, Input,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import { rubixpickOptions } from '../../components/options/ducks';
import { waybill } from '../../components/waybill/ducks';
import { reportList, reportNoFilterList, fetchOptions } from './ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactSelect from '../../components/Select';
import Spacer from '../../components/Spacer';

export class DispatchedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFilter: false,
      loading: false,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      payloadReq: {
        export: false,
        page: 0,
        pageSize: 0,
        sorted: [],
      },
      optionState: {
        destination: [],
        client: [],
        shipping_type: [],
      },
      handoverDate: {
        dateFrom: null,
        dateTo: null,
      },
      tableFilter: [],
      searchFilter: [],
      isFetchingSelect: false,
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
  }

  componentDidMount() {
    const { doRubixpickOptions } = this.props;
    doRubixpickOptions('destination').then(res => this.setState(prevState => ({
      optionState: {
        ...prevState.optionState,
        destination: res.payload.data.slice(0, 50),
      },
    })));
    doRubixpickOptions('client').then(res => this.setState(prevState => ({
      optionState: {
        ...prevState.optionState,
        client: res.payload.data.slice(0, 50),
      },
    })));
    doRubixpickOptions('shipping_type').then(res => this.setState(prevState => ({
      optionState: {
        ...prevState.optionState,
        shipping_type: res.payload.data.slice(0, 50),
      },
    })));
  }

  onFetchDataFunc(state) {

    const dateAdd = new Date();
    dateAdd.setHours(23);
    dateAdd.setMinutes(59);
    dateAdd.setSeconds(59);
    const formatDateTo = format(dateAdd, 'YYYY-MM-DD HH:mm:ss');
    dateAdd.setHours(0);
    dateAdd.setMinutes(0);
    dateAdd.setSeconds(0);
    dateAdd.setDate(dateAdd.getDate() - 7);
    const formatDateFrom = format(dateAdd, 'YYYY-MM-DD HH:mm:ss');

    this.setState({
      handoverDate: {
        dateFrom: formatDateFrom,
        dateTo: formatDateTo,
      },
    });
    // console.log(state);
    const { searchFilter, hasFilter } = this.state;
    const { dispatchNoFilterItems } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };
    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    this.setState({
      payloadReq: paramsReq,
    });
    if (hasFilter) {
      this.setState({
        loading: true,
      });

      dispatchNoFilterItems('dispatch/items', paramsReq).then(res => this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        tableFilter: state.filter,
      }));
    }
  }

  getEditAccountHandler(e) {
    const { waybillComponent, history } = this.props;
    waybillComponent(e.tracking_number).then(() => history.push(`/waybill/${e.tracking_number}`));
    // console.log(e);
  }

  searchFilter(value, name) {
    this.setState({
      isFetchingSelect: true,
    });
    const { doFetchOptions } = this.props;
    doFetchOptions(name, value).then(res => this.setState(prevState => ({
      optionState: {
        ...prevState.optionState,
        [name]: res.payload.data.slice(0, 50),
      },
      isFetchingSelect: false,
    })));
  }

  submitHandler() {
    const { payloadReq, handoverDate, tableFilter } = this.state;
    const { dispatchNoFilterItems, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        const paramsReq = {
          export: false,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: [
            values.client
              ? { id: 'client_id', value: values.client }
              : { id: 'client_id' },
            handoverDate.dateFrom
              ? { id: 'dateFrom', value: handoverDate.dateFrom }
              : { id: 'dateFrom' },
            handoverDate.dateTo
              ? { id: 'dateTo', value: handoverDate.dateTo }
              : { id: 'dateTo' },
            values.destination
              ? { id: 'location', value: values.destination }
              : { id: 'location' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number' },
            values.dNumber
              ? { id: 'dispatch_manifest', value: values.dNumber }
              : { id: 'dispatch_manifest' },
            values.shipping_type
              ? { id: 'shipping_type', value: values.shipping_type }
              : { id: 'shipping_type' },
            values.dispatch_type
              ? { id: 'type', value: values.dispatch_type }
              : { id: 'type' },
          ],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filter = paramsReq.filter.concat(tableFilter);
        }
        dispatchNoFilterItems('dispatch/items', paramsReq).then(res => this.setState({
          loading: false,
          hasFilter: true,
          userData: {
            rows: res.payload.data.rows,
            pages: res.payload.data.pages,
            total: res.payload.data.total,
          },
          payloadReq: paramsReq,
          searchFilter: [
            values.client
              ? { id: 'client_id', value: values.client }
              : { id: 'client_id' },
            handoverDate.dateFrom
              ? { id: 'dateFrom', value: handoverDate.dateFrom }
              : { id: 'dateFrom' },
            handoverDate.dateTo
              ? { id: 'dateTo', value: handoverDate.dateTo }
              : { id: 'dateTo' },
            values.destination
              ? { id: 'location', value: values.destination }
              : { id: 'location' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number' },
            values.dNumber
              ? { id: 'dispatch_manifest', value: values.dNumber }
              : { id: 'dispatch_manifest' },
            values.shipping_type
              ? { id: 'shipping_type', value: values.shipping_type }
              : { id: 'shipping_type' },
            values.dispatch_type
              ? { id: 'type', value: values.dispatch_type }
              : { id: 'type' },
          ],
        }));
        // this.setState({
        //   hasFilter: true,
        // }, () => this.onFetchDataFunc(paramsReq));
      }
    });
  }

  submitHandlerExport() {
    const { payloadReq, handoverDate, tableFilter } = this.state;
    const { dispatchNoFilterItems, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        const paramsReq = {
          export: true,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: [
            values.client
              ? { id: 'client_id', value: values.client }
              : { id: 'client_id' },
            handoverDate.dateFrom
              ? { id: 'dateFrom', value: handoverDate.dateFrom }
              : { id: 'dateFrom' },
            handoverDate.dateTo
              ? { id: 'dateTo', value: handoverDate.dateTo }
              : { id: 'dateTo' },
            values.destination
              ? { id: 'location', value: values.destination }
              : { id: 'location' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number' },
            values.dNumber
              ? { id: 'dispatch_manifest', value: values.dNumber }
              : { id: 'dispatch_manifest' },
            values.shipping_type
              ? { id: 'shipping_type', value: values.shipping_type }
              : { id: 'shipping_type' },
            values.dispatch_type
              ? { id: 'type', value: values.dispatch_type }
              : { id: 'type' },
          ],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filter = paramsReq.filter.concat(tableFilter);
        }
        dispatchNoFilterItems('dispatch/items', paramsReq).then((res) => {
          const newBlob = new Blob([res.payload.data]);
          fileDownload(newBlob, 'Dispatch-items.csv');
          this.setState({
            loading: false,
          });
        });
      }
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    this.setState({
      handoverDate: {
        dateFrom: formatDateOne !== 'Invalid Date' ? formatDateOne : '',
        dateTo: formatDateTwo !== 'Invalid Date' ? formatDateTwo : '',
      },
    });
  }

  render() {
    const {
      loading, userData, optionState, isFetchingSelect,
      handoverDate, hasFilter,
    } = this.state;

    const dateAdd = new Date();
    dateAdd.setHours(23);
    dateAdd.setMinutes(59);
    dateAdd.setSeconds(59);
    const formatDateTo = format(dateAdd, 'YYYY-MM-DD HH:mm:ss');
    dateAdd.setHours(0);
    dateAdd.setMinutes(0);
    dateAdd.setSeconds(0);
    dateAdd.setDate(dateAdd.getDate() - 7);
    const formatDateFrom = format(dateAdd, 'YYYY-MM-DD HH:mm:ss');

    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Report',
      },
      {
        breadcrumbName: 'Dispatched',
      },
    ];
    return (
      <div className="DispatchedItems">
        <PageHeader title="DISPATCHED ITEMS" routes={breadCrumbs} />
        <Spacer />
        <Container>
          <Card>
            <Row>
              <Col xs={24} sm={16} lg={16}>
                <Form.Item label="Date Range">
                  <DatePicker.RangePicker
                    onChange={e => this.dateHandler(e)}
                    style={{ width: '100%' }}
                    defaultValue={[
                      moment(formatDateFrom, 'YYYY-MM-DD HH:mm:ss'),
                      moment(formatDateTo, 'YYYY-MM-DD HH:mm:ss'),
                    ]}
                    showTime={{
                      hideDisabledOptions: true,
                      format: 'HH:mm',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} lg={8}>
                <Form.Item label="Dispatch Type">
                  {getFieldDecorator('dispatch_type')(
                    <ReactSelect
                      allowClear
                      filterOption={false}
                      style={{ width: '100%' }}
                      placeholder="Select dispatch type..."
                      options={[
                        { value: 'delivery', label: 'Delivery' },
                        { value: 'rts', label: 'RTS' },
                        { value: 'rts_transfer', label: 'RTS Transfer' },
                        { value: 'transfer', label: 'Transfer' },
                      ]}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={8} lg={8}>
                <Form.Item label="Client">
                  {getFieldDecorator('client', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(
                    <ReactSelect
                      allowClear
                      showSearch
                      loading={isFetchingSelect}
                      filterOption={false}
                      onSearch={e => this.searchFilter(e, 'client')}
                      style={{ width: '100%' }}
                      placeholder="Select Client..."
                      options={optionState.client}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} lg={8}>
                <Form.Item label="Dispatch Location">
                  {getFieldDecorator('destination', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(
                    <ReactSelect
                      allowClear
                      showSearch
                      loading={isFetchingSelect}
                      filterOption={false}
                      onSearch={e => this.searchFilter(e, 'destination')}
                      style={{ width: '100%' }}
                      placeholder="Select Destination..."
                      options={optionState.destination}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} lg={8}>
                <Form.Item label="Shipping Type">
                  {getFieldDecorator('shipping_type', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(
                    <ReactSelect
                      allowClear
                      showSearch
                      loading={isFetchingSelect}
                      filterOption={false}
                      onSearch={e => this.searchFilter(e, 'shipping_type')}
                      style={{ width: '100%' }}
                      placeholder="Select shipping type..."
                      options={optionState.shipping_type}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Tracking Number">
                  {getFieldDecorator('tNumber', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(<Input.TextArea />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Dispatch Number">
                  {getFieldDecorator('dNumber', {
                    rules: [{ required: false, message: 'Please insert value' }],
                  })(<Input.TextArea />)}
                </Form.Item>
              </Col>
            </Row>
            <br />
            <Button onClick={() => this.submitHandler()} type="primary" block loading={loading} disabled={loading}>
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              </Icon>
              Filter
            </Button>
          </Card>
          <Spacer />
          <Card
            title={(
              <React.Fragment>
                <Button onClick={() => this.submitHandlerExport()} type="danger" loading={loading} disabled={loading}>
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faFileExport} fixedWidth />
                  </Icon>
                  Export
                </Button>
                <span style={{ display: 'inline-block', marginLeft: '6px' }}>
                  <strong>Dispatched: </strong>
                  {
                    `${moment(handoverDate.dateFrom).format('MMM DD, YYYY hh:mm A')} to ${moment(handoverDate.dateTo).format('MMM DD, YYYY hh:mm A')}`
                  }
                </span>
              </React.Fragment>
            )}
            extra={`Total: ${userData.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <ReactTable
              data={userData.rows}
              pages={userData.pages}
              columns={[
                {
                  Header: 'Dispatched Number',
                  accessor: 'dh_id',
                },
                {
                  Header: 'Dispatched Date',
                  accessor: 'dispatched_date',
                },
                {
                  Header: 'Waybill Number',
                  accessor: 'tracking_number',
                },
                {
                  Header: 'Driver Name',
                  accessor: 'driver',
                },
                {
                  Header: 'Consignee Name',
                  accessor: 'consignee_name',
                },
                {
                  Header: 'City',
                  accessor: 'address_city',
                },
                {
                  Header: 'Type',
                  accessor: 'type',
                  filterable: false,
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: row => (
                    <center>
                      <Button
                        type="link"
                        onClick={() => this.getEditAccountHandler(row.original)}
                      >
                        <Icon type="eye" />
                        View
                      </Button>
                    </center>
                  ),
                },
              ]}
              loadingText={!hasFilter ? 'Use the filters to search for Dispatched Item' : 'Fetching dispatched item. . .'}
              loading={loading}
              onFetchData={this.onFetchDataFunc}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reportListTable: state.report.data,
  options: state.options.options,
});

const mapDispatchToProps = {
  dispatchItems: reportList,
  dispatchNoFilterItems: reportNoFilterList,
  waybillComponent: waybill,
  doRubixpickOptions: rubixpickOptions,
  doFetchOptions: fetchOptions,
};

DispatchedItems.propTypes = {
  dispatchNoFilterItems: PropTypes.func.isRequired,
  waybillComponent: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doRubixpickOptions: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptions: PropTypes.func.isRequired,
};

const WrappedDispatchedItems = Form.create({ name: 'Register' })(
  DispatchedItems,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedDispatchedItems);

import {
  remittanceCreate,
  apiFetchList,
  remittanceRemitted,
  unRemittedViews,
  billingUnbilledViews,
} from '../../services/api';

const REMITTANCE_ITEMS_VIEWS = 'courierRemittance/REMITTANCE_ITEMS_VIEWS';
const REMITTANCE_ITEMS_VIEWS_SUCCESS = 'courierRemittance/REMITTANCE_ITEMS_VIEWS_SUCCESS';

export const remittedViewsAccount = tNumber => ({
  type: REMITTANCE_ITEMS_VIEWS,
  payload: {
    client: 'secure',
    request: {
      ...billingUnbilledViews(tNumber),
    },
  },
});

const REMITTANCE_REMITTED_VIEWS = 'courierRemittance/REMITTANCE_REMITTED_VIEWS';
const REMITTANCE_REMITTED_VIEWS_SUCCESS = 'courierRemittance/REMITTANCE_REMITTED_VIEWS_SUCCESS';

export const unRemittedViewsAccount = id => ({
  type: REMITTANCE_REMITTED_VIEWS,
  payload: {
    client: 'secure',
    request: {
      ...unRemittedViews(id),
    },
  },
});

const CREATE_REMITTANCE = 'courierRemittance/CREATE_REMITTANCE';
const CREATE_REMITTANCE_SUCCESS = 'courierRemittance/CREATE_REMITTANCE_SUCCESS';

export const createRemittance = dispatchNumber => ({
  type: CREATE_REMITTANCE,
  payload: {
    client: 'secure',
    request: {
      ...remittanceCreate(dispatchNumber),
    },
  },
});

const REMITTANCE_REMITTED = 'courierRemittance/REMITTANCE_REMITTED';
const REMITTANCE_REMITTED_SUCCESS = 'courierRemittance/REMITTANCE_REMITTED_SUCCESS';

export const remittedRemittance = filter => ({
  type: REMITTANCE_REMITTED,
  payload: {
    client: 'secure',
    request: {
      ...remittanceRemitted,
      data: {
        export: false,
        filter,
        page: 0,
        pageSize: 100000000000,
        sorted: [],
      },
    },
  },
});

const REMITTANCE_REMITTED_LIST = 'courierRemittance/REMITTANCE_REMITTED_LIST';
const REMITTANCE_REMITTED_LIST_SUCCESS = 'courierRemittance/REMITTANCE_REMITTED_LIST_SUCCESS';

export const remittedRemittanceList = data => ({
  type: REMITTANCE_REMITTED_LIST,
  payload: {
    client: 'secure',
    request: {
      ...remittanceRemitted,
      data,
    },
  },
});

const REMITTANCE_LIST = 'courierRemittance/REMITTANCE_LIST';
const REMITTANCE_LIST_SUCCESS = 'courierRemittance/REMITTANCE_LIST_SUCCESS';

export const remittedList = filter => ({
  type: REMITTANCE_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchList('remittance'),
      data: {
        export: false,
        filter,
        page: 0,
        pageSize: 100000000000,
        sorted: [],
      },
    },
  },
});

const REMITTANCE_NONFILTER_LIST = 'courierRemittance/REMITTANCE_NONFILTER_LIST';
const REMITTANCE_NONFILTER_LIST_SUCCESS = 'courierRemittance/REMITTANCE_NONFILTER_LIST_SUCCESS';

export const remittednonFilterList = data => ({
  type: REMITTANCE_NONFILTER_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchList('remittance'),
      data,
    },
  },
});

const initialState = {
  listViews: {},
  createRemittanceData: {},
  data: [],
  dataRItems: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_REMITTANCE_SUCCESS:
      return {
        ...state,
        createRemittanceData: {
          dispatch: action.payload.data.dispatch,
          packages: action.payload.data.packages,
        },
      };

    case REMITTANCE_NONFILTER_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data.rows,
      };

    case REMITTANCE_ITEMS_VIEWS_SUCCESS:
      return {
        ...state,
        listViews: action.payload.data,
      };

    case REMITTANCE_REMITTED_LIST_SUCCESS:
      return {
        ...state,
        dataRItems: action.payload.data.rows,
      };

    case REMITTANCE_REMITTED_VIEWS_SUCCESS:
      return {
        ...state,
        listViews: action.payload.data,
      };

    case REMITTANCE_REMITTED_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };

    case REMITTANCE_LIST_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };

    default:
      return state;
  }
}

export default reducer;

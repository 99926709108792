import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form, Button, Row, Col,
} from 'antd';
import Select from '../../../components/Select';
import Container from '../../../components/Container';
import {
  fetchPcLocationTypeRequest,
  fetchLocationTypeLikeRequestWhereIn,
} from '../ducks';

function PortClusterAdvancedSearch({
  branchCodeList,
  tableFilter,
  onTableUpdate,
  advancedFilter,
  setAdvancedFilter,
  ...props
}) {
  const {
    form,
    doFetchPcLocationTypeRequest,
    doFetchLocationTypeLikeRequestWhereIn,
  } = props;

  const { getFieldDecorator, validateFields } = form;

  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [barangayList, setBarangayList] = useState([]);
  const [routeCodeList, setRouteCodeList] = useState([]);

  useEffect(() => {
    doFetchPcLocationTypeRequest('province').then((res) => {
      setProvinceList(res.payload.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      Object.keys(values).forEach((key) => {
        if (typeof values[key] !== 'undefined') {
          // eslint-disable-next-line no-param-reassign
          advancedFilter = advancedFilter.filter(obj => obj.key !== key);

          advancedFilter.push(
            {
              key,
              id: `advanced_${key}_filter`,
              value: values[key],
            },
          );
        }

        if (values[key] instanceof Array) {
          if (values[key].length === 0) {
            // eslint-disable-next-line no-param-reassign
            advancedFilter = advancedFilter.filter(obj => obj.key !== key);
          }
        }
      });

      setAdvancedFilter(advancedFilter);
      onTableUpdate(tableFilter);
    });
  };

  const setSelectedFilter = (e, type) => {
    if (type === 'province') {
      const params = {
        provinces: e,
      };

      doFetchLocationTypeLikeRequestWhereIn('city', params).then((res) => {
        setCityList(res.payload.data);
      });
    } else if (type === 'city') {
      const params = {
        cities: e,
      };

      doFetchLocationTypeLikeRequestWhereIn('barangay', params).then((res) => {
        setBarangayList(res.payload.data);
      });
    } else if (type === 'barangay') {
      const params = {
        barangays: e,
      };

      doFetchLocationTypeLikeRequestWhereIn('route_code', params).then((res) => {
        setRouteCodeList(res.payload.data);
      });
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col span={8}>
            <Form.Item label="Porvince">
              {getFieldDecorator('province', {})(
                <Select
                  options={provinceList}
                  onChange={e => setSelectedFilter(e, 'province')}
                  style={{ width: '100%' }}
                  mode="multiple"
                  placeholder="Select province(s)..."
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label="City">
              {getFieldDecorator('city', {})(
                <Select
                  options={cityList}
                  onChange={e => setSelectedFilter(e, 'city')}
                  style={{ width: '100%' }}
                  mode="multiple"
                  placeholder="Select city(s)..."
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label="Barangay">
              {getFieldDecorator('barangay', {})(
                <Select
                  options={barangayList}
                  onChange={e => setSelectedFilter(e, 'barangay')}
                  style={{ width: '100%' }}
                  mode="multiple"
                  placeholder="Select barangay(s)..."
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label="Route Code">
              {getFieldDecorator('route_code', {})(
                <Select
                  options={routeCodeList}
                  onChange={e => setSelectedFilter(e, 'route_code')}
                  style={{ width: '100%' }}
                  mode="multiple"
                  placeholder="Select route code(s)..."
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label="Branch">
              {getFieldDecorator('branch', {})(
                <Select
                  options={branchCodeList}
                  style={{ width: '100%' }}
                  mode="multiple"
                  placeholder="Select branch(s)..."
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ textAlign: 'right', marginRight: '10px' }}>
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
            >
                        Apply Filter
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Container>
  );
}


PortClusterAdvancedSearch.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchPcLocationTypeRequest: PropTypes.func.isRequired,
  branchCodeList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  doFetchLocationTypeLikeRequestWhereIn: PropTypes.func.isRequired,
  advancedFilter: PropTypes.oneOfType([PropTypes.array]).isRequired,
  tableFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  onTableUpdate: PropTypes.func.isRequired,
  setAdvancedFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchPcLocationTypeRequest: fetchPcLocationTypeRequest,
  doFetchLocationTypeLikeRequestWhereIn: fetchLocationTypeLikeRequestWhereIn,
};

const WrappedPortClusterAdvancedSearch = Form.create({ name: 'PortClusterAdvancedSearch ' })(PortClusterAdvancedSearch);

export default connect(null, mapDispatchToProps)(WrappedPortClusterAdvancedSearch);

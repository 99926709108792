import React, { Component } from 'react';
import { Select as AntSelect } from 'antd';
import PropTypes from 'prop-types';

export class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.renderOptions = this.renderOptions.bind(this);
  }

  renderOptions() {
    const { options } = this.props;
    const { Option } = AntSelect;
    const selection = options.map(item => (
      <Option key={`${item.label}-${item.value}`} value={item.value}>
        {item.label}
      </Option>
    ));

    return selection;
  }

  render() {
    return (
      <AntSelect allowClear showSearch {...this.props}>
        {this.renderOptions()}
      </AntSelect>
    );
  }
}

Select.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array]),
};

Select.defaultProps = {
  options: [],
};

export default Select;

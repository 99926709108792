import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { format } from 'date-fns';
import {
  Button,
  Spin,
  DatePicker,
  Col,
  Form,
  Icon,
  message,
  Descriptions,
} from 'antd';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import {
  fetchOptionsLike,
  fetchOptions,
  getFleetPerfFM,
  getFleetPerfLM,
} from '../ducks';
import Pageheader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import ReactSelect from '../../components/Select';
import ReactTable from '../../components/ReactTable';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';

class FleetPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Last Mile',
      selectedType: null,
      selectedBranch: null,
      selectedOutsource: null,
      destinationOptions: [],
      outsourceOptions: [],
      selectedDates: {
        from: moment().startOf('day').subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
        to: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      },
      spinnerState: true,
      isOptionsLoading: true,
      fleetPerformance: [],
    };
  }

  componentDidMount() {
    const { doFetchOptions } = this.props;
    this.submitSearch();
    doFetchOptions('destination').then(res => this.setState({ destinationOptions: res.payload.data, isOptionsLoading: false }));
    doFetchOptions('outsource').then(res => this.setState({ outsourceOptions: res.payload.data.slice(0, 50), isOptionsLoading: false }));
  }

  optionHandler(type, e) {
    this.setState({
      [`selected${type}`]: e,
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');

    this.setState({
      selectedDates: {
        from: formatDateOne !== 'Invalid Date' ? formatDateOne : null,
        to: formatDateTwo !== 'Invalid Date' ? formatDateTwo : null,
      },
    });
  }

  searchOutsource(outsource) {
    const { doFetchOptionsLike } = this.props;

    this.setState({ isOptionsLoading: true });

    doFetchOptionsLike('outsource', outsource)
      .then((res) => {
        this.setState({ outsourceOptions: res.payload.data.slice(0, 50), isOptionsLoading: false });
      })
      .catch(() => { message.error('Server error! Please contact IT for help.'); });
  }

  submitSearch() {
    const {
      type,
      selectedType,
      selectedBranch,
      selectedOutsource,
      selectedDates,
    } = this.state;

    const {
      doGetFleetPerfFM,
      doGetFleetPerfLM,
    } = this.props;

    this.setState({ spinnerState: true });

    const params = {
      dispatch_from: selectedDates.from,
      dispatch_to: selectedDates.to,
    };

    if (selectedBranch) {
      params.branch = selectedBranch;
    }

    if (selectedOutsource !== 'undefined' || selectedOutsource !== null) {
      params.outsource_group = selectedOutsource;
    }

    const operationType = selectedType || type;
    const operation = operationType === 'Last Mile' ? doGetFleetPerfLM : doGetFleetPerfFM;

    operation(params)
      .then((res) => {
        this.setState({
          fleetPerformance: res.payload.data,
          spinnerState: false,
          type: operationType,
        });
      })
      .catch(() => {
        message.error('Server error! Please contact IT for help.');
        this.setState({
          spinnerState: false,
          type: operationType,
        });
      });
  }

  render() {
    const {
      outsourceOptions,
      destinationOptions,
      spinnerState,
      isOptionsLoading,
      fleetPerformance,
      type,
    } = this.state;

    const { RangePicker } = DatePicker;

    const breadCrumbs = [
      {
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: 'Fleet Performance',
      },
    ];

    return (
      <div className="fleetDashboard">
        <Pageheader title={`Fleet Dashboard - ${type}`} routes={breadCrumbs} />
        <Spacer />
        <Container>
          <div className="fleetDashboard">
            <Card>
              <Row>
                <Col xs={24} sm={9} lg={9}>
                  <Form.Item label="Dispatch Date">
                    <RangePicker
                      style={{ width: '100%' }}
                      defaultValue={[
                        moment().subtract(7, 'days').startOf('day'),
                        moment().endOf('day'),
                      ]}
                      onChange={e => this.dateHandler(e)}
                      showTime
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={5} lg={5}>
                  <Form.Item label="Outsource Group">
                    <ReactSelect
                      style={{ width: '100%' }}
                      placeholder="Type to search for outsource group..."
                      loading={isOptionsLoading}
                      onSearch={e => this.searchOutsource(e)}
                      options={outsourceOptions}
                      onChange={e => this.optionHandler('Outsource', e)}
                      filterOption={false}
                      showSearch
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={5} lg={5}>
                  <Form.Item label="Branch">
                    <ReactSelect
                      style={{ width: '100%' }}
                      placeholder="Type to search for branch..."
                      loading={isOptionsLoading}
                      options={destinationOptions}
                      onChange={e => this.optionHandler('Branch', e)}
                      filterOption
                      optionFilterProp="children"
                      showSearch
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={5} lg={5}>
                  <Form.Item label="Type">
                    <ReactSelect
                      defaultValue="Last Mile"
                      style={{ width: '100%' }}
                      options={[
                        { label: 'Last Mile', value: 'Last Mile' },
                        { label: 'First Mile', value: 'First Mile' },
                      ]}
                      onChange={e => this.setState({ selectedType: e })}
                      filterOption={false}
                      showSearch
                      isRequired
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Button disabled={spinnerState} type="primary" onClick={() => this.submitSearch()} block>
                <Icon type="file-search" />
                Filter
              </Button>
            </Card>
            <Spacer />
            <Card title="Dashboard Formula">
              <Descriptions size="small" bordered>
                <Descriptions.Item label="Progress">(Total Success + Total Failed) / Total Volume</Descriptions.Item>
                <Descriptions.Item label="Success %">Total Success / (Total Success + Total Failed)</Descriptions.Item>
                <Descriptions.Item label="Failed %">Total Failed / (Total Success + Total Failed)</Descriptions.Item>
              </Descriptions>
            </Card>
            <Spacer />
            <Spin
              spinning={spinnerState}
              size="small"
              tip="Fetching fleet performance report..."
            >
              <Card
                extra={(
                  <CSVLink
                    data={fleetPerformance}
                    target="_blank"
                    filename="fleet_performance.csv"
                    onClick={this.downloadTrend}
                  >
                    <Button type="primary">
                      <Icon type="download" />
                    Export
                    </Button>
                  </CSVLink>
)}
              >
                <ReactTable
                  data={fleetPerformance}
                  columns={[
                    {
                      Header: 'Courier',
                      accessor: 'courier',
                    },
                    {
                      Header: 'Outsource Group',
                      accessor: 'outsource_group',
                    },
                    {
                      Header: 'Total Dispatch',
                      accessor: 'total_dispatch',
                    },
                    {
                      Header: 'Total Volume',
                      accessor: 'total_volume',
                    },
                    {
                      Header: 'Total Pending',
                      accessor: 'total_pending',
                    },
                    {
                      Header: `Total ${type === 'Last Mile' ? 'Delivered' : 'Picked'}`,
                      accessor: type === 'Last Mile' ? 'total_delivered' : 'total_picked',
                    },
                    {
                      Header: 'Total Failed',
                      accessor: 'total_failed',
                    },
                    {
                      Header: 'Progress',
                      Cell: row => (
                        <React.Fragment>
                          { `${parseFloat(row.original.progress).toFixed(2)}%` }
                        </React.Fragment>
                      ),
                    },
                    {
                      Header: 'Success %',
                      Cell: row => (
                        <React.Fragment>
                          { `${parseFloat(row.original.success_pct).toFixed(2)}%` }
                        </React.Fragment>
                      ),
                    },
                    {
                      Header: 'Failed %',
                      Cell: row => (
                        <React.Fragment>
                          { `${parseFloat(row.original.failed_pct).toFixed(2)}%` }
                        </React.Fragment>
                      ),
                    },
                  ]}
                  loadingText="Fetching fleet performance report..."
                  loading={spinnerState}
                  defaultPageSize={20}
                />
              </Card>
            </Spin>
          </div>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = {
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
  doGetFleetPerfFM: getFleetPerfFM,
  doGetFleetPerfLM: getFleetPerfLM,
};

FleetPerformance.propTypes = {
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
  doGetFleetPerfFM: PropTypes.func.isRequired,
  doGetFleetPerfLM: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(FleetPerformance);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
 Form, Input, Col, Button, Icon, message 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faTimes,
  faSave,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';
import ServerSideTable from '../../components/ServerSideTable';
import Select from '../../components/Select';
import {
  fetchVehicleList,
  createVehicle,
  fetchOptions,
  updateVehicle,
} from './ducks';

export class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      options: {
        outsource: [],
        destination: [],
      },
      currentTable: {
        page: null,
        pageSize: null,
        filter: [],
        sorted: [],
      },
      isFetching: false,
      editedVehicle: null,
      isFetchingSelect: false,
    };

    this.onFetchData = this.onFetchData.bind(this);
    this.formCreate = this.formCreate.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.formUpdate = this.formUpdate.bind(this);
  }

  componentDidMount() {
    const { doFetchOptions } = this.props;
    const selectType = ['outsource', 'destination'];

    Object.keys(selectType).forEach((key) => {
      doFetchOptions(selectType[key], '').then((action) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            [selectType[key]]: action.payload.data,
          },
        }));
      });
    });
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { doFetchVehicleList } = this.props;

    const params = {
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };

    this.setState({
      isFetching: true,
      currentTable: params,
    });

    doFetchVehicleList(params).then((action) => {
      this.setState({
        vehicleList: {
          rows: action.payload.data.rows,
          pages: action.payload.data.pages,
          total: action.payload.data.total,
        },
        isFetching: false,
      });
    });
  }

  onEdit(cellInfo) {
    const { vehicleList, editedVehicle, options } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    if (editedVehicle === cellInfo.original.vehicle_id) {
      if (cellInfo.column.id === 'port_code') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [
                {
                  required: true,
                  message: `${cellInfo.column.Header} cannot be blank.`,
                },
              ],
              initialValue: vehicleList.rows[cellInfo.index][cellInfo.column.id],
            })(<Select options={options.destination} autoComplete="off" />)}
          </Form.Item>
        );
      }
      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}_update`, {
            rules: [
              {
                required: true,
                message: `${cellInfo.column.Header} cannot be blank.`,
              },
            ],
            initialValue: vehicleList.rows[cellInfo.index][cellInfo.column.id],
          })(
            <Input
              placeholder={`Enter ${cellInfo.column.Header}`}
              autoComplete="off"
            />,
          )}
        </Form.Item>
      );
    }

    return (
      <React.Fragment>
        {vehicleList.rows[cellInfo.index][cellInfo.column.id]}
      </React.Fragment>
    );
  }

  setToEdit(vehicleId) {
    this.setState({
      editedVehicle: vehicleId,
    });
  }

  searchFilter(value, name) {
    this.setState({
      isFetchingSelect: true,
    });
    const { doFetchOptions } = this.props;
    doFetchOptions(name, value).then(res => this.setState(prevState => ({
        options: {
          ...prevState.options,
          [name]: res.payload.data.slice(0, 50),
        },
        isFetchingSelect: false,
      })),);
  }

  cancelEdit() {
    this.setState({
      editedVehicle: null,
    });
  }

  formUpdate(e) {
    e.preventDefault();
    const { editedVehicle } = this.state;
    const { doUpdateVehicle, form } = this.props;
    const { validateFields } = form;

    validateFields(
      [
        'vehicle_name_update',
        'description_update',
        'type_update',
        'plate_number_update',
        'port_code_update',
      ],
      (err, values) => {
        if (!err) {
          const data = {
            vehicle_name: values.vehicle_name_update,
            description: values.description_update,
            type: values.type_update,
            plate_number: values.plate_number_update,
            port_code: values.port_code_update,
          };
          doUpdateVehicle(editedVehicle, data)
            .then(() => {
              const { currentTable } = this.state;

              const params = {
                page: currentTable.page,
                pageSize: currentTable.pageSize,
                filtered: currentTable.filter,
                sorted: currentTable.sorted,
              };

              this.setState({
                editedVehicle: null,
              });

              message.success('Successfully updated vehicle!');
              this.onFetchData(params);
            })
            .catch((action) => {
              let errorMessage;
              if (action.error.response.status === 400) {
                errorMessage = action.error.response.data.message;
              } else {
                errorMessage = 'Something went wrong, please try again later.';
              }
              message.error(errorMessage, 3);
            });
        }
      },
    );
  }

  formCreate(e) {
    e.preventDefault();
    const { vehicleList } = this.state;
    const { doCreateVehicle, form } = this.props;
    const { validateFields } = form;
    validateFields(
      [
        'vehicle_name',
        'description',
        'type',
        'plate_number',
        'outsource',
        'port_code',
      ],
      (err, values) => {
        if (!err) {
          const vehicleNameDuplicate = vehicleList.rows.some(
            res => res.vehicle_name === values.vehicle_name,
          );
          if (vehicleNameDuplicate) {
            message.error('Vehicle Name already in use.', 3);
          } else {
            doCreateVehicle(values).then(() => {
              const { currentTable } = this.state;

              const params = {
                page: currentTable.page,
                pageSize: currentTable.pageSize,
                filtered: currentTable.filter,
                sorted: currentTable.sorted,
              };

              message.success('Successfully created vehicle!');
              this.onFetchData(params);
              form.resetFields();
            });
          }
          // console.log(vehicleNameDuplicate);
        }
      },
    );
  }

  render() {
    const {
      vehicleList,
      isFetching,
      options,
      editedVehicle,
      isFetchingSelect,
    } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    const breadCrumbs = [
      {
        breadcrumbName: 'Admin',
      },
      {
        breadcrumbName: 'Vehicle',
      },
    ];
    return (
      <div className="Vehicle">
        <PageHeader title="Vehicles" routes={breadCrumbs} />
        <Container>
          <Card title="Vehicle Information">
            <Form onSubmit={this.formCreate}>
              <Row>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Vehicle Name">
                    {getFieldDecorator('vehicle_name', {
                      rules: [
                        { required: true, message: 'Van Code cannot be blank.' },
                      ],
                    })(
                      <Input placeholder="Enter van code" autoComplete="off" />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Description">
                    {getFieldDecorator('description', {
                      rules: [
                        {
                          required: true,
                          message: 'Description cannot be blank',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter description"
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Type">
                    {getFieldDecorator('type', {
                      rules: [
                        { required: true, message: 'Type cannot be blank.' },
                      ],
                    })(<Input placeholder="Enter type" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Plate Number">
                    {getFieldDecorator('plate_number', {
                      rules: [
                        {
                          required: true,
                          message: 'Plate number cannot be blank.',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter plate number"
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Outsource">
                    {getFieldDecorator('outsource', {
                      rules: [
                        { required: true, message: 'Outsource cannot be blank' },
                      ],
                    })(
                      <Select
                        showSearch
                        onSearch={e => this.searchFilter(e, 'outsource')}
                        placeholder="Select outsource"
                        options={options.outsource}
                        loading={isFetchingSelect}
                        filterOption={false}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Assigned Warehouse">
                    {getFieldDecorator('port_code', {
                      rules: [
                        {
                          required: true,
                          message: 'Assigned Warehouse cannot be blank.',
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        loading={isFetchingSelect}
                        filterOption={false}
                        onSearch={e => this.searchFilter(e, 'destination')}
                        placeholder="Select assigned warehouse"
                        options={options.destination}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="primary" htmlType="submit" block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faPlus} fixedWidth />
                    </Icon>
                    Create
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          <Spacer />
          <Card
            title="Vehicle List"
            extra={`Total - ${vehicleList.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <Form onSubmit={this.formUpdate}>
              <ServerSideTable
                data={vehicleList.rows}
                pages={vehicleList.pages}
                columns={[
                  {
                    Header: 'Vehicle ID',
                    accessor: 'vehicle_id',
                    filterable: false,
                  },
                  {
                    Header: 'Vehicle Name',
                    accessor: 'vehicle_name',
                    filterable: false,
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Plate Number',
                    accessor: 'plate_number',
                    filterable: false,
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Type',
                    accessor: 'type',
                    filterable: false,
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Description',
                    accessor: 'description',
                    filterable: false,
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'PortCode',
                    accessor: 'port_code',
                    filterable: false,
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Date Created',
                    accessor: 'created_at',
                    filterable: false,
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    width: editedVehicle !== null ? 210 : 100,
                    Cell: data => (
                      <Row>
                        {editedVehicle !== data.row.vehicle_id ? (
                          <Row>
                            <Col>
                              <Button
                                type="link"
                                onClick={() => this.setToEdit(data.row.vehicle_id)
                                }
                                block
                              >
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faEdit} fixedWidth />
                                </Icon>
                                Edit
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <div>
                            <Col span={12}>
                              <Button type="link" htmlType="submit" block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faSave} fixedWidth />
                                </Icon>
                                Save
                              </Button>
                            </Col>

                            <Col span={12}>
                              <Button
                                type="link"
                                onClick={this.cancelEdit}
                                block
                              >
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faTimes} fixedWidth />
                                </Icon>
                                Cancel
                              </Button>
                            </Col>
                          </div>
                        )}
                      </Row>
                    ),
                  },
                ]}
                loading={isFetching}
                loadingText="Fetching vehicle list. . ."
                onFetchData={this.onFetchData}
              />
            </Form>
          </Card>
        </Container>
      </div>
    );
  }
}

Vehicle.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchVehicleList: PropTypes.func.isRequired,
  doCreateVehicle: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doUpdateVehicle: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchVehicleList: fetchVehicleList,
  doCreateVehicle: createVehicle,
  doFetchOptions: fetchOptions,
  doUpdateVehicle: updateVehicle,
};

const WrappedVehicle = Form.create({ name: 'Vehicle' })(Vehicle);

export default connect(null, mapDispatchToProps)(WrappedVehicle);

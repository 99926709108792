import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import fileDownload from 'js-file-download';
import { Button, Icon } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import Card from './Card';
import Spacer from './Spacer';
import ReactTable from './ReactTable';
import { exportDispatch, exportPickupDispatch } from '../pages/ducks';

export class DispatchView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false,
    };

    this.exportDispatch = this.exportDispatch.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  exportDispatch() {
    const {
      doExportDispatch, exportId, dispatchExportName, exportType, doExportPickupDispatch,
    } = this.props;
    this.setState({ isExporting: true });
    if (exportType === 'pickup_dispatch') {
      doExportPickupDispatch(exportId).then((res) => {
        const blob = new Blob([res.payload.data]);
        fileDownload(blob, `${dispatchExportName}-${exportId}-Dispatch.xlsx`);
        this.setState({ isExporting: false });
      }).catch(() => {
        this.setState({ isExporting: false });
      });
    }
    if (exportType === 'dispatch') {
      doExportDispatch(exportId).then((res) => {
        const blob = new Blob([res.payload.data]);
        fileDownload(blob, `${dispatchExportName}-${exportId}-Dispatch.xlsx`);
        this.setState({ isExporting: false });
      }).catch(() => {
        this.setState({ isExporting: false });
      });
    }
  }

  render() {
    const {
      dispatchDetails,
      dispatchItems,
      dispatchTitle,
      dispatchPackageListColumns,
      printTypes,
      loadingUpdate,
      hasExport,
    } = this.props;
    const { isExporting } = this.state;
    return (
      <div className="DispatchView">
        <Card title={dispatchTitle} extra={printTypes}>
          {dispatchDetails}
        </Card>
        <Spacer />
        <Card
          title={`Package List (${dispatchItems !== undefined ? dispatchItems.length : 0})`}
          extra={hasExport ? (
            <Button
              onClick={this.exportDispatch}
              type="primary"
              block
              disabled={isExporting}
            >
              <Icon viewBox="0 0 1024 1024">
                <FontAwesomeIcon icon={faFileExport} fixedWidth />
              </Icon>
              {isExporting ? 'Exporting . . .' : 'Export'}
            </Button>
          ) : null}
        >
          <ReactTable
            data={dispatchItems}
            columns={dispatchPackageListColumns}
            loading={loadingUpdate}
            loadingText="Updating status. . ."
          />
        </Card>
      </div>
    );
  }
}

DispatchView.defaultProps = {
  dispatchItems: [],
  loadingUpdate: false,
  hasExport: false,
  exportId: null,
  dispatchExportName: null,
};

DispatchView.propTypes = {
  dispatchItems: PropTypes.oneOfType([PropTypes.array]),
  dispatchDetails: PropTypes.oneOfType([PropTypes.array, PropTypes.node])
    .isRequired,
  dispatchPackageListColumns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  dispatchTitle: PropTypes.string.isRequired,
  printTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  dispatchExportName: PropTypes.string,
  exportType: PropTypes.string,
  loadingUpdate: PropTypes.bool,
  doExportDispatch: PropTypes.func.isRequired,
  doExportPickupDispatch: PropTypes.func.isRequired,
  hasExport: PropTypes.bool,
  exportId: PropTypes.number,
};

const mapDispatchToProps = {
  doExportDispatch: exportDispatch,
  doExportPickupDispatch: exportPickupDispatch,
};

export default connect(null, mapDispatchToProps)(DispatchView);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
 Input, Icon, Button, Col, Form, message 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faTimes,
  faSave,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Row from '../../components/Row';
import Spacer from '../../components/Spacer';
import ServerSideTable from '../../components/ServerSideTable';
import { fetchOutsourceList, createOutsource, updateOutsource } from './ducks';

export class Outsource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outsourceList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      currentTable: {
        page: null,
        pageSize: null,
        filter: [],
        sorted: [],
      },
      isFetching: false,
      editedOutsource: null,
    };

    this.onFetchData = this.onFetchData.bind(this);
    this.formCreate = this.formCreate.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.formUpdate = this.formUpdate.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { doFetchOutsourceList } = this.props;

    const params = {
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };

    this.setState({
      isFetching: true,
      currentTable: params,
    });

    doFetchOutsourceList(params).then((action) => {
      this.setState({
        outsourceList: {
          rows: action.payload.data.rows,
          pages: action.payload.data.pages,
          total: action.payload.data.total,
        },
        isFetching: false,
      });
    });
  }

  onEdit(cellInfo) {
    const { outsourceList, editedOutsource } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    if (editedOutsource === cellInfo.original.id) {
      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}_update`, {
            rules: [
              {
                required: true,
                message: `${cellInfo.column.Header} cannot be blank.`,
              },
            ],
            initialValue: outsourceList.rows[cellInfo.index][cellInfo.column.id],
          })(
            <Input
              placeholder={`Enter ${cellInfo.column.Header}`}
              autoComplete="off"
            />,
          )}
        </Form.Item>
      );
    }

    return (
      <React.Fragment>
        {outsourceList.rows[cellInfo.index][cellInfo.column.id]}
      </React.Fragment>
    );
  }

  setToEdit(vehicleId) {
    this.setState({
      editedOutsource: vehicleId,
    });
  }

  cancelEdit() {
    this.setState({
      editedOutsource: null,
    });
  }

  formUpdate(e) {
    e.preventDefault();
    const { editedOutsource } = this.state;
    const { doUpdateOutsource, form } = this.props;
    const { validateFields } = form;

    validateFields(
      [
        'name_update',
        'point_person_update',
        'contact_number_update',
        'email_address_update',
        'remarks_update',
      ],
      (err, values) => {
        if (!err) {
          const data = {
            name: values.name_update,
            point_person: values.point_person_update,
            contact_number: values.contact_number_update,
            email_address: values.email_address_update,
            remarks: values.remarks_update,
          };
          doUpdateOutsource(editedOutsource, data)
            .then(() => {
              const { currentTable } = this.state;

              const params = {
                page: currentTable.page,
                pageSize: currentTable.pageSize,
                filtered: currentTable.filter,
                sorted: currentTable.sorted,
              };

              this.setState({
                editedOutsource: null,
              });

              message.success('Successfully updated outsource!');
              this.onFetchData(params);
            })
            .catch((action) => {
              let errorMessage;
              if (action.error.response.status === 400) {
                errorMessage = action.error.response.data.message;
              } else {
                errorMessage = 'Something went wrong, please try again later.';
              }
              message.error(errorMessage, 3);
            });
        }
      },
    );
  }

  formCreate(e) {
    e.preventDefault();

    const { doCreateOutsource, form } = this.props;
    const { validateFields } = form;

    validateFields(
      ['name', 'point_person', 'contact_number', 'email_address', 'remarks'],
      (err, values) => {
        if (!err) {
          doCreateOutsource(values)
            .then(() => {
              const { currentTable } = this.state;

              const params = {
                page: currentTable.page,
                pageSize: currentTable.pageSize,
                filtered: currentTable.filter,
                sorted: currentTable.sorted,
              };

              message.success('Successfully created outsource!');
              this.onFetchData(params);
              form.resetFields();
            })
            .catch((action) => {
              let errorMessage;
              if (action.error.response.status === 400) {
                errorMessage = action.error.response.data.message;
              } else {
                errorMessage = 'Something went wrong, please try again later.';
              }
              message.error(errorMessage, 3);
            });
        }
      },
    );
  }

  render() {
    const { outsourceList, isFetching, editedOutsource } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Admin',
      },
      {
        breadcrumbName: 'Outsource',
      },
    ];
    return (
      <div className="Outsource">
        <PageHeader title="Outsources" routes={breadCrumbs} />
        <Container>
          <Card title="Outsource Information">
            <Form onSubmit={this.formCreate}>
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Name">
                    {getFieldDecorator('name', {
                      rules: [
                        { required: true, message: 'Name cannot be blank.' },
                      ],
                    })(<Input placeholder="Enter name" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Point Person">
                    {getFieldDecorator('point_person', {
                      rules: [
                        {
                          required: true,
                          message: 'Point person cannot be blank.',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter point person"
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Contact Number">
                    {getFieldDecorator('contact_number', {
                      rules: [
                        {
                          required: true,
                          message: 'Contact number cannot be blank.',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter contact number"
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Email">
                    {getFieldDecorator('email_address', {
                      rules: [
                        { required: true, message: 'Email cannot be blank.' },
                      ],
                    })(<Input placeholder="Enter email" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Remarks">
                {getFieldDecorator('remarks', {
                  rules: [
                    { required: true, message: 'Remarks cannot be blank.' },
                  ],
                })(<Input placeholder="Enter remarks" autoComplete="off" />)}
              </Form.Item>
              <Row>
                <Col xs={24} sm={24} lg={24}>
                  <Button type="primary" htmlType="submit" block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faPlus} fixedWidth />
                    </Icon>
                    Create
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
          <Spacer />
          <Card
            title="Outsource List"
            extra={`Total: ${outsourceList.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <Form onSubmit={this.formUpdate}>
              <ServerSideTable
                data={outsourceList.rows}
                pages={outsourceList.pages}
                columns={[
                  {
                    Header: 'ID',
                    accessor: 'id',
                  },
                  {
                    Header: 'Name',
                    accessor: 'name',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Point Person',
                    accessor: 'point_person',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Contact Number',
                    accessor: 'contact_number',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Email Address',
                    accessor: 'email_address',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Remarks',
                    accessor: 'remarks',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Date Created',
                    accessor: 'created_at',
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    width: editedOutsource !== null ? 210 : 100,
                    Cell: data => (
                      <Row>
                        {editedOutsource !== data.row.id ? (
                          <Row>
                            <Col>
                              <Button
                                type="link"
                                onClick={() => this.setToEdit(data.row.id)}
                                block
                              >
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faEdit} fixedWidth />
                                </Icon>
                                Edit
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <div>
                            <Col span={12}>
                              <Button type="link" htmlType="submit" block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faSave} fixedWidth />
                                </Icon>
                                Save
                              </Button>
                            </Col>

                            <Col span={12}>
                              <Button
                                type="link"
                                onClick={this.cancelEdit}
                                block
                              >
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faTimes} fixedWidth />
                                </Icon>
                                Cancel
                              </Button>
                            </Col>
                          </div>
                        )}
                      </Row>
                    ),
                  },
                ]}
                loadingText="Fetching outsource list . . ."
                loading={isFetching}
                onFetchData={this.onFetchData}
              />
            </Form>
          </Card>
        </Container>
      </div>
    );
  }
}

Outsource.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOutsourceList: PropTypes.func.isRequired,
  doCreateOutsource: PropTypes.func.isRequired,
  doUpdateOutsource: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchOutsourceList: fetchOutsourceList,
  doCreateOutsource: createOutsource,
  doUpdateOutsource: updateOutsource,
};
const WrappedOutsource = Form.create({ name: 'Outsource' })(Outsource);

export default connect(null, mapDispatchToProps)(WrappedOutsource);

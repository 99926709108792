import { deliveryList, remittanceCreateCancel } from '../../services/api';

const TRANSFER_LIST = 'transfer/TRANSFER_LIST';
const TRANSFER_LIST_SUCCESS = 'transfer/TRANSFER_LIST_SUCCESS';

export const viewList = data => ({
  type: TRANSFER_LIST,
  payload: {
    client: 'secure',
    request: {
      ...deliveryList,
      data,
    },
  },
});

const TRANSFER_CANCEL = 'pickup/TRANSFER_PACKAGE_CANCEL';

export const rtsCancel = tNumber => ({
  type: TRANSFER_CANCEL,
  payload: {
    client: 'secure',
    request: {
      ...remittanceCreateCancel(tNumber),
    },
  },
});

const initialState = {
  tableList: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case TRANSFER_LIST_SUCCESS:
      return {
        ...state,
        tableList: action.payload.data.rows,
      };

    default:
      return state;
  }
}

export default reducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Icon, Form,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import ReactTable from '../../components/ReactTable';
import { itemfetchviewsByReference } from './ducks';

export class ItemListViewByReference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      itemsData: [],
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    const { doItemfetchviewsByReference, match, history } = this.props;
    doItemfetchviewsByReference(match.params.reference).then((res) => {
      if (res.payload.data.length === 1) {
        const reference = res.payload.data.map(resArr => resArr.reference);
        const itemID = res.payload.data.map(resArr => resArr.item_id);
        history.push(`/items/${reference}/${itemID}`);
      } else {
        this.setState({
          isLoading: false,
          itemsData: res.payload.data,
        });
      }
    });
  }

  render() {
    const {
      itemsData, isLoading,
    } = this.state;

    const breadCrumbs = [
      {
        breadcrumbName: 'Items',
      },
      {
        breadcrumbName: 'Item View',
      },
      {
        breadcrumbName: 'Reference',
      },
    ];

    const itemListColumns = [
      {
        Header: 'Tracking Number',
        sortable: true,
        filterable: true,
        accessor: 'tracking_number',
      },
      {
        Header: 'Item ID',
        sortable: true,
        filterable: true,
        accessor: 'item_id',
      },
      {
        Header: 'Reference',
        sortable: true,
        filterable: true,
        accessor: 'reference',
      },
      {
        Header: 'Description',
        sortable: true,
        filterable: true,
        accessor: 'description',
      },
      {
        Header: 'Status',
        sortable: true,
        filterable: true,
        accessor: 'status',
      },
      {
        Header: 'Client Name',
        sortable: true,
        filterable: true,
        accessor: 'client_name',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        Cell: row => (
          <center>
            <NavLink to={`/items/${row.original.reference}/${row.original.item_id}`}>
              <Button type="link">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faEye} fixedWidth />
                </Icon>
                view
              </Button>
            </NavLink>
          </center>
        ),
      },
    ];

    return (
      <div className="ItemListViewByReference">
        <PageHeader title="Reference" routes={breadCrumbs} />
        <Container>
          <Card extra={`Total: ${itemsData.length}`}>
            <ReactTable
              data={itemsData}
              columns={itemListColumns}
              loadingText="Fetching item list. . ."
              loading={isLoading}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

ItemListViewByReference.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doItemfetchviewsByReference: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doItemfetchviewsByReference: itemfetchviewsByReference,
};

const WrappedItemListViewByReference = Form.create({ name: 'create' })(ItemListViewByReference);
export default connect(null, mapDispatchToProps)(WrappedItemListViewByReference);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { message } from 'antd';
import moment from 'moment';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import DispatchForm from '../../components/DispatchForm';
import { fetchMerchantPickupTrackingNumber, createMerchantPickup } from './newDucks';

export class MerchantPickupDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preventDuplicate: true,
      trackingNumberRows: [],
      errors: [],
      isSubmitting: false,
      trackingNumberValidation: [],
    };
    this.packageFormHandler = this.packageFormHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  deleteHandler(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber,
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber,
    });

    message.success('Successfully removed tracking number!');
  }

  packageFormHandler(values) {
    const { trackingNumberRows } = this.state;
    const { doFetchMerchantPickupTrackingNumber } = this.props;
    this.setState(prevState => ({
      trackingNumberValidation: [
        ...prevState.trackingNumberValidation,
        { tracking_number: values.tracking_number },
      ],
    }));

    const splitTrackingNumber = values.tracking_number ? values.tracking_number.split('\n') : '';
    const filteredTrackingNumber = splitTrackingNumber.filter(
      (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
    );
    // eslint-disable-next-line no-unused-vars
    filteredTrackingNumber.forEach((item, key) => {
      const { trackingNumberValidation: validation } = this.state;
      const existLocal = validation.filter(tn => tn.tracking_number === item);

      const exists = trackingNumberRows.filter(tn => tn.tracking_number === item);

      if (existLocal.length > 0) {
        // console.log('first console log');
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          preventDuplicate: true,
          errorMessage: `Tracking number ${item} is already entered.`,
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          preventDuplicate: true,
          errorMessage: `Tracking number ${item} is already entered.`,
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else {
        doFetchMerchantPickupTrackingNumber(values.shipper_id, item)
          .then((action) => {
            this.setState(prevState => ({
              trackingNumberValidation: [],
              preventDuplicate: true,
              errorMessage: ' ',
              trackingNumberRows: [
                ...prevState.trackingNumberRows,
                {
                  address_city: action.payload.data.address_city,
                  merchant: action.payload.data.shipper.shipper_name,
                  tracking_number: item,
                  consignee_address: action.payload.data.consignee_address,
                  payment_type: action.payload.data.payment_type,
                },
              ],
            }));
            message.success(`Successfully added ${item}`);
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 400) {
              errorMessage = action.error.response.data.message;
              this.setState(prevState => ({
                trackingNumberValidation: [],
                preventDuplicate: true,
                errorMessage: action.error.response.data.message,
                errors: [
                  ...prevState.errors,
                  {
                    tracking_number: item,
                    message: action.error.response.data.message,
                  },
                ],
              }));
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  formSubmitHandler(values) {
    const { trackingNumberRows } = this.state;
    const { doCreateMerchantPickup, history } = this.props;
    if (trackingNumberRows.length > 0) {
      const packages = [];

      this.setState({
        isSubmitting: true,
      });
      // eslint-disable-next-line no-unused-vars
      trackingNumberRows.forEach((item, key) => {
        packages.push(item.tracking_number);
      });

      const params = {
        ...values,
        expected_arrival: moment(values.expected_arrival).format('YYYY-MM-DD HH:mm:ss'),
        type: 'merchant',
        packages,
      };

      doCreateMerchantPickup(params)
        .then((action) => {
          this.setState({
            isSubmitting: false,
          });
          history.push(`/merchant-pickup/${action.payload.data.ph_id}`);
        })
        .catch(() => {
          this.setState({
            isSubmitting: false,
          });
          message.error('Something went wrong, please try again later.');
        });
    } else {
      message.error('No tracking number found!');
    }
  }

  render() {
    const {
      trackingNumberRows, errors, isSubmitting, errorMessage, preventDuplicate,
    } = this.state;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Merchant Pickup',
      },
      {
        breadcrumbName: 'Dispatch',
      },
    ];

    const listColumns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Merchant',
        accessor: 'merchant',
      },
      {
        Header: 'City',
        accessor: 'address_city',
      },
      {
        Header: 'Address',
        accessor: 'consignee_address',
      },
      {
        Header: 'Payment Type',
        accessor: 'payment_type',
      },
    ];

    const selectOptions = ['courier', 'merchant', 'vehicle'];

    const requiredFormSubmitFields = [
      'driver_id',
      'driver2_id',
      'helper_id',
      'helper2_id',
      'vehicle_id',
      'remarks',
      'expected_arrival',
      'shipper_id',
      'driver_rate',
      'driver2_rate',
      'helper_rate',
      'helper2_rate',
    ];

    const requiredPackageFormFields = ['tracking_number', 'shipper_id'];

    return (
      <div className="MerchantPickupDispatch">
        <PageHeader title="Merchant Pickup" routes={breadCrumbs} />
        <Container>
          <DispatchForm
            doPreventDuplicate={preventDuplicate}
            formInformation="Merchant Pickup Information"
            tablInformation="Merchant Pickup Dispatch List"
            selectOptions={selectOptions}
            requiredFormSubmitFields={requiredFormSubmitFields}
            requiredPackageFormFields={requiredPackageFormFields}
            listColumns={listColumns}
            packageFormHandler={this.packageFormHandler}
            formSubmitHandler={this.formSubmitHandler}
            deleteHandler={this.deleteHandler}
            dispatchItems={trackingNumberRows}
            errorItems={errors}
            isSubmitting={isSubmitting}
            errorMessageDispatch={errorMessage}
            hasNoChargableWeight
            hasExpectedArrival
            hasPackageMerchant
          />
        </Container>
      </div>
    );
  }
}

MerchantPickupDispatch.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchMerchantPickupTrackingNumber: PropTypes.func.isRequired,
  doCreateMerchantPickup: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchMerchantPickupTrackingNumber: fetchMerchantPickupTrackingNumber,
  doCreateMerchantPickup: createMerchantPickup,
};

export default connect(
  null,
  mapDispatchToProps,
)(MerchantPickupDispatch);

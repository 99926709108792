import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
 DatePicker, Form, Button, Icon 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import {
  remittedList,
  remittednonFilterList,
  unRemittedViewsAccount,
} from './ducks';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';

export class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handoverDate: [],
      loading: true,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      payloadReq: {
        page: 0,
        pageSize: 0,
        filter: [],
        sorted: [],
      },
      tableFilter: [],
      searchFilter: [],
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
  }

  onFetchDataFunc(state, instance) {
    this.setState({
      loading: true,
    });
    // console.log(state);
    const { searchFilter } = this.state;
    const { nonFilterList } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };

    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    nonFilterList(paramsReq).then(res => this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        payloadReq: paramsReq,
        tableFilter: state.filtered,
      }),);
  }

  getEditAccountHandler(e) {
    // const { urViews, history } = this.props;
    const { history } = this.props;
    // urViews(e.id).then(() => history.push(`/remittance/${e.id}`));
    // console.log(e.id);
    history.push(`/courier-remittance/${e.id}`);
  }

  submitHandler() {
    this.setState({
      loading: true,
    });
    const { payloadReq, handoverDate, tableFilter } = this.state;
    const { nonFilterList } = this.props;
    const paramsReq = {
      export: false,
      page: payloadReq.page,
      pageSize: payloadReq.pageSize,
      filter: [...handoverDate],
      sorted: payloadReq.sorted,
    };
    if (tableFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(tableFilter);
    }
    nonFilterList(paramsReq).then(res => this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        payloadReq: paramsReq,
        searchFilter: [...handoverDate],
      }),);
  }

  submitHandlerExport() {
    this.setState({
      loading: true,
    });
    const { payloadReq, handoverDate } = this.state;
    const { nonFilterList } = this.props;
    const paramsReq = {
      export: true,
      page: payloadReq.page,
      pageSize: payloadReq.pageSize,
      filter: [...handoverDate],
      sorted: payloadReq.sorted,
    };
    nonFilterList(paramsReq).then((res) => {
      const newBlob = new Blob([res.payload.data]);
      fileDownload(newBlob, 'Remittance-list.csv');
      this.setState({
        loading: false,
      });
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm');
    this.setState({
      handoverDate: [
        {
          id: 'dateFrom',
          value: formatDateOne !== 'Invalid Date' ? formatDateOne : '',
        },
        {
          id: 'dateTo',
          value: formatDateTwo !== 'Invalid Date' ? formatDateTwo : '',
        },
      ],
    });
  }

  render() {
    const { userData, loading } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Courier Remittance',
      },
      {
        breadcrumbName: 'List',
      },
    ];
    return (
      <div className="List">
        <PageHeader title="REMITTANCE LIST" routes={breadCrumbs} />
        <Container>
          <Form>
            <Card>
              <Form.Item label="Dispatch Date">
                <DatePicker.RangePicker
                  style={{ width: '100%' }}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment('00:00', 'HH:mm'),
                      moment('00:00', 'HH:mm'),
                    ],
                  }}
                  onChange={e => this.dateHandler(e)}
                />
              </Form.Item>
              <Button onClick={() => this.submitHandler()} type="primary" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Icon>
                Filter
              </Button>
            </Card>
            <Spacer />
            <Card
              title={(
<Button
                  onClick={() => this.submitHandlerExport()}
                  type="danger"
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faFileExport} fixedWidth />
                  </Icon>
                  Export
                </Button>
)}
              extra={`Total: ${userData.total
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            >
              <ReactTable
                data={userData.rows}
                pages={userData.pages}
                columns={[
                  {
                    Header: 'Date',
                    accessor: 'created_date',
                  },
                  {
                    Header: 'Remittance ID',
                    accessor: 'id',
                  },
                  {
                    Header: 'Driver',
                    accessor: 'driver',
                  },
                  {
                    Header: 'Dispatch ID',
                    accessor: 'dispatch_id',
                  },
                  {
                    Header: 'Total',
                    accessor: 'total_amount',
                  },
                  {
                    Header: 'Remitted Amount',
                    accessor: 'remitted_amount',
                  },
                  {
                    Header: 'Processed By',
                    accessor: 'processed_by',
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                      <center>
                        <Button
                          type="link"
                          onClick={() => this.getEditAccountHandler(row.original)
                          }
                        >
                          <Icon type="eye" />
                          View
                        </Button>
                      </center>
                    ),
                  },
                ]}
                loadingText="Fetching courier remittance list. . ."
                loading={loading}
                onFetchData={this.onFetchDataFunc}
              />
            </Card>
          </Form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  courierList: state.courierRemittance.data,
});

const mapDispatchToProps = {
  requestRemittedList: remittedList,
  nonFilterList: remittednonFilterList,
  urViews: unRemittedViewsAccount,
};

List.propTypes = {
  nonFilterList: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);

import {
  all, put, call, takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { LOGIN_SUCCESS, LOGOUT } from './ducks';

import { store, remove } from '../../services/localStorage';

function* success(action) {
  const { token, refresh_token } = action.payload.data;
  yield call(store, 'token', token);
  yield call(store, 'refresh_token', refresh_token);
  yield put(push('/'));
}

function* logout() {
  yield call(remove, 'token');
  yield put(push('/login'));
}

export default function* () {
  yield all([takeLatest(LOGIN_SUCCESS, success)]);
  yield all([takeLatest(LOGOUT, logout)]);
}

import {
  cashierRemittanceImport,
  billingUnbilledViews,
  massUpload,
  template,
  uploadConfirm,
} from '../../services/api';

import { cashierRemittanceList, cashierRemittanceListFinalize } from '../../services/apiEndpoints';

const UPLOAD_CASHIER_LIST = 'cashierRemmittance/UPLOAD_CASHIER_LIST';
export const cashierRemittanceFinalize = (page, data) => ({
  type: UPLOAD_CASHIER_LIST,
  payload: {
    client: 'secure',
    request: {
      ...cashierRemittanceListFinalize(page),
      data,
    },
  },
});

const UPLOAD_CASHIER = 'cashierRemmittance/UPLOAD_CASHIER';
export const uploadCashierRemittance = (page, data) => ({
  type: UPLOAD_CASHIER,
  payload: {
    client: 'secure',
    request: {
      ...uploadConfirm(page),
      data,
    },
  },
});

const CASHIER_TEMPLATE = 'cashierRemmittance/CASHIER_TEMPLATE';

export const getTemplate = tempName => ({
  type: CASHIER_TEMPLATE,
  payload: {
    client: 'secure',
    request: {
      ...template(tempName),
    },
  },
});

const CASHIER_IMPORT = 'cashierRemmittance/CASHIER_IMPORT';

export const uploadCashier = (page, file) => ({
  type: CASHIER_IMPORT,
  payload: {
    client: 'secure',
    request: {
      ...massUpload(page),
      data: file,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    },
  },
});

const UNREMITTED_LIST_VIEWS = 'cashierRemmittance/UNREMITTED_LIST_VIEWS';
const UNREMITTED_LIST_VIEWS_SUCCESS = 'cashierRemmittance/UNREMITTED_LIST_VIEWS_SUCCESS';

export const crListUnremitted = tNumber => ({
  type: UNREMITTED_LIST_VIEWS,
  payload: {
    client: 'secure',
    request: {
      ...billingUnbilledViews(tNumber),
    },
  },
});

const UNREMITTED_LIST = 'cashierRemmittance/UNREMITTED_LIST';
const UNREMITTED_LIST_SUCCESS = 'cashierRemmittance/UNREMITTED_LIST_SUCCESS';

/**
 *  unremitted-list --- UnremittedList.js
 *  remittance-list --- List.js
 *  report-list --- RemittanceReport.js
 */

export const crList = (filter, page) => ({
  type: UNREMITTED_LIST,
  payload: {
    client: 'secure',
    request: {
      ...cashierRemittanceList(page),
      data: {
        export: false,
        filter,
        page: 0,
        pageSize: 100000000000,
        sorted: [],
      },
    },
  },
});

const UNREMITTED_UNREMITTEDLIST_NONFILTER = 'cashierRemmittance/UNREMITTED_UNREMITTEDLIST_NONFILTER';
const UNREMITTED_UNREMITTEDLIST_NONFILTER_SUCCESS = 'cashierRemmittance/UNREMITTED_UNREMITTEDLIST_NONFILTER_SUCCESS';

const UNREMITTED_REPORTLIST_NONFILTER = 'cashierRemmittance/UNREMITTED_REPORTLIST_NONFILTER';
const UNREMITTED_REPORTLIST_NONFILTER_SUCCESS = 'cashierRemmittance/UNREMITTED_REPORTLIST_NONFILTER_SUCCESS';

const UNREMITTED_REMITTANCELIST_NONFILTER = 'cashierRemmittance/UNREMITTED_REMITTANCELIST_NONFILTER';
const UNREMITTED_REMITTANCELIST_NONFILTER_SUCCESS = 'cashierRemmittance/UNREMITTED_REMITTANCELIST_NONFILTER_SUCCESS';

export const crListnonFilter = (page, data) => {
  switch (page) {
    case 'unremitted-list':
      return {
        type: UNREMITTED_UNREMITTEDLIST_NONFILTER,
        payload: {
          client: 'secure',
          request: {
            ...cashierRemittanceList('unremitted-list'),
            data,
          },
        },
      };

    case 'report-list':
      return {
        type: UNREMITTED_REPORTLIST_NONFILTER,
        payload: {
          client: 'secure',
          request: {
            ...cashierRemittanceList('report-list'),
            data,
          },
        },
      };

    case 'remittance-list':
      return {
        type: UNREMITTED_REMITTANCELIST_NONFILTER,
        payload: {
          client: 'secure',
          request: {
            ...cashierRemittanceList('remittance-list'),
            data,
          },
        },
      };

    default:
      return false;
  }
};

const REMITTANCE_IMPORT = 'cashierRemmittance/REMITTANCE_IMPORT';
const REMITTANCE_IMPORT_SUCCESS = 'cashierRemmittance/REMITTANCE_IMPORT_SUCCESS';

export const crImport = file => ({
  type: REMITTANCE_IMPORT,
  payload: {
    client: 'secure',
    request: {
      ...cashierRemittanceImport,
      data: {
        file,
      },
    },
  },
});

const initialState = {
  listReturn: {},
  import: {},
  dataUnremitted: [],
  dataReport: [],
  dataRemittance: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case UNREMITTED_LIST_SUCCESS:
      return {
        ...state,
        listReturn: {
          rows: action.payload.data.rows,
          pages: action.payload.data.pages,
          total: action.payload.data.total,
        },
      };

    case UNREMITTED_LIST_VIEWS_SUCCESS:
      return {
        ...state,
        listReturn: action.payload.data,
      };

    case UNREMITTED_UNREMITTEDLIST_NONFILTER_SUCCESS:
      return {
        ...state,
        dataUnremitted: action.payload.data.rows,
      };

    case UNREMITTED_REPORTLIST_NONFILTER_SUCCESS:
      return {
        ...state,
        dataReport: action.payload.data.rows,
      };

    case UNREMITTED_REMITTANCELIST_NONFILTER_SUCCESS:
      return {
        ...state,
        dataRemittance: action.payload.data.rows,
      };

    case REMITTANCE_IMPORT_SUCCESS:
      return {
        ...state,
        import: action.payload.data,
      };

    default:
      return state;
  }
}

export default reducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Popconfirm, Typography, Spin, Alert } from 'antd';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';

import { fetchOptions, fetchOptionsLike } from '../ducks';
import { accountList } from '../monitoring/ducks';
import Pageheader from '../../components/PageHeader';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Select from '../../components/Select';
import Spacer from '../../components/Spacer';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientOptionSelectLabel: 'Lazada',
      clientOptionSelect: 1,
      isFetching: false,
      client: [],
      optionTable: {
        pickup: [],
        tts: [],
        shipped: [],
        rts: [],
      },
      pickupType: 'total',
      ttsType: 'total',
      shippedType: 'total',
      rtsType: 'total',
      optionSet: false,

      getCountPickup: false,
      getCountAcceptedByCourier: false,
      getCountAcceptedToWarehouse: false,
      getCountPicked: false,
      getCountReleased: false,
      getCountAcceptedToBranch: false,
      getCountAcceptedToWarehouseShipped: false,
      getCountFirstAttemptFailed: false,
      getCountFirstDeliveryAttempt: false,
      getCountForwardedToBranch: false,
      getCountRedeliveryAttempt: false,
      getCountRedeliveryAttemptFailed: false,
      getCountBranchReceivedRts: false,
      getCountFailedDeliveryReturn: false,
      getCountForwardedToMain: false,
      getCountRainReceivedRts: false,
      getCountOutForReturn: false,

      isMountingModulePickup: false,
      isMountingModuleTts: false,
      isMountingModuleShipped: false,
      isMountingModuleRts: false,
    };
  }

  componentDidMount() {
    const { pickupType, ttsType, shippedType, rtsType, clientOptionSelect } = this.state;
    this.setState({
      isMountingModulePickup: true,
      isMountingModuleTts: true,
      isMountingModuleShipped: true,
      isMountingModuleRts: true,
    });
    const params = [
      {
        client: clientOptionSelect,
        type: pickupType,
      },
      {
        client: clientOptionSelect,
        type: ttsType,
      },
      {
        client: clientOptionSelect,
        type: shippedType,
      },
      {
        client: clientOptionSelect,
        type: rtsType,
      },
    ];
    const spinnerParams = ['isMountingModulePickup', 'isMountingModuleTts', 'isMountingModuleShipped', 'isMountingModuleRts'];
    const { doAccountList, doFetchOptions } = this.props;
    const monitorPage = ['pickup', 'tts', 'shipped', 'rts'];
    monitorPage.forEach((item, index) => {
      doAccountList(item, params[index]).then(res => this.setState(prevState => ({
        optionTable: {
          ...prevState.optionTable,
          [item]: res.payload.data,
        },
        optionSet: true,

        getCountPickup: true,
        getCountAcceptedByCourier: true,
        getCountAcceptedToWarehouse: true,
        getCountPicked: true,
        getCountReleased: true,
        getCountAcceptedToBranch: true,
        getCountAcceptedToWarehouseShipped: true,
        getCountFirstAttemptFailed: true,
        getCountFirstDeliveryAttempt: true,
        getCountForwardedToBranch: true,
        getCountRedeliveryAttempt: true,
        getCountRedeliveryAttemptFailed: true,
        getCountBranchReceivedRts: true,
        getCountFailedDeliveryReturn: true,
        getCountForwardedToMain: true,
        getCountRainReceivedRts: true,
        getCountOutForReturn: true,

        [spinnerParams[index]]: false,
      })));
    });

    doFetchOptions('client').then((action) => {
      const getClientValue = action.payload.data.find(res => res.value === clientOptionSelect);
      this.setState({
        client: action.payload.data.slice(0, 50),
        clientOptionSelectLabel: getClientValue.label,
      });
    });
  }

  getMonitoringExport(ageing, status, type, exportName, name) {
    const { clientOptionSelect: client } = this.state;
    const { doAccountList } = this.props;
    const params = {
      ageing,
      status,
      type,
      client,
    };
    doAccountList(exportName, params).then((res) => {
      const newBlob = new Blob([res.payload.data]);
      fileDownload(newBlob, `${name}Export.csv`);
    });
  }

  selectClientHandler(client) {
    const { pickupType, ttsType, shippedType, rtsType } = this.state;
    const { doAccountList, doFetchOptions } = this.props;

    this.setState({
      isMountingModulePickup: true,
      isMountingModuleTts: true,
      isMountingModuleShipped: true,
      isMountingModuleRts: true,
    });
    const monitorPage = ['pickup', 'tts', 'shipped', 'rts'];
    const params = [
      {
        client,
        type: pickupType,
      },
      {
        client,
        type: ttsType,
      },
      {
        client,
        type: shippedType,
      },
      {
        client,
        type: rtsType,
      },
    ];
    const spinnerParams = ['isMountingModulePickup', 'isMountingModuleTts', 'isMountingModuleShipped', 'isMountingModuleRts'];
    monitorPage.forEach((item, index) => {
      doAccountList(item, params[index]).then(res => this.setState(prevState => ({
        optionTable: {
          ...prevState.optionTable,
          [item]: res.payload.data,
        },
        optionSet: true,

        getCountPickup: true,
        getCountAcceptedByCourier: true,
        getCountAcceptedToWarehouse: true,
        getCountPicked: true,
        getCountReleased: true,
        getCountAcceptedToBranch: true,
        getCountAcceptedToWarehouseShipped: true,
        getCountFirstAttemptFailed: true,
        getCountFirstDeliveryAttempt: true,
        getCountForwardedToBranch: true,
        getCountRedeliveryAttempt: true,
        getCountRedeliveryAttemptFailed: true,
        getCountBranchReceivedRts: true,
        getCountFailedDeliveryReturn: true,
        getCountForwardedToMain: true,
        getCountRainReceivedRts: true,
        getCountOutForReturn: true,

        [spinnerParams[index]]: false,

        clientOptionSelect: client,
      })));
    });

    doFetchOptions('client').then((action) => {
      const clientValue = action.payload.data.find(res => res.value === client);
      this.setState({
        clientOptionSelectLabel: clientValue.label,
      });
    });
  }

  searchOption(e) {
    const { doFetchOptionsLike } = this.props;

    this.setState({
      isFetching: true,
    });

    doFetchOptionsLike('client', e).then((action) => {
      this.setState({
        client: action.payload.data.slice(0, 50),
        isFetching: false,
        clientOptionSelect: e,
      });
    });
  }

  typeHandler(e, type, accountType, spinner) {
    const { clientOptionSelect: client } = this.state;
    this.setState(prevState => ({
      ...prevState,
      [spinner]: true,
    }));

    const { doAccountList } = this.props;
    this.setState(prevState => ({
      ...prevState,
      [type]: e,
    }));
    const params = {
      client,
      type: e,
    };
    doAccountList(accountType, params).then((res) => {
      this.setState(prevState => ({
        optionTable: {
          ...prevState.optionTable,
          [accountType]: res.payload.data,
        },
        [spinner]: false,
      }));
    });
  }

  render() {
    const {
      isMountingModulePickup,
      isMountingModuleTts,
      isMountingModuleShipped,
      isMountingModuleRts,
      optionTable,
      pickupType,
      ttsType,
      shippedType,
      rtsType,
      optionSet,
      getCountPickup,
      getCountAcceptedByCourier,
      getCountAcceptedToWarehouse,
      getCountPicked,
      getCountReleased,
      getCountAcceptedToBranch,
      getCountAcceptedToWarehouseShipped,
      getCountFirstAttemptFailed,
      getCountFirstDeliveryAttempt,
      getCountForwardedToBranch,
      getCountRedeliveryAttempt,
      getCountRedeliveryAttemptFailed,
      getCountBranchReceivedRts,
      getCountFailedDeliveryReturn,
      getCountForwardedToMain,
      getCountRainReceivedRts,
      getCountOutForReturn,
      client,
      isFetching,
      clientOptionSelectLabel,
    } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: 'Backlogs',
      },
    ];

    const optionAgeingReference = [
      { label: 'Total', value: 'total' },
      { label: 'Milestone', value: 'milestone' },
      { label: 'Status', value: 'status' },
    ];

    const { Paragraph } = Typography;

    const forPickup = optionSet ? optionTable.pickup.filter(res => res.status === 'for_pickup') : null;
    const acceptedByCourier = optionSet ? optionTable.tts.filter(res => res.status === 'accepted_by_courier') : null;
    const acceptedToWarehouse = optionSet ? optionTable.tts.filter(res => res.status === 'accepted_to_warehouse') : null;
    const pickedTts = optionSet ? optionTable.tts.filter(res => res.status === 'picked') : null;
    const released = optionSet ? optionTable.tts.filter(res => res.status === 'released') : null;
    const acceptedToBranch = optionSet ? optionTable.shipped.filter(res => res.status === 'accepted_to_branch') : null;
    const acceptedToWarehouseShipped = optionSet ? optionTable.shipped.filter(res => res.status === 'accepted_to_warehouse') : null;
    const firstAttemptFailed = optionSet ? optionTable.shipped.filter(res => res.status === 'first_attempt_failed') : null;
    const firstDeliveryAttempt = optionSet ? optionTable.shipped.filter(res => res.status === 'first_delivery_attempt') : null;
    const forwardedToBranch = optionSet ? optionTable.shipped.filter(res => res.status === 'forwarded_to_branch') : null;
    const redeliveryAttempt = optionSet ? optionTable.shipped.filter(res => res.status === 'redelivery_attempt') : null;
    const redeliveryAttemptFailed = optionSet ? optionTable.shipped.filter(res => res.status === 'redelivery_attempt_failed') : null;
    const branchReceivedRts = optionSet ? optionTable.rts.filter(res => res.status === 'branch_received_rts') : null;
    const failedDeliveryReturn = optionSet ? optionTable.rts.filter(res => res.status === 'failed_delivery_return') : null;
    const forwardedToMain = optionSet ? optionTable.rts.filter(res => res.status === 'forwarded_to_main') : null;
    const mainReceivedRts = optionSet ? optionTable.rts.filter(res => res.status === 'main_received_rts') : null;
    const outForReturn = optionSet ? optionTable.rts.filter(res => res.status === 'out_for_return') : null;

    return (
      <div className="Dashboard">
        <Pageheader
          title={`Dashboard - (${clientOptionSelectLabel})`}
          routes={breadCrumbs}
          extra={
            (
              <React.Fragment>
                <Paragraph strong style={{ fontSize: 12 }}>Select Client :</Paragraph>
                <Select
                  defaultValue="Lazada"
                  onChange={e => this.selectClientHandler(e)}
                  style={{ width: 280 }}
                  options={client}
                  allowClear
                  showSearch
                  filterOption={false}
                  loading={isFetching}
                  onSearch={e => this.searchOption(e)}
                />
              </React.Fragment>
            )
          }
        />
        <Container>
          <Spacer />
          <Card
            title="Pickup"
          >
            <div className="dashboardContainer">
              <Spin spinning={isMountingModulePickup} size="small" tip="Fetching pickup details. . .">
                <table className="tableStyle">
                  <thead>
                    <tr>
                      <th>Page</th>
                      <th>Today</th>
                      <th>1d</th>
                      <th>2d</th>
                      <th>3d</th>
                      <th>4d</th>
                      <th>5d</th>
                      <th>6d</th>
                      <th>7d</th>
                      <th>8-14d</th>
                      <th>15-30d</th>
                      <th>31+d</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key="1">
                    <td>for_pickup</td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('Today', 'for_pickup', pickupType, 'pickup/export', 'pickup')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPickup ? (forPickup.some(res => res.ageing === 'Today') ? forPickup.find(res => res.ageing === 'Today').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('1', 'for_pickup', pickupType, 'pickup/export', 'pickup')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPickup ? (forPickup.some(res => res.ageing === '1') ? forPickup.find(res => res.ageing === '1').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('2', 'for_pickup', pickupType, 'pickup/export', 'pickup')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPickup ? (forPickup.some(res => res.ageing === '2') ? forPickup.find(res => res.ageing === '2').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('3', 'for_pickup', pickupType, 'pickup/export', 'pickup')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPickup ? (forPickup.some(res => res.ageing === '3') ? forPickup.find(res => res.ageing === '3').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('4', 'for_pickup', pickupType, 'pickup/export', 'pickup')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPickup ? (forPickup.some(res => res.ageing === '4') ? forPickup.find(res => res.ageing === '4').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('5', 'for_pickup', pickupType, 'pickup/export', 'pickup')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPickup ? (forPickup.some(res => res.ageing === '5') ? forPickup.find(res => res.ageing === '5').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('6', 'for_pickup', pickupType, 'pickup/export', 'pickup')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPickup ? (forPickup.some(res => res.ageing === '6') ? forPickup.find(res => res.ageing === '6').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('7', 'for_pickup', pickupType, 'pickup/export', 'pickup')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPickup ? (forPickup.some(res => res.ageing === '7') ? forPickup.find(res => res.ageing === '7').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('8-14', 'for_pickup', pickupType, 'pickup/export', 'pickup')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPickup ? (forPickup.some(res => res.ageing === '8-14') ? forPickup.find(res => res.ageing === '8-14').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('15-30', 'for_pickup', pickupType, 'pickup/export', 'pickup')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPickup ? (forPickup.some(res => res.ageing === '15-30') ? forPickup.find(res => res.ageing === '15-30').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('31', 'for_pickup', pickupType, 'pickup/export', 'pickup')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPickup ? (forPickup.some(res => res.ageing === '31++') ? forPickup.find(res => res.ageing === '31++').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </Spin>
            </div>
          </Card>
          <Spacer />
          <Card
            title="Time To Ship"
            extra={
              (
                <React.Fragment>
                  <Paragraph strong>Select Ageing Reference :</Paragraph>
                  <Select
                    onChange={e => this.typeHandler(e, 'ttsType', 'tts', 'isMountingModuleTts')}
                    style={{ width: 180 }}
                    defaultValue="Total"
                    options={optionAgeingReference}
                  />
                </React.Fragment>
              )
            }
          >
            {(() => {
              switch (ttsType) {
                case 'status':
                  return (
                    <Alert
                      message="Ageing Reference - Status"
                      description="Difference between today and the package's last status update."
                      type="info"
                      showIcon
                    />
                  );
                case 'total':
                  return (
                    <Alert
                      message="Ageing Reference - Total"
                      description="Difference between today and when the package was first accepted by courier or picked, whichever is older."
                      type="info"
                      showIcon
                    />
                  );
                case 'milestone':
                  return (
                    <Alert
                      message="Ageing Reference - Milestone"
                      description="Difference between today and when the package was first accepted by courier or picked, whichever is older."
                      type="info"
                      showIcon
                    />
                  );
                default:
                  return false;
              }
            })()}
            <Spacer />
            <div className="dashboardContainer">
              <Spin spinning={isMountingModuleTts} size="small" tip="Fetching time to shipped details. . .">
                <table className="tableStyle">
                  <thead>
                    <tr>
                      <th>Page</th>
                      <th>Today</th>
                      <th>1d</th>
                      <th>2d</th>
                      <th>3d</th>
                      <th>4d</th>
                      <th>5d</th>
                      <th>6d</th>
                      <th>7d</th>
                      <th>8-14d</th>
                      <th>15-30d</th>
                      <th>31+d</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key="1">
                    <td>accepted_by_courier</td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('Today', 'accepted_by_courier', pickupType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedByCourier ? (acceptedByCourier.some(res => res.ageing === 'Today') ? acceptedByCourier.find(res => res.ageing === 'Today').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('1', 'accepted_by_courier', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedByCourier ? (acceptedByCourier.some(res => res.ageing === '1') ? acceptedByCourier.find(res => res.ageing === '1').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('2', 'accepted_by_courier', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedByCourier ? (acceptedByCourier.some(res => res.ageing === '2') ? acceptedByCourier.find(res => res.ageing === '2').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('3', 'accepted_by_courier', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedByCourier ? (acceptedByCourier.some(res => res.ageing === '3') ? acceptedByCourier.find(res => res.ageing === '3').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('4', 'accepted_by_courier', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedByCourier ? (acceptedByCourier.some(res => res.ageing === '4') ? acceptedByCourier.find(res => res.ageing === '4').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('5', 'accepted_by_courier', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedByCourier ? (acceptedByCourier.some(res => res.ageing === '5') ? acceptedByCourier.find(res => res.ageing === '5').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('6', 'accepted_by_courier', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedByCourier ? (acceptedByCourier.some(res => res.ageing === '6') ? acceptedByCourier.find(res => res.ageing === '6').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('7', 'accepted_by_courier', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedByCourier ? (acceptedByCourier.some(res => res.ageing === '7') ? acceptedByCourier.find(res => res.ageing === '7').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('8-14', 'accepted_by_courier', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedByCourier ? (acceptedByCourier.some(res => res.ageing === '8-14') ? acceptedByCourier.find(res => res.ageing === '8-14').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('15-30', 'accepted_by_courier', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedByCourier ? (acceptedByCourier.some(res => res.ageing === '15-30') ? acceptedByCourier.find(res => res.ageing === '15-30').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('31', 'accepted_by_courier', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedByCourier ? (acceptedByCourier.some(res => res.ageing === '31++') ? acceptedByCourier.find(res => res.ageing === '31++').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                  </tr>
                    <tr key="2">
                    <td>accepted_to_warehouse</td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('Today', 'accepted_to_warehouse', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedToWarehouse ? (acceptedToWarehouse.some(res => res.ageing === 'Today') ? acceptedToWarehouse.find(res => res.ageing === 'Today').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('1', 'accepted_to_warehouse', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedToWarehouse ? (acceptedToWarehouse.some(res => res.ageing === '1') ? acceptedToWarehouse.find(res => res.ageing === '1').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('2', 'accepted_to_warehouse', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedToWarehouse ? (acceptedToWarehouse.some(res => res.ageing === '2') ? acceptedToWarehouse.find(res => res.ageing === '2').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('3', 'accepted_to_warehouse', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedToWarehouse ? (acceptedToWarehouse.some(res => res.ageing === '3') ? acceptedToWarehouse.find(res => res.ageing === '3').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('4', 'accepted_to_warehouse', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedToWarehouse ? (acceptedToWarehouse.some(res => res.ageing === '4') ? acceptedToWarehouse.find(res => res.ageing === '4').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('5', 'accepted_to_warehouse', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedToWarehouse ? (acceptedToWarehouse.some(res => res.ageing === '5') ? acceptedToWarehouse.find(res => res.ageing === '5').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('6', 'accepted_to_warehouse', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedToWarehouse ? (acceptedToWarehouse.some(res => res.ageing === '6') ? acceptedToWarehouse.find(res => res.ageing === '6').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('7', 'accepted_to_warehouse', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedToWarehouse ? (acceptedToWarehouse.some(res => res.ageing === '7') ? acceptedToWarehouse.find(res => res.ageing === '7').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('8-14', 'accepted_to_warehouse', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedToWarehouse ? (acceptedToWarehouse.some(res => res.ageing === '8-14') ? acceptedToWarehouse.find(res => res.ageing === '8-14').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('15-30', 'accepted_to_warehouse', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedToWarehouse ? (acceptedToWarehouse.some(res => res.ageing === '15-30') ? acceptedToWarehouse.find(res => res.ageing === '15-30').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('31', 'accepted_to_warehouse', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountAcceptedToWarehouse ? (acceptedToWarehouse.some(res => res.ageing === '31++') ? acceptedToWarehouse.find(res => res.ageing === '31++').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                  </tr>
                    <tr key="3">
                    <td>picked</td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('Today', 'picked', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPicked ? (pickedTts.some(res => res.ageing === 'Today') ? pickedTts.find(res => res.ageing === 'Today').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('1', 'picked', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPicked ? (pickedTts.some(res => res.ageing === '1') ? pickedTts.find(res => res.ageing === '1').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('2', 'picked', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPicked ? (pickedTts.some(res => res.ageing === '2') ? pickedTts.find(res => res.ageing === '2').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('3', 'picked', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPicked ? (pickedTts.some(res => res.ageing === '3') ? pickedTts.find(res => res.ageing === '3').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('4', 'picked', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPicked ? (pickedTts.some(res => res.ageing === '4') ? pickedTts.find(res => res.ageing === '4').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('5', 'picked', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPicked ? (pickedTts.some(res => res.ageing === '5') ? pickedTts.find(res => res.ageing === '5').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('6', 'picked', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPicked ? (pickedTts.some(res => res.ageing === '6') ? pickedTts.find(res => res.ageing === '6').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('7', 'picked', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPicked ? (pickedTts.some(res => res.ageing === '7') ? pickedTts.find(res => res.ageing === '7').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('8-14', 'picked', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPicked ? (pickedTts.some(res => res.ageing === '8-14') ? pickedTts.find(res => res.ageing === '8-14').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('15-30', 'picked', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPicked ? (pickedTts.some(res => res.ageing === '15-30') ? pickedTts.find(res => res.ageing === '15-30').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('31', 'picked', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountPicked ? (pickedTts.some(res => res.ageing === '31++') ? pickedTts.find(res => res.ageing === '31++').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                  </tr>
                    <tr key="4">
                    <td>released</td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('Today', 'released', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountReleased ? (released.some(res => res.ageing === 'Today') ? released.find(res => res.ageing === 'Today').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('1', 'released', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountReleased ? (released.some(res => res.ageing === '1') ? released.find(res => res.ageing === '1').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('2', 'released', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountReleased ? (released.some(res => res.ageing === '2') ? released.find(res => res.ageing === '2').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('3', 'released', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountReleased ? (released.some(res => res.ageing === '3') ? released.find(res => res.ageing === '3').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('4', 'released', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountReleased ? (released.some(res => res.ageing === '4') ? released.find(res => res.ageing === '4').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('5', 'released', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountReleased ? (released.some(res => res.ageing === '5') ? released.find(res => res.ageing === '5').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('6', 'released', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountReleased ? (released.some(res => res.ageing === '6') ? released.find(res => res.ageing === '6').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('7', 'released', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountReleased ? (released.some(res => res.ageing === '7') ? released.find(res => res.ageing === '7').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('8-14', 'released', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountReleased ? (released.some(res => res.ageing === '8-14') ? released.find(res => res.ageing === '8-14').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('15-30', 'released', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountReleased ? (released.some(res => res.ageing === '15-30') ? released.find(res => res.ageing === '15-30').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('31', 'released', ttsType, 'tts/export', 'tts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountReleased ? (released.some(res => res.ageing === '31++') ? released.find(res => res.ageing === '31++').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </Spin>
            </div>
          </Card>
          <Spacer />
          <Card
            title="Shipped"
            extra={
              (
                <React.Fragment>
                  <Paragraph strong>Select Ageing Reference :</Paragraph>
                  <Select
                    onChange={e => this.typeHandler(e, 'shippedType', 'shipped', 'isMountingModuleShipped')}
                    style={{ width: 180 }}
                    defaultValue="Total"
                    options={optionAgeingReference}
                  />
                </React.Fragment>
              )
            }
          >
            {(() => {
              switch (shippedType) {
                case 'status':
                  return (
                    <Alert
                      message="Ageing Reference - Status"
                      description="Difference between today and the package's last status update."
                      type="info"
                      showIcon
                    />
                  );
                case 'total':
                  return (
                    <Alert
                      message="Ageing Reference - Total"
                      description="Difference between today and when the package was first accepted by courier or picked, whichever is older."
                      type="info"
                      showIcon
                    />
                  );
                case 'milestone':
                  return (
                    <Alert
                      message="Ageing Reference - Milestone"
                      description="Difference between today and when the package was first delivery attempted or transferred to other branch, whichever is older."
                      type="info"
                      showIcon
                    />
                  );
                default:
                  return false;
              }
            })()}
            <Spacer />
            <div className="dashboardContainer">
              <Spin spinning={isMountingModuleShipped} size="small" tip="Fetching shipped details. . .">
                <table className="tableStyle">
                  <thead>
                    <tr>
                    <th>Page</th>
                    <th>Today</th>
                    <th>1d</th>
                    <th>2d</th>
                    <th>3d</th>
                    <th>4d</th>
                    <th>5d</th>
                    <th>6d</th>
                    <th>7d</th>
                    <th>8-14d</th>
                    <th>15-30d</th>
                    <th>31+d</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr key="1">
                      <td>accepted_to_branch</td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('Today', 'accepted_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToBranch ? (acceptedToBranch.some(res => res.ageing === 'Today') ? acceptedToBranch.find(res => res.ageing === 'Today').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('1', 'accepted_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToBranch ? (acceptedToBranch.some(res => res.ageing === '1') ? acceptedToBranch.find(res => res.ageing === '1').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('2', 'accepted_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToBranch ? (acceptedToBranch.some(res => res.ageing === '2') ? acceptedToBranch.find(res => res.ageing === '2').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('3', 'accepted_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToBranch ? (acceptedToBranch.some(res => res.ageing === '3') ? acceptedToBranch.find(res => res.ageing === '3').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('4', 'accepted_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToBranch ? (acceptedToBranch.some(res => res.ageing === '4') ? acceptedToBranch.find(res => res.ageing === '4').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('5', 'accepted_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToBranch ? (acceptedToBranch.some(res => res.ageing === '5') ? acceptedToBranch.find(res => res.ageing === '5').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('6', 'accepted_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToBranch ? (acceptedToBranch.some(res => res.ageing === '6') ? acceptedToBranch.find(res => res.ageing === '6').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('7', 'accepted_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToBranch ? (acceptedToBranch.some(res => res.ageing === '7') ? acceptedToBranch.find(res => res.ageing === '7').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('8-14', 'accepted_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToBranch ? (acceptedToBranch.some(res => res.ageing === '8-14') ? acceptedToBranch.find(res => res.ageing === '8-14').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('15-30', 'accepted_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToBranch ? (acceptedToBranch.some(res => res.ageing === '15-30') ? acceptedToBranch.find(res => res.ageing === '15-30').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('31', 'accepted_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToBranch ? (acceptedToBranch.some(res => res.ageing === '31++') ? acceptedToBranch.find(res => res.ageing === '31++').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                    <tr key="2">
                      <td>accepted_to_warehouse</td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('Today', 'accepted_to_warehouse', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToWarehouseShipped ? (acceptedToWarehouseShipped.some(res => res.ageing === 'Today') ? acceptedToWarehouseShipped.find(res => res.ageing === 'Today').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('1', 'accepted_to_warehouse', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToWarehouseShipped ? (acceptedToWarehouseShipped.some(res => res.ageing === '1') ? acceptedToWarehouseShipped.find(res => res.ageing === '1').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('2', 'accepted_to_warehouse', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToWarehouseShipped ? (acceptedToWarehouseShipped.some(res => res.ageing === '2') ? acceptedToWarehouseShipped.find(res => res.ageing === '2').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('3', 'accepted_to_warehouse', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToWarehouseShipped ? (acceptedToWarehouseShipped.some(res => res.ageing === '3') ? acceptedToWarehouseShipped.find(res => res.ageing === '3').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('4', 'accepted_to_warehouse', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToWarehouseShipped ? (acceptedToWarehouseShipped.some(res => res.ageing === '4') ? acceptedToWarehouseShipped.find(res => res.ageing === '4').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('5', 'accepted_to_warehouse', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToWarehouseShipped ? (acceptedToWarehouseShipped.some(res => res.ageing === '5') ? acceptedToWarehouseShipped.find(res => res.ageing === '5').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('6', 'accepted_to_warehouse', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToWarehouseShipped ? (acceptedToWarehouseShipped.some(res => res.ageing === '6') ? acceptedToWarehouseShipped.find(res => res.ageing === '6').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('7', 'accepted_to_warehouse', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToWarehouseShipped ? (acceptedToWarehouseShipped.some(res => res.ageing === '7') ? acceptedToWarehouseShipped.find(res => res.ageing === '7').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('8-14', 'accepted_to_warehouse', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToWarehouseShipped ? (acceptedToWarehouseShipped.some(res => res.ageing === '8-14') ? acceptedToWarehouseShipped.find(res => res.ageing === '8-14').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('15-30', 'accepted_to_warehouse', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToWarehouseShipped ? (acceptedToWarehouseShipped.some(res => res.ageing === '15-30') ? acceptedToWarehouseShipped.find(res => res.ageing === '15-30').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('31', 'accepted_to_warehouse', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountAcceptedToWarehouseShipped ? (acceptedToWarehouseShipped.some(res => res.ageing === '31++') ? acceptedToWarehouseShipped.find(res => res.ageing === '31++').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                    <tr key="3">
                      <td>first_attempt_failed</td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('Today', 'first_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstAttemptFailed ? (firstAttemptFailed.some(res => res.ageing === 'Today') ? firstAttemptFailed.find(res => res.ageing === 'Today').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('1', 'first_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstAttemptFailed ? (firstAttemptFailed.some(res => res.ageing === '1') ? firstAttemptFailed.find(res => res.ageing === '1').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('2', 'first_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstAttemptFailed ? (firstAttemptFailed.some(res => res.ageing === '2') ? firstAttemptFailed.find(res => res.ageing === '2').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('3', 'first_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstAttemptFailed ? (firstAttemptFailed.some(res => res.ageing === '3') ? firstAttemptFailed.find(res => res.ageing === '3').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('4', 'first_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstAttemptFailed ? (firstAttemptFailed.some(res => res.ageing === '4') ? firstAttemptFailed.find(res => res.ageing === '4').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('5', 'first_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstAttemptFailed ? (firstAttemptFailed.some(res => res.ageing === '5') ? firstAttemptFailed.find(res => res.ageing === '5').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('6', 'first_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstAttemptFailed ? (firstAttemptFailed.some(res => res.ageing === '6') ? firstAttemptFailed.find(res => res.ageing === '6').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('7', 'first_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstAttemptFailed ? (firstAttemptFailed.some(res => res.ageing === '7') ? firstAttemptFailed.find(res => res.ageing === '7').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('8-14', 'first_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstAttemptFailed ? (firstAttemptFailed.some(res => res.ageing === '8-14') ? firstAttemptFailed.find(res => res.ageing === '8-14').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('15-30', 'first_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstAttemptFailed ? (firstAttemptFailed.some(res => res.ageing === '15-30') ? firstAttemptFailed.find(res => res.ageing === '15-30').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('31', 'first_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstAttemptFailed ? (firstAttemptFailed.some(res => res.ageing === '31++') ? firstAttemptFailed.find(res => res.ageing === '31++').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                    <tr key="4">
                      <td>first_delivery_attempt</td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('Today', 'first_delivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstDeliveryAttempt ? (firstDeliveryAttempt.some(res => res.ageing === 'Today') ? firstDeliveryAttempt.find(res => res.ageing === 'Today').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('1', 'first_delivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstDeliveryAttempt ? (firstDeliveryAttempt.some(res => res.ageing === '1') ? firstDeliveryAttempt.find(res => res.ageing === '1').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('2', 'first_delivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstDeliveryAttempt ? (firstDeliveryAttempt.some(res => res.ageing === '2') ? firstDeliveryAttempt.find(res => res.ageing === '2').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('3', 'first_delivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstDeliveryAttempt ? (firstDeliveryAttempt.some(res => res.ageing === '3') ? firstDeliveryAttempt.find(res => res.ageing === '3').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('4', 'first_delivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstDeliveryAttempt ? (firstDeliveryAttempt.some(res => res.ageing === '4') ? firstDeliveryAttempt.find(res => res.ageing === '4').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('5', 'first_delivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstDeliveryAttempt ? (firstDeliveryAttempt.some(res => res.ageing === '5') ? firstDeliveryAttempt.find(res => res.ageing === '5').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('6', 'first_delivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstDeliveryAttempt ? (firstDeliveryAttempt.some(res => res.ageing === '6') ? firstDeliveryAttempt.find(res => res.ageing === '6').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('7', 'first_delivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstDeliveryAttempt ? (firstDeliveryAttempt.some(res => res.ageing === '7') ? firstDeliveryAttempt.find(res => res.ageing === '7').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('8-14', 'first_delivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstDeliveryAttempt ? (firstDeliveryAttempt.some(res => res.ageing === '8-14') ? firstDeliveryAttempt.find(res => res.ageing === '8-14').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('15-30', 'first_delivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstDeliveryAttempt ? (firstDeliveryAttempt.some(res => res.ageing === '15-30') ? firstDeliveryAttempt.find(res => res.ageing === '15-30').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('31', 'first_delivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFirstDeliveryAttempt ? (firstDeliveryAttempt.some(res => res.ageing === '31++') ? firstDeliveryAttempt.find(res => res.ageing === '31++').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                    <tr key="5">
                      <td>forwarded_to_branch</td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('Today', 'forwarded_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToBranch ? (forwardedToBranch.some(res => res.ageing === 'Today') ? forwardedToBranch.find(res => res.ageing === 'Today').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('1', 'forwarded_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToBranch ? (forwardedToBranch.some(res => res.ageing === '1') ? forwardedToBranch.find(res => res.ageing === '1').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('2', 'forwarded_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToBranch ? (forwardedToBranch.some(res => res.ageing === '2') ? forwardedToBranch.find(res => res.ageing === '2').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('3', 'forwarded_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToBranch ? (forwardedToBranch.some(res => res.ageing === '3') ? forwardedToBranch.find(res => res.ageing === '3').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('4', 'forwarded_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToBranch ? (forwardedToBranch.some(res => res.ageing === '4') ? forwardedToBranch.find(res => res.ageing === '4').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('5', 'forwarded_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToBranch ? (forwardedToBranch.some(res => res.ageing === '5') ? forwardedToBranch.find(res => res.ageing === '5').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('6', 'forwarded_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToBranch ? (forwardedToBranch.some(res => res.ageing === '6') ? forwardedToBranch.find(res => res.ageing === '6').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('7', 'forwarded_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToBranch ? (forwardedToBranch.some(res => res.ageing === '7') ? forwardedToBranch.find(res => res.ageing === '7').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('8-14', 'forwarded_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToBranch ? (forwardedToBranch.some(res => res.ageing === '8-14') ? forwardedToBranch.find(res => res.ageing === '8-14').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('15-30', 'forwarded_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToBranch ? (forwardedToBranch.some(res => res.ageing === '15-30') ? forwardedToBranch.find(res => res.ageing === '15-30').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('31', 'forwarded_to_branch', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToBranch ? (forwardedToBranch.some(res => res.ageing === '31++') ? forwardedToBranch.find(res => res.ageing === '31++').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                    <tr key="6">
                      <td>redelivery_attempt</td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('Today', 'redelivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRedeliveryAttempt ? (redeliveryAttempt.some(res => res.ageing === 'Today') ? redeliveryAttempt.find(res => res.ageing === 'Today').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('1', 'redelivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRedeliveryAttempt ? (redeliveryAttempt.some(res => res.ageing === '1') ? redeliveryAttempt.find(res => res.ageing === '1').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('2', 'redelivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRedeliveryAttempt ? (redeliveryAttempt.some(res => res.ageing === '2') ? redeliveryAttempt.find(res => res.ageing === '2').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('3', 'redelivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRedeliveryAttempt ? (redeliveryAttempt.some(res => res.ageing === '3') ? redeliveryAttempt.find(res => res.ageing === '3').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('4', 'redelivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRedeliveryAttempt ? (redeliveryAttempt.some(res => res.ageing === '4') ? redeliveryAttempt.find(res => res.ageing === '4').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('5', 'redelivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRedeliveryAttempt ? (redeliveryAttempt.some(res => res.ageing === '5') ? redeliveryAttempt.find(res => res.ageing === '5').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('6', 'redelivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRedeliveryAttempt ? (redeliveryAttempt.some(res => res.ageing === '6') ? redeliveryAttempt.find(res => res.ageing === '6').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('7', 'redelivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRedeliveryAttempt ? (redeliveryAttempt.some(res => res.ageing === '7') ? redeliveryAttempt.find(res => res.ageing === '7').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('8-14', 'redelivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRedeliveryAttempt ? (redeliveryAttempt.some(res => res.ageing === '8-14') ? redeliveryAttempt.find(res => res.ageing === '8-14').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('15-30', 'redelivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRedeliveryAttempt ? (redeliveryAttempt.some(res => res.ageing === '15-30') ? redeliveryAttempt.find(res => res.ageing === '15-30').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('31', 'redelivery_attempt', shippedType, 'shipped/export', 'shipped')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRedeliveryAttempt ? (redeliveryAttempt.some(res => res.ageing === '31++') ? redeliveryAttempt.find(res => res.ageing === '31++').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                    <tr key="7">
                    <td>redelivery_attempt_failed</td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('Today', 'redelivery_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountRedeliveryAttemptFailed ? (redeliveryAttemptFailed.some(res => res.ageing === 'Today') ? redeliveryAttemptFailed.find(res => res.ageing === 'Today').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('1', 'redelivery_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountRedeliveryAttemptFailed ? (redeliveryAttemptFailed.some(res => res.ageing === '1') ? redeliveryAttemptFailed.find(res => res.ageing === '1').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('2', 'redelivery_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountRedeliveryAttemptFailed ? (redeliveryAttemptFailed.some(res => res.ageing === '2') ? redeliveryAttemptFailed.find(res => res.ageing === '2').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('3', 'redelivery_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountRedeliveryAttemptFailed ? (redeliveryAttemptFailed.some(res => res.ageing === '3') ? redeliveryAttemptFailed.find(res => res.ageing === '3').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('4', 'redelivery_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountRedeliveryAttemptFailed ? (redeliveryAttemptFailed.some(res => res.ageing === '4') ? redeliveryAttemptFailed.find(res => res.ageing === '4').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('5', 'redelivery_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountRedeliveryAttemptFailed ? (redeliveryAttemptFailed.some(res => res.ageing === '5') ? redeliveryAttemptFailed.find(res => res.ageing === '5').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('6', 'redelivery_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountRedeliveryAttemptFailed ? (redeliveryAttemptFailed.some(res => res.ageing === '6') ? redeliveryAttemptFailed.find(res => res.ageing === '6').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('7', 'redelivery_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountRedeliveryAttemptFailed ? (redeliveryAttemptFailed.some(res => res.ageing === '7') ? redeliveryAttemptFailed.find(res => res.ageing === '7').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('8-14', 'redelivery_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountRedeliveryAttemptFailed ? (redeliveryAttemptFailed.some(res => res.ageing === '8-14') ? redeliveryAttemptFailed.find(res => res.ageing === '8-14').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('15-30', 'redelivery_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountRedeliveryAttemptFailed ? (redeliveryAttemptFailed.some(res => res.ageing === '15-30') ? redeliveryAttemptFailed.find(res => res.ageing === '15-30').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('31', 'redelivery_attempt_failed', shippedType, 'shipped/export', 'shipped')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountRedeliveryAttemptFailed ? (redeliveryAttemptFailed.some(res => res.ageing === '31++') ? redeliveryAttemptFailed.find(res => res.ageing === '31++').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </Spin>
            </div>
          </Card>
          <Spacer />
          <Card
            title="Return To Shipper"
            extra={
              (
                <React.Fragment>
                  <Paragraph strong>Select Ageing Reference :</Paragraph>
                  <Select
                    onChange={e => this.typeHandler(e, 'rtsType', 'rts', 'isMountingModuleRts')}
                    style={{ width: 180 }}
                    defaultValue="Total"
                    options={optionAgeingReference}
                  />
                </React.Fragment>
              )
            }
          >
            {(() => {
              switch (rtsType) {
                case 'status':
                  return (
                    <Alert
                      message="Ageing Reference - Status"
                      description="Difference between today and the package's last status update."
                      type="info"
                      showIcon
                    />
                  );
                case 'total':
                  return (
                    <Alert
                      message="Ageing Reference - Total"
                      description="Difference between today and when the package was first accepted by courier or picked, whichever is older."
                      type="info"
                      showIcon
                    />
                  );
                case 'milestone':
                  return (
                    <Alert
                      message="Ageing Reference - Milestone"
                      description="Difference between today and when the package was tagged with failed delivery return."
                      type="info"
                      showIcon
                    />
                  );
                default:
                  return false;
              }
            })()}
            <Spacer />
            <div className="dashboardContainer">
              <Spin spinning={isMountingModuleRts} size="small" tip="Fetching return to shipper details. . .">
                <table className="tableStyle">
                  <thead>
                    <tr>
                    <th>Page</th>
                    <th>Today</th>
                    <th>1d</th>
                    <th>2d</th>
                    <th>3d</th>
                    <th>4d</th>
                    <th>5d</th>
                    <th>6d</th>
                    <th>7d</th>
                    <th>8-14d</th>
                    <th>15-30d</th>
                    <th>31+d</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr key="1">
                      <td>branch_received_rts</td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('Today', 'branch_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountBranchReceivedRts ? (branchReceivedRts.some(res => res.ageing === 'Today') ? branchReceivedRts.find(res => res.ageing === 'Today').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('1', 'branch_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountBranchReceivedRts ? (branchReceivedRts.some(res => res.ageing === '1') ? branchReceivedRts.find(res => res.ageing === '1').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('2', 'branch_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountBranchReceivedRts ? (branchReceivedRts.some(res => res.ageing === '2') ? branchReceivedRts.find(res => res.ageing === '2').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('3', 'branch_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountBranchReceivedRts ? (branchReceivedRts.some(res => res.ageing === '3') ? branchReceivedRts.find(res => res.ageing === '3').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('4', 'branch_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountBranchReceivedRts ? (branchReceivedRts.some(res => res.ageing === '4') ? branchReceivedRts.find(res => res.ageing === '4').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('5', 'branch_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountBranchReceivedRts ? (branchReceivedRts.some(res => res.ageing === '5') ? branchReceivedRts.find(res => res.ageing === '5').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('6', 'branch_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountBranchReceivedRts ? (branchReceivedRts.some(res => res.ageing === '6') ? branchReceivedRts.find(res => res.ageing === '6').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('7', 'branch_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountBranchReceivedRts ? (branchReceivedRts.some(res => res.ageing === '7') ? branchReceivedRts.find(res => res.ageing === '7').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('8-14', 'branch_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountBranchReceivedRts ? (branchReceivedRts.some(res => res.ageing === '8-14') ? branchReceivedRts.find(res => res.ageing === '8-14').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('15-30', 'branch_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountBranchReceivedRts ? (branchReceivedRts.some(res => res.ageing === '15-30') ? branchReceivedRts.find(res => res.ageing === '15-30').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('31', 'branch_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountBranchReceivedRts ? (branchReceivedRts.some(res => res.ageing === '31++') ? branchReceivedRts.find(res => res.ageing === '31++').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                    <tr key="2">
                      <td>failed_delivery_return</td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('Today', 'failed_delivery_return', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFailedDeliveryReturn ? (failedDeliveryReturn.some(res => res.ageing === 'Today') ? failedDeliveryReturn.find(res => res.ageing === 'Today').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('1', 'failed_delivery_return', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFailedDeliveryReturn ? (failedDeliveryReturn.some(res => res.ageing === '1') ? failedDeliveryReturn.find(res => res.ageing === '1').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('2', 'failed_delivery_return', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFailedDeliveryReturn ? (failedDeliveryReturn.some(res => res.ageing === '2') ? failedDeliveryReturn.find(res => res.ageing === '2').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('3', 'failed_delivery_return', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFailedDeliveryReturn ? (failedDeliveryReturn.some(res => res.ageing === '3') ? failedDeliveryReturn.find(res => res.ageing === '3').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('4', 'failed_delivery_return', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFailedDeliveryReturn ? (failedDeliveryReturn.some(res => res.ageing === '4') ? failedDeliveryReturn.find(res => res.ageing === '4').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('5', 'failed_delivery_return', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFailedDeliveryReturn ? (failedDeliveryReturn.some(res => res.ageing === '5') ? failedDeliveryReturn.find(res => res.ageing === '5').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('6', 'failed_delivery_return', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFailedDeliveryReturn ? (failedDeliveryReturn.some(res => res.ageing === '6') ? failedDeliveryReturn.find(res => res.ageing === '6').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('7', 'failed_delivery_return', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFailedDeliveryReturn ? (failedDeliveryReturn.some(res => res.ageing === '7') ? failedDeliveryReturn.find(res => res.ageing === '7').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('8-14', 'failed_delivery_return', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFailedDeliveryReturn ? (failedDeliveryReturn.some(res => res.ageing === '8-14') ? failedDeliveryReturn.find(res => res.ageing === '8-14').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('15-30', 'failed_delivery_return', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFailedDeliveryReturn ? (failedDeliveryReturn.some(res => res.ageing === '15-30') ? failedDeliveryReturn.find(res => res.ageing === '15-30').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('31', 'failed_delivery_return', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountFailedDeliveryReturn ? (failedDeliveryReturn.some(res => res.ageing === '31++') ? failedDeliveryReturn.find(res => res.ageing === '31++').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                    <tr key="3">
                      <td>forwarded_to_main</td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('Today', 'forwarded_to_main', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToMain ? (forwardedToMain.some(res => res.ageing === 'Today') ? forwardedToMain.find(res => res.ageing === 'Today').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('1', 'forwarded_to_main', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToMain ? (forwardedToMain.some(res => res.ageing === '1') ? forwardedToMain.find(res => res.ageing === '1').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('2', 'forwarded_to_main', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToMain ? (forwardedToMain.some(res => res.ageing === '2') ? forwardedToMain.find(res => res.ageing === '2').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('3', 'forwarded_to_main', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToMain ? (forwardedToMain.some(res => res.ageing === '3') ? forwardedToMain.find(res => res.ageing === '3').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('4', 'forwarded_to_main', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToMain ? (forwardedToMain.some(res => res.ageing === '4') ? forwardedToMain.find(res => res.ageing === '4').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('5', 'forwarded_to_main', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToMain ? (forwardedToMain.some(res => res.ageing === '5') ? forwardedToMain.find(res => res.ageing === '5').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('6', 'forwarded_to_main', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToMain ? (forwardedToMain.some(res => res.ageing === '6') ? forwardedToMain.find(res => res.ageing === '6').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('7', 'forwarded_to_main', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToMain ? (forwardedToMain.some(res => res.ageing === '7') ? forwardedToMain.find(res => res.ageing === '7').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('8-14', 'forwarded_to_main', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToMain ? (forwardedToMain.some(res => res.ageing === '8-14') ? forwardedToMain.find(res => res.ageing === '8-14').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('15-30', 'forwarded_to_main', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToMain ? (forwardedToMain.some(res => res.ageing === '15-30') ? forwardedToMain.find(res => res.ageing === '15-30').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('31', 'forwarded_to_main', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountForwardedToMain ? (forwardedToMain.some(res => res.ageing === '31++') ? forwardedToMain.find(res => res.ageing === '31++').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                    <tr key="4">
                      <td>main_received_rts</td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('Today', 'main_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRainReceivedRts ? (mainReceivedRts.some(res => res.ageing === 'Today') ? mainReceivedRts.find(res => res.ageing === 'Today').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('1', 'main_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRainReceivedRts ? (mainReceivedRts.some(res => res.ageing === '1') ? mainReceivedRts.find(res => res.ageing === '1').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('2', 'main_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRainReceivedRts ? (mainReceivedRts.some(res => res.ageing === '2') ? mainReceivedRts.find(res => res.ageing === '2').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('3', 'main_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRainReceivedRts ? (mainReceivedRts.some(res => res.ageing === '3') ? mainReceivedRts.find(res => res.ageing === '3').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('4', 'main_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRainReceivedRts ? (mainReceivedRts.some(res => res.ageing === '4') ? mainReceivedRts.find(res => res.ageing === '4').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('5', 'main_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRainReceivedRts ? (mainReceivedRts.some(res => res.ageing === '5') ? mainReceivedRts.find(res => res.ageing === '5').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('6', 'main_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRainReceivedRts ? (mainReceivedRts.some(res => res.ageing === '6') ? mainReceivedRts.find(res => res.ageing === '6').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('7', 'main_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRainReceivedRts ? (mainReceivedRts.some(res => res.ageing === '7') ? mainReceivedRts.find(res => res.ageing === '7').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('8-14', 'main_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRainReceivedRts ? (mainReceivedRts.some(res => res.ageing === '8-14') ? mainReceivedRts.find(res => res.ageing === '8-14').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('15-30', 'main_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRainReceivedRts ? (mainReceivedRts.some(res => res.ageing === '15-30') ? mainReceivedRts.find(res => res.ageing === '15-30').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                      <td>
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to download this item?"
                          onConfirm={() => this.getMonitoringExport('31', 'main_received_rts', rtsType, 'rts/export', 'rts')}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link">
                            {getCountRainReceivedRts ? (mainReceivedRts.some(res => res.ageing === '31++') ? mainReceivedRts.find(res => res.ageing === '31++').package_count : 0) : 0}
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                    <tr key="5">
                    <td>out_for_return</td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('Today', 'out_for_return', rtsType, 'rts/export', 'rts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountOutForReturn ? (outForReturn.some(res => res.ageing === 'Today') ? outForReturn.find(res => res.ageing === 'Today').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('1', 'out_for_return', rtsType, 'rts/export', 'rts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountOutForReturn ? (outForReturn.some(res => res.ageing === '1') ? outForReturn.find(res => res.ageing === '1').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('2', 'out_for_return', rtsType, 'rts/export', 'rts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountOutForReturn ? (outForReturn.some(res => res.ageing === '2') ? outForReturn.find(res => res.ageing === '2').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('3', 'out_for_return', rtsType, 'rts/export', 'rts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountOutForReturn ? (outForReturn.some(res => res.ageing === '3') ? outForReturn.find(res => res.ageing === '3').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('4', 'out_for_return', rtsType, 'rts/export', 'rts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountOutForReturn ? (outForReturn.some(res => res.ageing === '4') ? outForReturn.find(res => res.ageing === '4').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('5', 'out_for_return', rtsType, 'rts/export', 'rts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountOutForReturn ? (outForReturn.some(res => res.ageing === '5') ? outForReturn.find(res => res.ageing === '5').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('6', 'out_for_return', rtsType, 'rts/export', 'rts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountOutForReturn ? (outForReturn.some(res => res.ageing === '6') ? outForReturn.find(res => res.ageing === '6').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('7', 'out_for_return', rtsType, 'rts/export', 'rts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountOutForReturn ? (outForReturn.some(res => res.ageing === '7') ? outForReturn.find(res => res.ageing === '7').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('8-14', 'out_for_return', rtsType, 'rts/export', 'rts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountOutForReturn ? (outForReturn.some(res => res.ageing === '8-14') ? outForReturn.find(res => res.ageing === '8-14').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('15-30', 'out_for_return', rtsType, 'rts/export', 'rts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountOutForReturn ? (outForReturn.some(res => res.ageing === '15-30') ? outForReturn.find(res => res.ageing === '15-30').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                    <td>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to download this item?"
                        onConfirm={() => this.getMonitoringExport('31', 'out_for_return', rtsType, 'rts/export', 'rts')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          {getCountOutForReturn ? (outForReturn.some(res => res.ageing === '31++') ? outForReturn.find(res => res.ageing === '31++').package_count : 0) : 0}
                        </Button>
                      </Popconfirm>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </Spin>
            </div>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  doAccountList: accountList,
  doFetchOptions: fetchOptions,
  doFetchOptionsLike: fetchOptionsLike,
};
Dashboard.propTypes = {
  doAccountList: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);

import {
  apiFetchList,
  unRemittanceRemitted,
  billingUnbilledViews,
  cashierUnremittedList,
  getOptionsDispatch,
} from '../../services/api';

import { apiFetchOptionsLike } from '../../services/apiEndpoints';

const REPORTS_LIST = 'reports/REPORTS_LIST';
const REPORTS_LIST_SUCCESS = 'reports/REPORTS_LIST_SUCCESS';

/**
 * dispatch/items --- for dispatch items --- DispatchedItems.js
 * pickup/items --- for picked items --- PickedItems.js
 * package --- for for-pick-up and items location --- ItemsForPickup.js and ItemsLocation.js
 */

export const reportList = (filter, page) => ({
  type: REPORTS_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchList(page),
      data: filter,
    },
  },
});

const FETCH_OPTIONS = 'reports/FETCH_OPTIONS';
export const fetchOptions = (option, value) => ({
  type: FETCH_OPTIONS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchOptionsLike(option, value),
    },
  },
});

const REPORTS_NOFILTER_LIST = 'reports/REPORTS_NOFILTER_LIST';
const REPORTS_NOFILTER_LIST_SUCCESS = 'reports/REPORTS_NOFILTER_LIST_SUCCESS';

/**
 * dispatch/items --- for dispatch items --- DispatchedItems.js
 * pickup/items --- for picked items --- PickedItems.js
 * package --- for for-pick-up and items location --- ItemsForPickup.js and ItemsLocation.js
 */

export const reportNoFilterList = (page, data) => ({
  type: REPORTS_NOFILTER_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchList(page),
      data,
    },
  },
});

const UNREMITTED_LIST = 'reports/UNREMITTED_LIST';
const UNREMITTED_LIST_SUCCESS = 'reports/UNREMITTED_LIST_SUCCESS';

/**
 * for unremitted Items --- UnremittedItems.js
 */

export const reportUnremittedList = filter => ({
  type: UNREMITTED_LIST,
  payload: {
    client: 'secure',
    request: {
      ...unRemittanceRemitted,
      data: filter,
    },
  },
});

const UNREMITTED_NOFILTER_LIST = 'reports/UNREMITTED_NOFILTER_LIST';
const UNREMITTED_NOFILTER_LIST_SUCCESS = 'reports/UNREMITTED_NOFILTER_LIST_SUCCESS';

/**
 * for unremitted Items --- UnremittedItems.js
 */

export const reportUnremittedNoFilterList = data => ({
  type: UNREMITTED_NOFILTER_LIST,
  payload: {
    client: 'secure',
    request: {
      ...cashierUnremittedList,
      data,
    },
  },
});

const CASHIER_UNREMITTED_LIST = 'reports/CASHIER_UNREMITTED_LIST';
const CASHIER_UNREMITTED_LIST_SUCCESS = 'reports/CASHIER_UNREMITTED_LIST_SUCCESS';

/**
 * for cashier unremitted --- CashierUnremitted.js
 */

export const reportCashierUnremittedList = data => ({
  type: CASHIER_UNREMITTED_LIST,
  payload: {
    client: 'secure',
    request: {
      ...unRemittanceRemitted,
      data,
    },
  },
});

const REPORT_PICKITEMS_VIEW_ACCOUNT = 'reports/REPORT_PICKITEMS_VIEW_ACCOUNT';
const REPORT_PICKITEMS_VIEW_ACCOUNT_SUCCESS = 'reports/REPORT_PICKITEMS_VIEW_ACCOUNT_SUCCESS';

/**
 * for cashier unremitted --- CashierUnremitted.js
 */

export const reportPickItemsAccount = tNumber => ({
  type: REPORT_PICKITEMS_VIEW_ACCOUNT,
  payload: {
    client: 'secure',
    request: {
      ...billingUnbilledViews(tNumber),
    },
  },
});

const REPORT_OPTIONS_USER = 'reports/REPORT_OPTIONS_USER';
const REPORT_OPTIONS_USER_SUCCESS = 'reports/REPORT_OPTIONS_USER_SUCCESS';

const REPORT_OPTIONS_CLIENT = 'reports/REPORT_OPTIONS_CLIENT';
const REPORT_OPTIONS_CLIENT_SUCCESS = 'reports/REPORT_OPTIONS_CLIENT_SUCCESS';

const REPORT_OPTIONS_BRANCHCODE = 'reports/REPORT_OPTIONS_BRANCHCODE';
const REPORT_OPTIONS_BRANCHCODE_SUCCESS = 'reports/REPORT_OPTIONS_BRANCHCODE_SUCCESS';

const REPORT_OPTIONS_DESTINATION = 'reports/REPORT_OPTIONS_DESTINATION';
const REPORT_OPTIONS_DESTINATION_SUCCESS = 'reports/REPORT_OPTIONS_DESTINATION_SUCCESS';

const REPORT_OPTIONS_STATUS = 'reports/REPORT_OPTIONS_STATUS';
const REPORT_OPTIONS_STATUS_SUCCESS = 'reports/REPORT_OPTIONS_STATUS_SUCCESS';

const REPORT_OPTIONS_MERCHANT = 'reports/REPORT_OPTIONS_MERCHANT';
const REPORT_OPTIONS_MERCHANT_SUCCESS = 'reports/REPORT_OPTIONS_MERCHANT_SUCCESS';

export const reportPickOptions = (tNumber) => {
  switch (tNumber) {
    case 'user':
      return {
        type: REPORT_OPTIONS_USER,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('user'),
          },
        },
      };

    case 'client':
      return {
        type: REPORT_OPTIONS_CLIENT,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('client'),
          },
        },
      };

    case 'branch_code':
      return {
        type: REPORT_OPTIONS_BRANCHCODE,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('branch_code'),
          },
        },
      };

    case 'destination':
      return {
        type: REPORT_OPTIONS_DESTINATION,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('destination'),
          },
        },
      };

    case 'status':
      return {
        type: REPORT_OPTIONS_STATUS,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('status'),
          },
        },
      };

    case 'merchant':
      return {
        type: REPORT_OPTIONS_MERCHANT,
        payload: {
          client: 'secure',
          request: {
            ...getOptionsDispatch('merchant'),
          },
        },
      };

    default:
      return false;
  }
};

const initialState = {
  listViews: {},
  data: [],
  options: {
    users: [],
    clients: [],
    branchCodes: [],
    destinations: [],
    status: [],
    merchant: [],
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_OPTIONS_USER_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          users: action.payload.data,
        },
      };

    case REPORT_OPTIONS_CLIENT_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          clients: action.payload.data,
        },
      };

    case REPORT_OPTIONS_BRANCHCODE_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          branchCodes: action.payload.data,
        },
      };

    case REPORT_OPTIONS_DESTINATION_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          destinations: action.payload.data,
        },
      };

    case REPORT_OPTIONS_STATUS_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          status: action.payload.data,
        },
      };

    case REPORT_OPTIONS_MERCHANT_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          merchant: action.payload.data,
        },
      };

    case REPORTS_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data.rows,
      };

    case REPORTS_NOFILTER_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data.rows,
      };

    case UNREMITTED_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data.rows,
      };

    case UNREMITTED_NOFILTER_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data.rows,
      };

    case CASHIER_UNREMITTED_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data.rows,
      };

    case REPORT_PICKITEMS_VIEW_ACCOUNT_SUCCESS:
      return {
        ...state,
        listViews: action.payload.data,
      };

    default:
      return state;
  }
}

export default reducer;

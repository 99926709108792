import {
  apiFetchList,
  apiCreateVehicle,
  apiFetchOptionsLike,
  apiUpdateVehicle,
} from '../../services/apiEndpoints';

const FETCH_VEHICLE_LIST = 'admin/FETCH_VEHICLE_LIST';
export const fetchVehicleList = data => ({
  type: FETCH_VEHICLE_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchList('vehicle'),
      data,
    },
  },
});

const CREATE_VEHICLE = 'vehicle/CREATE_VEHICLE';
export const createVehicle = data => ({
  type: CREATE_VEHICLE,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateVehicle,
      data,
    },
  },
});

const FETCH_OPTIONS = 'vehicle/FETCH_OPTIONS';
export const fetchOptions = (option, value) => ({
  type: FETCH_OPTIONS,
  payload: {
    client: 'secure',
    request: {
      ...apiFetchOptionsLike(option, value),
    },
  },
});

const UPDATE_VEHICLE = 'vehicle/UPDATE_VEHICLE';
export const updateVehicle = (vehicleId, data) => ({
  type: UPDATE_VEHICLE,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateVehicle(vehicleId),
      data,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;

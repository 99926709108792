import React from 'react';
import { Card as AntCard } from 'antd';
import PropTypes from 'prop-types';

const Card = ({ children, title, extra }) => (
  <AntCard title={title} extra={extra}>
    {children}
  </AntCard>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Card.defaultProps = {
  extra: '',
};

export default Card;

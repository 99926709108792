import { acceptToWarehouseTnumber } from '../../services/api';

const ACCEPT_TO_WAREHOUSE = 'acceptToWarehouse/ACCEPT_TO_WAREHOUSE';
const ACCEPT_TO_WAREHOUSE_SUCCESS = 'acceptToWarehouse/ACCEPT_TO_WAREHOUSE_SUCCESS';
const ACCEPT_TO_WAREHOUSE_FAIL = 'acceptToWarehouse/ACCEPT_TO_WAREHOUSE_FAIL';

export const addtNumber = (type, tNumber) => ({
  type: ACCEPT_TO_WAREHOUSE,
  payload: {
    client: 'secure',
    request: {
      ...acceptToWarehouseTnumber(type, tNumber),
    },
  },
});

const ACCEPT_TO_WAREHOUSE_TABLE = 'acceptToWarehouse/ACCEPT_TO_WAREHOUSE_TABLE';
const ACCEPT_TO_WAREHOUSE_TABLE_SUCCESS = 'acceptToWarehouse/ACCEPT_TO_WAREHOUSE_TABLE_SUCCESS';

export const submitTableData = (type, data) => ({
  type: ACCEPT_TO_WAREHOUSE_TABLE,
  payload: {
    client: 'secure',
    request: {
      ...acceptToWarehouseTnumber(type, data),
    },
  },
});

const initialState = {
  data: [],
  tableData: {},
  errorReq: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ACCEPT_TO_WAREHOUSE_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };

    case ACCEPT_TO_WAREHOUSE_TABLE_SUCCESS:
      return {
        ...state,
        tableData: action.payload.data,
      };

    case ACCEPT_TO_WAREHOUSE_FAIL:
      return {
        ...state,
        errorReq: [...state.errorReq, action.error.response.data],
      };

    default:
      return state;
  }
}

export default reducer;

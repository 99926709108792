import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch, faFileExport } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Icon, Col, Form, Input, DatePicker,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Row from '../../components/Row';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';
import Select from '../../components/Select';
import ServerSideTable from '../../components/ServerSideTable';
import { fetchOptions, fetchOptionsLike } from '../ducks';
import { itemfetch, itemExport } from './ducks';

export class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      options: {
        client: [],
        destination: [],
      },
      itemList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      params: {
        page: 0,
        pageSize: 0,
        filter: {},
        sorted: [],
      },
      handoverDate: {},
      dateName: '',
      isActiveDate: true,
      isFetching: false,
      changeMessage: false,
    };

    this.onFetchData = this.onFetchData.bind(this);
  }

  componentDidMount() {
    const options = ['client', 'destination'];
    const { doFetchOptions } = this.props;

    Object.keys(options).forEach((key) => {
      doFetchOptions(options[key]).then((action) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            [options[key]]: action.payload.data.slice(0, 50),
          },
        }));
      });
    });
  }

  onFetchData(state, instance) {
    const { params: paramState, itemList } = this.state;
    const { doItemfetch } = this.props;

    const params = {
      page: state.page,
      pageSize: state.pageSize,
      filter: { ...paramState.filter },
      sorted: [
        {
          id: 'client',
          desc: false,
        },
      ],
    };

    this.setState({
      isLoading: true,
      params,
    });
    if (itemList.rows.length !== 0) {
      doItemfetch(params).then((action) => {
        // console.log(action);
        this.setState({
          itemList: {
            rows: action.payload.data.rows,
            pages: action.payload.data.pages,
            total: action.payload.data.total,
          },
          isLoading: false,
        });
      });
    }
  }

  formSubmit() {
    const { params: paramsState, handoverDate, dateName } = this.state;
    const { form, doItemfetch } = this.props;
    const { validateFields } = form;

    validateFields(['date_range', 'status', 'client', 'client', 'current_location', 'tracking_number', 'reference'], (err, values) => {
      const dateParams = dateName !== '' ? { [dateName]: handoverDate } : {};

      const params = {
        page: paramsState.page,
        pageSize: paramsState.pageSize,
        filter: { ...values, ...dateParams },
        sorted: paramsState.sorted,
      };

      if (!err) {
        // console.log(dateName);
        this.setState({
          isLoading: true,
        });
        doItemfetch(params).then((action) => {
          // console.log(action);
          this.setState({
            itemList: {
              rows: action.payload.data.rows,
              pages: action.payload.data.pages,
              total: action.payload.data.total,
            },
            isLoading: false,
            params,
            changeMessage: true,
          });
        });
      }
    });
  }

  submitHandlerExport() {
    this.setState({
      isLoading: true,
    });
    const { params: stateParams } = this.state;
    const { doItemExport } = this.props;
    const params = {
      page: stateParams.page,
      pageSize: stateParams.pageSize,
      filter: stateParams.filter,
      sorted: stateParams.sorted,
    };
    doItemExport(params).then((res) => {
      const newBlob = new Blob([res.payload.data]);
      fileDownload(newBlob, 'Item-list.csv');
      this.setState({
        isLoading: false,
      });
    });
  }

  dateReferenceChange(e) {
    if (e === undefined) {
      this.setState({
        isActiveDate: true,
        dateName: '',
      });
    } else {
      this.setState({
        dateName: e,
        isActiveDate: false,
      });
    }
  }

  labelChange(e, type) {
    // console.log(e, type);
    this.setState({
      isFetching: true,
    });
    const { doFetchOptionsLike } = this.props;

    doFetchOptionsLike(type, e).then((action) => {
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          [type]: action.payload.data.slice(0, 50),
        },
        isFetching: false,
      }));
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    const dateForm = `${formatDateOne}|${formatDateTwo}`;

    this.setState({
      handoverDate: formatDateOne !== 'Invalid Date' ? dateForm : '',
    });
  }

  render() {
    const {
      isLoading, options, itemList, isActiveDate, isFetching, dateName, changeMessage,
    } = this.state;

    const breadCrumbs = [
      {
        breadcrumbName: 'Items',
      },
      {
        breadcrumbName: 'Item List',
      },
    ];

    const statusOption = [
      {
        value: 'picked',
        label: 'Picked',
      },
      {
        value: 'accepted_to_warehouse',
        label: 'Accepted To Warehouse',
      },
      {
        value: 'forwarded_to_branch',
        label: 'Forwarded To Branch',
      },
      {
        value: 'failed_delivery',
        label: 'Failed Delivery',
      },
      {
        value: 'delivery_successful',
        label: 'Delivery Successful',
      },
      {
        value: 'partially_picked',
        label: 'Partially Picked',
      },
      {
        value: 'partially_delivered',
        label: 'Partially Delivered',
      },
      {
        value: 'returned',
        label: 'Returned',
      },
      {
        value: 'claims',
        label: 'Claims',
      },
      {
        value: 'void',
        label: 'Void',
      },
    ];

    const itemListColumns = [
      {
        Header: 'Tracking Number',
        sortable: false,
        filterable: false,
        accessor: 'tracking_number',
      },
      {
        Header: 'Reference',
        sortable: false,
        filterable: false,
        accessor: 'reference',
      },
      {
        Header: 'Description',
        sortable: false,
        filterable: false,
        accessor: 'description',
      },
      {
        Header: 'Status',
        sortable: false,
        filterable: false,
        accessor: 'status',
      },
      {
        Header: 'Client Name',
        sortable: false,
        filterable: false,
        accessor: 'client_name',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        Cell: row => (
          <center>
            <NavLink to={`/items/${row.original.reference}/${row.original.item_id}`}>
              <Button type="link">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faEye} fixedWidth />
                </Icon>
                view
              </Button>
            </NavLink>
          </center>
        ),
      },
    ];

    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className="ItemList">
        <PageHeader title="Item List" routes={breadCrumbs} />
        <Container>
          <Card>
            <Form>
              <Row>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Date Reference">
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      // loading={isFetching}
                      options={[
                        { value: 'created_at', label: 'Created at' },
                        { value: 'updated_at', label: 'Updated at' },
                      ]}
                      onChange={e => this.dateReferenceChange(e)}
                      filterOption={false}
                      placeholder="Enter Date Reference"
                      showSearch
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={16} lg={16}>
                  <Form.Item label="Date Range">
                    {getFieldDecorator('date_range', {
                      rules: [
                        {
                          required: dateName !== '',
                          message: dateName !== '' ? 'DateRange is required if DateReference is not null' : '',
                        },
                      ],
                    })(
                      <DatePicker.RangePicker
                        disabled={isActiveDate}
                        showTime={{
                          hideDisabledOptions: true,
                          defaultValue: [
                            moment('00:00', 'HH:mm'),
                            moment('00:00', 'HH:mm'),
                          ],
                        }}
                        style={{ width: '100%' }}
                        onChange={e => this.dateHandler(e)}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Status">
                    {getFieldDecorator('status', {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        style={{ width: '100%' }}
                        allowClear
                        // loading={isFetching}
                        options={statusOption}
                        // onChange={e => this.labelChange(e, '')}
                        filterOption={false}
                        placeholder="Enter Package Status"
                        showSearch
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Client">
                    {getFieldDecorator('client', {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        style={{ width: '100%' }}
                        allowClear
                        loading={isFetching}
                        options={options.client}
                        onSearch={e => this.labelChange(e, 'client')}
                        filterOption={false}
                        placeholder="Enter Client"
                        showSearch
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Location">
                    {getFieldDecorator('current_location', {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Select
                        style={{ width: '100%' }}
                        allowClear
                        loading={isFetching}
                        options={options.destination}
                        onSearch={e => this.labelChange(e, 'destination')}
                        filterOption={false}
                        placeholder="Enter Location"
                        showSearch
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Tracking Number">
                    {getFieldDecorator('tracking_number', {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Input.TextArea
                        placeholder="Enter Tracking Number..."
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Reference">
                    {getFieldDecorator('reference', {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Input.TextArea
                        placeholder="Enter Reference..."
                        autoComplete="off"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Spacer />
              <Button type="primary" onClick={() => this.formSubmit()} block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Icon>
                Filter
              </Button>
            </Form>
          </Card>
          <Spacer />
          <Card
            title={(
              <Button type="danger" onClick={() => this.submitHandlerExport()}>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faFileExport} fixedWidth />
                </Icon>
                Export
              </Button>
            )}
            extra={`Total: ${itemList.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <ServerSideTable
              data={itemList.rows}
              pages={itemList.pages}
              columns={itemListColumns}
              loadingText={changeMessage ? 'Fetching Item list...' : 'Please search item...'}
              loading={isLoading}
              onFetchData={this.onFetchData}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

ItemList.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  doFetchOptionsLike: PropTypes.func.isRequired,
  doItemfetch: PropTypes.func.isRequired,
  doItemExport: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchOptions: fetchOptions,
  doItemfetch: itemfetch,
  doFetchOptionsLike: fetchOptionsLike,
  doItemExport: itemExport,
};

const WrappedItemList = Form.create({ name: 'create' })(ItemList);
export default connect(null, mapDispatchToProps)(WrappedItemList);

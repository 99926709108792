import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  Button, message, Icon, Popconfirm,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';
import StatusTaggingForm from '../../components/StatusTaggingForm';
import { fetchTrackingNumber, updateTrackingNumberStatus } from '../ducks';

export class CustomerPickupAcceptedByCourier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingNumberRows: [],
      errors: [],
      success: [],
      isSubmitting: false,
    };

    this.validatePackage = this.validatePackage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
  }

  submitForm() {
    const { trackingNumberRows } = this.state;
    const { doUpdateTrackingNumberStatus } = this.props;

    this.setState({
      isSubmitting: true,
    }, () => {
      // eslint-disable-next-line no-unused-vars
      trackingNumberRows.forEach((item, key) => {
        const payload = {
          status_reference: item.status_reference,
          current_status: item.current_status,
          comment: item.comment,
          status: item.status,
          tracking_number: item.tracking_number,
        };

        doUpdateTrackingNumberStatus(payload, item.tracking_number).then((action) => {
          this.setState({
            isSubmitting: false,
            trackingNumberRows: [],
          });
          message.success(`Successfully updated ${item.tracking_number}`);
          this.setState(prevState => ({
            success: [
              ...prevState.success,
              {
                tracking_number: action.payload.data.tracking_number,
                address_city: action.payload.data.address_city,
                reference_code: action.payload.data.status_reference,
                status: action.payload.data.status,
              },
            ],
          }));
        }).catch(() => {
          message.error('Something went wrong, please try again later.');
        });
      });
    });
  }

  validatePackage(values) {
    const {
      trackingNumberRows,
    } = this.state;
    const { doFetchTrackingNumber } = this.props;

    const splitTrackingNumber = values.trackingNumber ? values.trackingNumber.split('\n') : '';
    const filteredTrackingNumber = splitTrackingNumber.filter(
      (item, key) => splitTrackingNumber.indexOf(item) >= key && item !== '',
    );
    // eslint-disable-next-line no-unused-vars
    filteredTrackingNumber.forEach((item, key) => {
      const exists = trackingNumberRows.filter(tn => tn.tracking_number === item);

      if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else {
        doFetchTrackingNumber('accepted_by_courier', item).then((action) => {
          this.setState(prevState => ({
            trackingNumberRows: [
              ...prevState.trackingNumberRows,
              {
                tracking_number: action.payload.data.tracking_number,
                current_status: action.payload.data.status,
                status: 'accepted_by_courier',
                status_reference: action.payload.data.status_reference,
                comment: values.comment,
              },
            ],
          }));
          message.success('Successfully added tracking number!.');
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 400) {
            errorMessage = action.error.response.data.message;
            this.setState(prevState => ({
              errors: [
                ...prevState.errors,
                {
                  tracking_number: item,
                  message: action.error.response.data.message,
                },
              ],
            }));
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  confirmRemove(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber,
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber,
    });

    message.success('Successfully removed tracking number!');
  }

  render() {
    const { trackingNumberRows, errors, success, isSubmitting } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Customer Pickup',
      },
      {
        breadcrumbName: 'Accepted By Courier',
      },
    ];

    const trackingNumberRowColumns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Current Status',
        accessor: 'current_status',
      },
      {
        Header: 'Target Status',
        accessor: 'status',
      },
      {
        Header: 'Reference',
        accessor: 'status_reference',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        Cell: data => (
          <React.Fragment>
            <Popconfirm
              placement="leftBottom"
              title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
              okText="Yes"
              onConfirm={() => this.confirmRemove(data.row.tracking_number)}
              cancelText="No"
            >
              <Button type="link" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                </Icon>
          Remove
              </Button>
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    return (
      <div className="CustomerPickupAcceptedByCourier">
        <PageHeader title="Customer Pickup" routes={breadCrumbs} />
        <Spacer />
        <Container>
          <StatusTaggingForm
            inputDisabled={isSubmitting}
            hasDatePicker={false}
            validateHandler={this.validatePackage}
            submitHandler={this.submitForm}
            trackingNumberList={trackingNumberRows}
            trackingNumberListColumns={trackingNumberRowColumns}
            errorList={errors}
            successList={success}
          />
        </Container>
      </div>

    );
  }
}

CustomerPickupAcceptedByCourier.propTypes = {
  doFetchTrackingNumber: PropTypes.func.isRequired,
  doUpdateTrackingNumberStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchTrackingNumber: fetchTrackingNumber,
  doUpdateTrackingNumberStatus: updateTrackingNumberStatus,
};

export default connect(null, mapDispatchToProps)(CustomerPickupAcceptedByCourier);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Col, Form, Button, Upload, Icon, Divider, Descriptions, Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleRight, faArrowLeft, faPrint,
} from '@fortawesome/free-solid-svg-icons';

import { rubixpickOptions } from '../../components/options/ducks';
import { crImport, uploadCashier, getTemplate, uploadCashierRemittance } from './ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import { ReactSelect } from '../../components/ReactSelect';
import ReactTable from '../../components/ReactTable';

export class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destination: [],
      resPayloadUpload: {
        details: [],
        errors: [],
        branch: null,
        expected_amount: 0,
        total_amount: 0,
      },
    };
  }

  componentDidMount() {
    const { doRubixpickOptions } = this.props;
    doRubixpickOptions('destination').then(res => this.setState({
      destination: res.payload.data,
    }));
  }

  backUpload() {
    this.setState({
      resPayloadUpload: {
        details: [],
        errors: [],
        branch: null,
        expected_amount: 0,
        total_amount: 0,
      },
    });
  }

  sumbitHandler() {
    const { form, doUploadCashier } = this.props;
    const { validateFields, setFieldsValue } = form;
    validateFields((err, values) => {
      if (!err) {
        const bodyFormData = new FormData();
        bodyFormData.append('branch_code', values.selectPortCode.value);
        bodyFormData.append('file', values.remittanceFile.fileList[0].originFileObj);
        doUploadCashier('xde-remittance', bodyFormData).then((res) => {
          setFieldsValue({
            selectPortCode: null,
            uploadB: null,
          });
          this.setState({
            resPayloadUpload: res.payload.data,
          });
        });
      }
    });
  }

  continueUpload() {
    const { resPayloadUpload } = this.state;
    const { doUploadConfirmation, history } = this.props;
    doUploadConfirmation('xde-remittance', resPayloadUpload).then(res => history.push(`/cashier-remittance/details/${res.payload.data.id}`));
  }

  render() {
    const { destination, resPayloadUpload } = this.state;
    const { Title } = Typography;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Cashier Remittance',
      },
      {
        breadcrumbName: 'New',
      },
    ];
    return (
      <div className="New">
        <PageHeader title="NEW REMITTANCE" routes={breadCrumbs} />
        <Container>
          <Card>
            <Button
              type="primary"
              shape="round"
              onClick={() => window.open('http://xdelogistics.com/api/templates/remittance_upload_template.xlsx')
              }
            >
              Get Template
            </Button>
            <Divider />
            <Descriptions title="This feature is used to mark the tracking numbers as Remitted in the system.">
              <Descriptions.Item>
                1. Download the Template and edit the file by entering the correct values.
                <br />
                2.Save the file, then select your branch and upload the saved file by clicking Next.
                <br />
                3.Confirm your report and then click Save.
              </Descriptions.Item>
            </Descriptions>
            {(() => {
              switch (resPayloadUpload.branch) {
                case null:
                  return (
                    <Card>
                      <Form>
                        <Form.Item label="Select Port Code">
                          {getFieldDecorator('selectPortCode', {
                            rules: [{ required: true, message: 'Please insert value' }],
                          })(<ReactSelect options={destination} />)}
                        </Form.Item>
                        <Form.Item label="Remittance File" beforeUpload={() => false}>
                          {getFieldDecorator('remittanceFile', {
                            rules: [{ required: true, message: 'Please insert value' }],
                          })(
                            <Upload.Dragger
                              beforeUpload={() => false}
                              multiple={false}
                              accept=".csv, .xlsx"
                            >
                              <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                              </p>
                              <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>,
                          )}
                        </Form.Item>
                        <Button onClick={e => this.sumbitHandler(e)} type="primary" block>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faPrint} fixedWidth />
                          </Icon>
                          Upload
                        </Button>
                      </Form>
                    </Card>
                  );
                default:
                  return (
                    <React.Fragment>
                      <Divider />
                      <Title level={2}>Confirm Upload</Title>
                      <div>
                        <Row>
                          <Col xs={12} sm={12} lg={12}>
                            <strong>Total Records: </strong>
                            {resPayloadUpload.details.length + resPayloadUpload.errors.length}
                          </Col>
                          <Col xs={12} sm={12} lg={12}>
                            <strong>Total Payment: </strong>
                            {resPayloadUpload.total_amount}
                          </Col>
                        </Row>
                      </div>
                      <Card
                        title={`Upload Billing: ${
                          resPayloadUpload.details ? resPayloadUpload.details.length : 0
                        } records`}
                      >
                        <ReactTable
                          data={resPayloadUpload.details}
                          columns={[
                            {
                              Header: 'Tracking Number',
                              accessor: 'tracking_number',
                            },
                            {
                              Header: 'Expected Amount',
                              accessor: 'expected_amount',
                            },
                            {
                              Header: 'Amount Collected',
                              accessor: 'amount_already_collected',
                            },
                            {
                              Header: 'Amount Deposited',
                              accessor: 'amount',
                            },
                            {
                              Header: 'Bank',
                              accessor: 'bank',
                            },
                            {
                              Header: 'Reference No.',
                              accessor: 'deposit_slip',
                            },
                            {
                              Header: 'Date Deposited',
                              accessor: 'deposited_at',
                            },
                            {
                              Header: 'Remarks',
                              accessor: 'remarks',
                            },
                          ]}
                        />
                      </Card>
                      <Card
                        title={`Invalid: ${
                          resPayloadUpload.errors ? resPayloadUpload.errors.length : 0
                        } records`}
                      >
                        <ReactTable
                          data={resPayloadUpload.errors}
                          columns={[
                            {
                              Header: 'Tracking Number',
                              accessor: 'tracking_number',
                            },
                            {
                              Header: 'Amount Collected',
                              accessor: 'amount_already_collected',
                            },
                            {
                              Header: 'Remarks',
                              accessor: 'remarks',
                            },
                            {
                              Header: 'Error',
                              accessor: 'message',
                            },
                          ]}
                        />
                      </Card>
                      <Divider />
                      <Row>
                        <Col xs={24} sm={12} lg={12}>
                          <Button onClick={() => this.backUpload()} type="danger" block>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faArrowLeft} fixedWidth />
                            </Icon>
                            Back
                          </Button>
                        </Col>
                        <Col xs={24} sm={12} lg={12}>
                          <Button onClick={() => this.continueUpload()} type="primary" block>
                            Continue
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faArrowAltCircleRight} fixedWidth />
                            </Icon>
                          </Button>
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
              }
            })()}
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  options: state.options.options,
});

const mapDispatchToProps = {
  newImport: crImport,
  doRubixpickOptions: rubixpickOptions,
  doUploadCashier: uploadCashier,
  doGetTemplate: getTemplate,
  doUploadConfirmation: uploadCashierRemittance,
};

New.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doRubixpickOptions: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doUploadCashier: PropTypes.func.isRequired,
  doUploadConfirmation: PropTypes.func.isRequired,
};

const WrappedNew = Form.create({ name: 'Register' })(New);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedNew);

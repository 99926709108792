import React, { Component } from 'react';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import DispatchList from '../../components/DispatchList';

class MerchantPickupTransmittalList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Merchant Pickup',
      },
      {
        breadcrumbName: 'List',
      },
    ];

    const dispatchListColumns = [
      {
        Header: 'Transmittal ID',
        accessor: 'id',
      },
      {
        Header: 'From',
        accessor: 'from',
      },
      {
        Header: 'To',
        accessor: 'to',
      },
      {
        Header: 'Via',
        accessor: 'via',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
      },
    ];

    const customFilter = {};

    return (
      <div className="MerchantPickupTransmittalList">
        <PageHeader title="Transmittal" routes={breadCrumbs} />
        <Container>
          <DispatchList
            hasExport={false}
            dispatchListColumns={dispatchListColumns}
            customFilter={customFilter}
            fetchText="Fetching Merchant Pickup List. . ."
            urlEndpoint="/merchant-pickup/transmittal"
            isTransmittal
            hasOnlyView={false}
          />
        </Container>
      </div>
    );
  }
}

export default MerchantPickupTransmittalList;

export async function store(key, data) {
  try {
    await window.localStorage.setItem(key, data);
    return true;
  } catch (e) {
    return false;
  }
}

export async function storeJSON(key, data) {
  try {
    const serialized = JSON.stringify(data);
    await window.localStorage.setItem(key, serialized);
    return true;
  } catch (e) {
    return false;
  }
}

export async function get(key) {
  try {
    return await window.localStorage.getItem(key);
  } catch (error) {
    return false;
  }
}

export async function getJSON(key) {
  return JSON.parse(await get(key));
}

export async function remove(key) {
  try {
    await window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}

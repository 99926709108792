import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
 DatePicker, Col, Form, Button, Input, Icon 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import {
  fetchCity,
  fetchOptions,
  massUploadData,
  getOptionsProvCity,
} from '../waybill/ducks';
import { waybill } from '../../components/waybill/ducks';
import {
  reportList,
  reportNoFilterList,
  reportPickOptions,
  fetchOptions as optionSearchSelect,
} from './ducks';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactSelect from '../../components/Select';
import Spacer from '../../components/Spacer';

export class ItemsForPickup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFilter: false,
      handoverDate: {},
      dateConfirmation: false,
      loading: false,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      optionState: {
        user: [],
        client: [],
        branch_code: [],
        merchant: [],
        province: [],
        city: [],
      },
      payloadReq: {
        page: 0,
        pageSize: 0,
        filter: [],
        sorted: [],
      },
      tableFilter: [],
      searchFilter: [],
      isFetchingSelect: false,
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
    this.provinceCityHandler = this.provinceCityHandler.bind(this);
  }

  componentDidMount() {
    const { doCallreportPickOptions, dofetchOptionsSelect } = this.props;
    doCallreportPickOptions('user').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          user: res.payload.data.slice(0, 50),
        },
      })),);
    doCallreportPickOptions('client').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          client: res.payload.data.slice(0, 50),
        },
      })),);
    doCallreportPickOptions('branch_code').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          branchCode: res.payload.data.slice(0, 50),
        },
      })),);
    doCallreportPickOptions('merchant').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          merchant: res.payload.data.slice(0, 50),
        },
      })),);
    dofetchOptionsSelect('province').then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          province: res.payload.data.slice(0, 50),
        },
      })),);
  }

  onFetchDataFunc(state, instance) {
    const { searchFilter, hasFilter } = this.state;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };

    // console.log(state);
    const { ItemsForPickupNoFilterList } = this.props;
    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    this.setState({
      payloadReq: paramsReq,
    });

    if (hasFilter) {
      this.setState({
        loading: true,
      });
      ItemsForPickupNoFilterList('package', paramsReq).then(res => this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        tableFilter: state.filtered,
      }));
    }
  }

  getEditAccountHandler(e) {
    const { waybillComponent, history } = this.props;
    waybillComponent(e.tracking_number).then(() => history.push(`/waybill/${e.tracking_number}`),);
    // console.log(e);
  }

  searchFilter(value, name) {
    this.setState({
      isFetchingSelect: true,
    });
    const { doFetchOptions } = this.props;
    doFetchOptions(name, value).then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          [name]: res.payload.data.slice(0, 50),
        },
        isFetchingSelect: false,
      })),);
  }

  searchFilterProvince(type, e) {
    this.setState({
      isFetchingSelect: true,
    });
    const { dogetOptionsProvCity } = this.props;
    dogetOptionsProvCity(type, e).then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          [type]: res.payload.data.slice(0, 50),
        },
        isFetchingSelect: false,
      })),);
  }

  provinceCityHandler(e) {
    const { dofetchCity } = this.props;
    dofetchCity(e).then(res => this.setState(prevState => ({
        optionState: {
          ...prevState.optionState,
          city: res.payload.data,
        },
      })),);
  }

  submitHandler() {
    // ItemsForPickupList('', 'package');
    const {
      payloadReq,
      handoverDate,
      tableFilter,
      dateConfirmation,
    } = this.state;
    const { ItemsForPickupNoFilterList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        const paramsReq = {
          export: false,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filtered: [
            values.user
              ? { id: 'created_by', value: values.user }
              : { id: 'created_by' },
            values.client
              ? { id: 'client', value: values.client }
              : { id: 'client' },
            values.province
              ? { id: 'address_province', value: values.province }
              : { id: 'address_province' },
            values.city
              ? { id: 'address_city', value: values.city }
              : { id: 'address_city' },
            values.branchCode
              ? { id: 'branch_code', value: values.branchCode }
              : { id: 'branch_code' },
            values.merchant
              ? { id: 'shipper_id', value: values.merchant }
              : { id: 'shipper_id' },
            values.packageSize
              ? { id: 'package_size', value: values.packageSize }
              : { id: 'package_size' },
            { id: 'status', value: 'for_pickup' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number', value: '' },
            dateConfirmation ? handoverDate : {},
          ],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filtered = paramsReq.filtered.concat(tableFilter);
        }
        // ItemsForPickupNoFilterList('package', paramsReq).then(res => this.setState({
        //     loading: false,
        //     userData: {
        //       rows: res.payload.data.rows,
        //       pages: res.payload.data.pages,
        //       total: res.payload.data.total,
        //     },
        //     payloadReq: paramsReq,
        //     searchFilter: [
        //       values.user
        //         ? { id: 'created_by', value: values.user }
        //         : { id: 'created_by' },
        //       values.client
        //         ? { id: 'client', value: values.client }
        //         : { id: 'client' },
        //       values.province
        //         ? { id: 'address_province', value: values.province }
        //         : { id: 'address_province' },
        //       values.city
        //         ? { id: 'address_city', value: values.city }
        //         : { id: 'address_city' },
        //       values.branchCode
        //         ? { id: 'branch_code', value: values.branchCode }
        //         : { id: 'branch_code' },
        //       values.merchant
        //         ? { id: 'shipper_id', value: values.merchant }
        //         : { id: 'shipper_id' },
        //       values.packageSize
        //         ? { id: 'package_size', value: values.packageSize }
        //         : { id: 'package_size' },
        //       { id: 'status', value: 'for_pickup' },
        //       values.tNumber
        //         ? { id: 'tracking_number', value: values.tNumber }
        //         : { id: 'tracking_number', value: '' },
        //       dateConfirmation ? handoverDate : {},
        //     ],
        //   }),);
        this.setState({
          hasFilter: true,
        }, () => this.onFetchDataFunc(paramsReq));
      }
    });
  }

  submitHandlerExport() {
    // ItemsForPickupList('', 'package');
    const {
      payloadReq,
      handoverDate,
      dateConfirmation,
      tableFilter,
    } = this.state;
    const { ItemsForPickupNoFilterList, form } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        const paramsReq = {
          export: true,
          page: payloadReq.page,
          pageSize: payloadReq.pageSize,
          filter: [
            values.user
              ? { id: 'created_by', value: values.user }
              : { id: 'created_by' },
            values.client
              ? { id: 'client', value: values.client }
              : { id: 'client' },
            values.province
              ? { id: 'address_province', value: values.province }
              : { id: 'address_province' },
            values.city
              ? { id: 'address_city', value: values.city }
              : { id: 'address_city' },
            values.branchCode
              ? { id: 'branch_code', value: values.branchCode }
              : { id: 'branch_code' },
            values.merchant
              ? { id: 'shipper_id', value: values.merchant }
              : { id: 'shipper_id' },
            values.packageSize
              ? { id: 'package_size', value: values.packageSize }
              : { id: 'package_size' },
            { id: 'status', value: 'for_pickup' },
            values.tNumber
              ? { id: 'tracking_number', value: values.tNumber }
              : { id: 'tracking_number', value: '' },
            dateConfirmation ? handoverDate : {},
          ],
          sorted: payloadReq.sorted,
        };
        if (tableFilter.length) {
          paramsReq.filter = paramsReq.filter.concat(tableFilter);
        }
        ItemsForPickupNoFilterList('package', paramsReq).then((res) => {
          const newBlob = new Blob([res.payload.data]);
          fileDownload(newBlob, 'Items-for-pickup.csv');
          this.setState({
            loading: false,
          });
        });
      }
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    const dateForm = `${formatDateOne}|${formatDateTwo}`;

    this.setState({
      handoverDate:
        dateForm !== 'Invalid Date|Invalid Date'
          ? { id: 'created_at', value: dateForm }
          : { id: 'created_at' },
      dateConfirmation: true,
    });
  }

  render() {
    const {
 loading, userData, optionState, isFetchingSelect, hasFilter,
} = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const breadCrumbs = [
      {
        breadcrumbName: 'Report',
      },
      {
        breadcrumbName: 'For Pickup',
      },
    ];
    return (
      <div className="ItemsForPickup">
        <PageHeader title="ITEMS FOR PICKUP" routes={breadCrumbs} />
        <Container>
          <Form>
            <Card>
              <Row>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Created By">
                    {getFieldDecorator('user', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        allowClear
                        showSearch
                        loading={isFetchingSelect}
                        filterOption={false}
                        onSearch={e => this.searchFilter(e, 'user')}
                        style={{ width: '100%' }}
                        placeholder="Select Users..."
                        options={optionState.user}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Client">
                    {getFieldDecorator('client', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        allowClear
                        showSearch
                        loading={isFetchingSelect}
                        filterOption={false}
                        onSearch={e => this.searchFilter(e, 'client')}
                        style={{ width: '100%' }}
                        placeholder="Select Client..."
                        options={optionState.client}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Branch Code">
                    {getFieldDecorator('branchCode', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        allowClear
                        showSearch
                        loading={isFetchingSelect}
                        filterOption={false}
                        onSearch={e => this.searchFilter(e, 'branch_code')}
                        style={{ width: '100%' }}
                        placeholder="Select Branch Code..."
                        options={optionState.branch_code}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Packages Size">
                    {getFieldDecorator('packageSize', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        style={{ width: '100%' }}
                        placeholder="Select Package Size..."
                        options={[
                          { value: 'Bulky', label: 'Bulky' },
                          { value: 'Pouches', label: 'Pouches' },
                        ]}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Province">
                    {getFieldDecorator('province', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                      onChange: this.provinceCityHandler,
                    })(<Input placeholder="Select Province..." />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="City">
                    {getFieldDecorator('city', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(<Input placeholder="Select Province..." />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={8} lg={8}>
                  <Form.Item label="Merchant">
                    {getFieldDecorator('merchant', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(
                      <ReactSelect
                        allowClear
                        showSearch
                        loading={isFetchingSelect}
                        filterOption={false}
                        onSearch={e => this.searchFilter(e, 'merchant')}
                        style={{ width: '100%' }}
                        placeholder="Select Merchant..."
                        options={optionState.merchant}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={16} lg={16}>
                  <Form.Item label="Date Range">
                    <DatePicker.RangePicker
                      onChange={e => this.dateHandler(e)}
                      style={{ width: '100%' }}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment('00:00', 'HH:mm'),
                          moment('00:00', 'HH:mm'),
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} lg={24}>
                  <Form.Item label="Tracking Number">
                    {getFieldDecorator('tNumber', {
                      rules: [
                        { required: false, message: 'Please insert value' },
                      ],
                    })(<Input.TextArea />)}
                  </Form.Item>
                </Col>
              </Row>
              <Button onClick={() => this.submitHandler()} type="primary" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Icon>
                Filter
              </Button>
            </Card>
            <Spacer />
            <Card
              title={(
<Button
                  onClick={() => this.submitHandlerExport()}
                  type="danger"
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faFileExport} fixedWidth />
                  </Icon>
                  Export
                </Button>
)}
              extra={`Total: ${userData.total
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            >
              <ReactTable
                data={userData.rows}
                pages={userData.pages}
                columns={[
                  {
                    Header: 'Created Date',
                    accessor: 'created_at',
                  },
                  {
                    Header: 'Client',
                    accessor: 'client_name',
                  },
                  {
                    Header: 'Waybill Number',
                    accessor: 'tracking_number',
                  },
                  {
                    Header: 'Consignee Name',
                    accessor: 'consignee_name',
                  },
                  {
                    Header: 'City',
                    accessor: 'address_city',
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                      <center>
                        <Button
                          type="link"
                          onClick={() => this.getEditAccountHandler(row.original)
                          }
                        >
                          <Icon type="eye" />
                          View
                        </Button>
                      </center>
                    ),
                  },
                ]}
                loadingText={!hasFilter ? 'Use the filters to search for Items for Pickup' : 'Fetching Items for Pickup. . .'}
                loading={loading}
                onFetchData={this.onFetchDataFunc}
              />
            </Card>
          </Form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reportListTable: state.report.data,
  options: state.report.options,
});

const mapDispatchToProps = {
  ItemsForPickupList: reportList,
  ItemsForPickupNoFilterList: reportNoFilterList,
  waybillComponent: waybill,
  doCallreportPickOptions: reportPickOptions,
  dofetchCity: fetchCity,
  dofetchOptionsSelect: fetchOptions,
  doMassUploadData: massUploadData,
  doFetchOptions: optionSearchSelect,
  dogetOptionsProvCity: getOptionsProvCity,
};

ItemsForPickup.propTypes = {
  ItemsForPickupNoFilterList: PropTypes.func.isRequired,
  waybillComponent: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doCallreportPickOptions: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dofetchCity: PropTypes.func.isRequired,
  dofetchOptionsSelect: PropTypes.func.isRequired,
  doFetchOptions: PropTypes.func.isRequired,
  dogetOptionsProvCity: PropTypes.func.isRequired,
};

const WrappedItemsForPickup = Form.create({ name: 'Register' })(ItemsForPickup);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedItemsForPickup);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  Button, message, Icon, Popconfirm,
} from 'antd';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import Select from '../../components/Select';
import StatusTaggingForm from '../../components/StatusTaggingForm';
import { fetchTrackingNumber, updateTrackingNumberStatus } from '../ducks';

export class Repush extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taggingType: 'resend_delivered',
      trackingNumberRows: [],
      errors: [],
      success: [],
    };

    this.setTaggingTypeState = this.setTaggingTypeState.bind(this);
    this.validatePackage = this.validatePackage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
  }

  setTaggingTypeState(e) {
    this.setState({
      taggingType: e,
    });
  }

  submitForm() {
    const { trackingNumberRows } = this.state;
    const { doUpdateTrackingNumberStatus } = this.props;

    // eslint-disable-next-line no-unused-vars
    trackingNumberRows.forEach((item, key) => {
      const payload = {
        status_reference: item.status_reference,
        current_status: item.current_status,
        comment: item.comment,
        status: item.status,
        tracking_number: item.tracking_number,
      };

      doUpdateTrackingNumberStatus(payload, item.tracking_number)
        .then(() => {
          this.setState(prevState => ({
            trackingNumberRows: [],
            success: [
              ...prevState.success,
              { tracking_number: item.tracking_number },
            ],
          }));
          message.success(`Successfully submitted ${item.tracking_number}`);
        })
        .catch(() => {
          message.error('Something went wrong, please try again later.');
        });
    });
  }

  validatePackage(values) {
    const { taggingType, trackingNumberRows } = this.state;
    const { doFetchTrackingNumber } = this.props;

    const splitTrackingNumber = values.trackingNumber
      ? values.trackingNumber.split('\n')
      : '';
    const filteredTrackingNumber = splitTrackingNumber.filter(tn => tn !== '');

    // eslint-disable-next-line no-unused-vars
    filteredTrackingNumber.forEach((item, key) => {
      const exists = trackingNumberRows.filter(
        tn => tn.tracking_number === item,
      );

      if (exists.length > 0) {
        message.error(`Tracking number ${item} is already entered.`);
        this.setState(prevState => ({
          errors: [
            ...prevState.errors,
            {
              tracking_number: item,
              message: 'Tracking number is already entered.',
            },
          ],
        }));
      } else {
        doFetchTrackingNumber(taggingType, item)
          .then((action) => {
            this.setState(prevState => ({
              trackingNumberRows: [
                ...prevState.trackingNumberRows,
                {
                  tracking_number: action.payload.data.tracking_number,
                  current_status: action.payload.data.status,
                  status: taggingType,
                  status_reference: action.payload.data.status_reference,
                  comment: values.comment,
                },
              ],
            }));
            message.success('Successfully added tracking number!.');
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 400) {
              errorMessage = action.error.response.data.message;
              this.setState(prevState => ({
                errors: [
                  ...prevState.errors,
                  {
                    tracking_number: item,
                    message: action.error.response.data.message,
                  },
                ],
              }));
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  confirmRemove(trackingNumber) {
    const { trackingNumberRows } = this.state;
    const filteredTrackingNumber = trackingNumberRows.filter(
      data => data.tracking_number !== trackingNumber,
    );

    this.setState({
      trackingNumberRows: filteredTrackingNumber,
    });

    message.success('Successfully removed tracking number!');
  }

  render() {
    const { trackingNumberRows, errors, success } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Admin',
      },
      {
        breadcrumbName: 'Repush',
      },
    ];

    const repushOptions = [
      {
        label: 'Delivered',
        value: 'resend_delivered',
      },
      {
        label: 'Failed',
        value: 'resend_failed_delivery',
      },
      {
        label: 'Returned',
        value: 'resend_returned',
      },
    ];

    const trackingNumberRowColumns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Current Status',
        accessor: 'current_status',
      },
      {
        Header: 'Target Status',
        accessor: 'status',
      },
      {
        Header: 'Reference',
        accessor: 'status_reference',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
      {
        Header: 'Options',
        sortable: false,
        filterable: false,
        Cell: data => (
          <React.Fragment>
            <Popconfirm
              placement="leftBottom"
              title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
              okText="Yes"
              onConfirm={() => this.confirmRemove(data.row.tracking_number)}
              cancelText="No"
            >
              <Button type="link" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                </Icon>
                Remove
              </Button>
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    return (
      <div className="Repush">
        <PageHeader title="Repush" routes={breadCrumbs} />
        <Container>
          <StatusTaggingForm
            hasDatePicker={false}
            validateHandler={this.validatePackage}
            submitHandler={this.submitForm}
            trackingNumberList={trackingNumberRows}
            trackingNumberListColumns={trackingNumberRowColumns}
            errorList={errors}
            successList={success}
            successColumns={[
              {
                Header: 'Tracking Number',
                accessor: 'tracking_number',
              },
            ]}
            cardExtra={(
              <React.Fragment>
                <span>Select repush type: </span>
                <Select
                  defaultValue="resend_delivered"
                  options={repushOptions}
                  onChange={this.setTaggingTypeState}
                  disabled={trackingNumberRows.length > 0}
                />
              </React.Fragment>
            )}
          />
        </Container>
      </div>
    );
  }
}

Repush.propTypes = {
  doFetchTrackingNumber: PropTypes.func.isRequired,
  doUpdateTrackingNumberStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchTrackingNumber: fetchTrackingNumber,
  doUpdateTrackingNumberStatus: updateTrackingNumberStatus,
};

export default connect(null, mapDispatchToProps)(Repush);

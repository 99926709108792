import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchDispatchList } from '../../pages/ducks';

export class DispatchSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { doFetchDispatchList, match, history } = this.props;
    const dispatchID = {
      export: false,
      filter: [{ id: 'dh_id', value: match.params.idNumber }],
      page: 0,
      pageSize: 10,
      sorted: [],
    };
    doFetchDispatchList(dispatchID).then((res) => {
      console.log(res);
      const dataType =        res.payload.data.rows.length !== 0
          ? res.payload.data.rows[0].type
          : 'noDataType';

      if (dataType === 'delivery') {
        history.push(`/delivery/${match.params.idNumber}`);
      } else if (dataType === 'rts') {
        history.push(`/rts/${match.params.idNumber}`);
      } else if (dataType === 'rtm') {
        history.push(`/rtm/${match.params.idNumber}`);
      } else if (dataType === 'pickup') {
        history.push(`/pickup/${match.params.idNumber}`);
      } else if (dataType === 'transfer') {
        history.push(`/transfer/${match.params.idNumber}`);
      } else if (dataType === 'noDataType') {
        history.push('/404');
      }
    });
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  doFetchDispatchList: fetchDispatchList,
};

DispatchSearch.propTypes = {
  doFetchDispatchList: PropTypes.func.isRequired,
  match: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DispatchSearch);

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import rootReducer, { history } from './reducer';
import { apiClients } from '../services/api';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), multiClientMiddleware(apiClients), sagaMiddleware),
  ),
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

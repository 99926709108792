import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Col } from 'antd';
import PropTypes from 'prop-types';

import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ReactTable';
import Card from '../../components/Card';
import Container from '../../components/Container';

export class ListViews extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { viewAccount } = this.props;
    const { Paragraph, Text } = Typography;
    const breadCrumbs = [
      {
        breadcrumbName: 'Billing',
      },
      {
        breadcrumbName: 'List',
      },
      {
        breadcrumbName: 'List View',
      },
    ];

    return (
      <div className="ListView">
        <PageHeader title="VIEW BILLING" routes={breadCrumbs} />
        <Container>
          <Card>
            <Row>
              <Col xs={24} sm={24} lg={12}>
                <span>
                  <Paragraph>
                    <Text strong>Billing ID: </Text>
                    <br />
                    {viewAccount.id}
                  </Paragraph>
                  <Paragraph>
                    <Text strong>Total Payment: </Text>
                    <br />
                    {viewAccount.total_payment}
                  </Paragraph>
                </span>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <span>
                  <Paragraph>
                    <Text strong>Date Created: </Text>
                    <br />
                    {viewAccount.created_at}
                  </Paragraph>
                  <Paragraph>
                    <Text strong>Created By: </Text>
                    <br />
                    {viewAccount.creator.firstname}
                    {' '}
                    {viewAccount.creator.lastname}
                  </Paragraph>
                </span>
              </Col>
            </Row>
          </Card>
          <Card title="Billing Details">
            <ReactTable
              data={viewAccount.details}
              columns={[
                {
                  Header: 'Tracking Number',
                  accessor: 'tracking_number',
                },
                {
                  Header: 'Total Payment',
                  accessor: 'total_payment',
                },
                {
                  Header: 'Invoice Number',
                  accessor: 'invoice_number',
                },
                {
                  Header: 'Invoice Date',
                  accessor: 'invoice_date',
                },
              ]}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  viewAccount: state.billing.views,
});

const mapDispatchToProps = {};

ListViews.propTypes = {
  viewAccount: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListViews);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, Col, Row, Button, message,
} from 'antd';
import Container from '../../../components/Container';
import ReactTable from '../../../components/ReactTable';
import Spacer from '../../../components/Spacer';
import { massUpdateUploadRequest } from '../ducks';

function PortClusterMassUpdate({
  fileContentPreview,
  currentStep,
  setCurrentStep,
  setIsUpdating,
  isUpdating,
  setSpinText,
  ...props
}) {
  function HandleFormSubmit(data) {
    const { doMassUpdateUploadRequest } = props;

    setSpinText('Uploading excel file...');

    if (data.length === 0) {
      message.error('There are no tracking numbers to be updated!');
      return false;
    }
    setIsUpdating(true);


    doMassUpdateUploadRequest(data).then(() => {
      setCurrentStep(2);
      setIsUpdating(false);
    }).catch(() => {
      setCurrentStep(0);
      setIsUpdating(false);
    });
  }

  return (
    <Container>
      <Card title={`Success (${fileContentPreview.total_success})`}>
        <ReactTable
          data={fileContentPreview.success}
          columns={[
            {
              Header: 'Port Cluster ID',
              accessor: 'id',
            },
            {
              Header: 'Province',
              accessor: 'province',
            },
            {
              Header: 'City',
              accessor: 'city',
            },
            {
              Header: 'Barangay',
              accessor: 'barangay',
            },
            {
              Header: 'Route Code',
              accessor: 'route_code',
            },
            {
              Header: 'Branch',
              accessor: 'branch',
            },
            {
              Header: 'Cluster',
              accessor: 'cluster',
            },
          ]}
          minRows={0}
        />
      </Card>
      <Spacer />
      <Card title={`Errors (${fileContentPreview.total_errors})`}>
        <ReactTable
          data={fileContentPreview.errors}
          columns={[
            {
              Header: 'Row Number',
              accessor: 'row_number',
            },
            {
              Header: 'Message',
              accessor: 'error',
            },
          ]}
          minRows={0}
        />

      </Card>
      <Spacer />
      <Row gutter={5}>
        <Col>
          <Button
            type="primary"
            onClick={() => { HandleFormSubmit(fileContentPreview.success); }}
            style={{ float: 'right' }}
            disabled={isUpdating || fileContentPreview.success.length === 0}
          >
            {isUpdating ? '...Uploading' : 'Confirm'}
          </Button>
        </Col>
        <Col>
          <Button onClick={() => { setCurrentStep(0); }} type="primary" style={{ float: 'right', marginRight: '5px' }} disabled={isUpdating}>
              Back
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

PortClusterMassUpdate.propTypes = {
  fileContentPreview: PropTypes.oneOfType([PropTypes.object]).isRequired,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setSpinText: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  setIsUpdating: PropTypes.func.isRequired,
  doMassUpdateUploadRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doMassUpdateUploadRequest: massUpdateUploadRequest,
};

export default connect(null, mapDispatchToProps)(PortClusterMassUpdate);

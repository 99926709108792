import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Descriptions, Spin } from 'antd';

import ReactTable from '../../components/ReactTable';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';
import Container from '../../components/Container';

import { billingViews } from '../ducks';

export class ViewRemittanceUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadSpin: false,
      uploadRes: {
        branch: null,
        expected_amount: 0,
        total_amount: 0,
        variance: 0,
        created_at: null,
        creator: {
          firstname: null,
          lastname: null,
        },
        remarks: null,
        details: [],
      },
    };
  }

  componentDidMount() {
    this.setState({ loadSpin: true });
    const { doBillingViews, match } = this.props;
    doBillingViews('xde-remittance', match.params.id).then(res => this.setState({ uploadRes: res.payload.data, loadSpin: false }),);
  }

  render() {
    const { loadSpin } = this.state;
    const { uploadRes } = this.state;
    const { match } = this.props;
    return (
      <div className="ViewBillingUploadDetails">
        <PageHeader title="View Cashier-Remittance" />
        <Container>
          <Card>
            <Spin spinning={loadSpin} tip="Loading Cashier Remittance view...">
              <Descriptions bordered size="small">
                <Descriptions.Item label="Remittance ID">
                  {match.params.id}
                </Descriptions.Item>
                <Descriptions.Item label="Branch Code">
                  {uploadRes.branch ? uploadRes.branch : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Total Amount">
                  {uploadRes.expected_amount ? uploadRes.expected_amount : 0}
                </Descriptions.Item>
                <Descriptions.Item label="Amount Deposited">
                  {uploadRes.total_amount ? uploadRes.total_amount : 0}
                </Descriptions.Item>
                <Descriptions.Item label="Variance">
                  {uploadRes.variance ? uploadRes.variance : 0}
                </Descriptions.Item>
                <Descriptions.Item label="Date Created">
                  {uploadRes.created_at ? uploadRes.created_at : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Created By">
                  {uploadRes.creator ? uploadRes.creator.firstname : ''}
{" "}
                  {uploadRes.creator ? uploadRes.creator.lastname : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Remarks" span={2}>
                  {uploadRes.remarks ? uploadRes.remarks : ''}
                </Descriptions.Item>
              </Descriptions>
            </Spin>
          </Card>
          <Spacer />
          <Card
            extra={`Total: ${uploadRes.details ? uploadRes.details.length : 0}`}
          >
            <Spin spinning={loadSpin} tip="Loading Cashier Remittance view...">
              <ReactTable
                data={uploadRes.details}
                columns={[
                  {
                    Header: 'Deposit Date',
                    accessor: 'deposited_at',
                  },
                  {
                    Header: 'Tracking Number',
                    accessor: 'tracking_number',
                  },
                  {
                    Header: 'Amount Deposited',
                    accessor: 'amount',
                  },
                  {
                    Header: 'Deposit Slip',
                    accessor: 'deposit_slip',
                  },
                  {
                    Header: 'Bank',
                    accessor: 'bank',
                  },
                  {
                    Header: 'Account Number',
                    accessor: 'account_number',
                  },
                  {
                    Header: 'Remarks',
                    accessor: 'remarks',
                  },
                ]}
              />
            </Spin>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  doBillingViews: billingViews,
};

ViewRemittanceUpload.propTypes = {
  doBillingViews: PropTypes.func.isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewRemittanceUpload);

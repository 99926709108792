import {
  massUpload,
  billingListViews,
  billingUnbilledViews,
  template,
  uploadConfirm,
} from '../../services/api';

import { billingList } from '../../services/apiEndpoints';

const BILLING_CONFIRMATION = 'billing/BILLING_CONFIRMATION';

export const uploadConfirmation = (page, data) => ({
  type: BILLING_CONFIRMATION,
  payload: {
    client: 'secure',
    request: {
      ...uploadConfirm(page),
      data,
    },
  },
});

const BILLING_TEMPLATE = 'billing/BILLING_TEMPLATE';

export const getTemplate = tempName => ({
  type: BILLING_TEMPLATE,
  payload: {
    client: 'secure',
    request: {
      ...template(tempName),
    },
  },
});

const BILLING_LIST_VIEW = 'billing/BILLING_LIST_VIEW';
const BILLING_LIST_VIEW_SUCCESS = 'billing/BILLING_LIST_VIEW_SUCCESS';

export const bListViews = id => ({
  type: BILLING_LIST_VIEW,
  payload: {
    client: 'secure',
    request: {
      ...billingListViews(id),
    },
  },
});

const BILLING_UNBILLED_VIEW = 'billing/BILLING_UNBILLED_VIEW';
const BILLING_UNBILLED_VIEW_SUCCESS = 'billing/BILLING_UNBILLED_VIEW_SUCCESS';

export const bUnbilledViews = tNumber => ({
  type: BILLING_UNBILLED_VIEW,
  payload: {
    client: 'secure',
    request: {
      ...billingUnbilledViews(tNumber),
    },
  },
});

const BILLING_LIST = 'billing/BILLING_LIST';
const BILLING_LIST_SUCCESS = 'billing/BILLING_LIST_SUCCESS';

/**
 *  unbilled-list --- UnbilledList.js
 *  report-list --- Report.js
 *  billing-list --- List.js
 */

export const bList = (data, page) => ({
  type: BILLING_LIST,
  payload: {
    client: 'secure',
    request: {
      ...billingList(page),
      data,
    },
  },
});

const BILLING_NONFILTER_UNBILLEDLIST = 'billing/BILLING_NONFILTER_UNBILLEDLIST';
const BILLING_NONFILTER_UNBILLEDLIST_SUCCESS = 'billing/BILLING_NONFILTER_UNBILLEDLIST_SUCCESS';

const BILLING_NONFILTER_REPORTLIST = 'billing/BILLING_NONFILTER_REPORTLIST';
const BILLING_NONFILTER_REPORTLIST_SUCCESS = 'billing/BILLING_NONFILTER_REPORTLIST_SUCCESS';

const BILLING_NONFILTER_BILLINGLIST = 'billing/BILLING_NONFILTER_BILLINGLIST';
const BILLING_NONFILTER_BILLINGLIST_SUCCESS = 'billing/BILLING_NONFILTER_BILLINGLIST_SUCCESS';

/**
 *  unbilled-list --- UnbilledList.js
 *  report-list --- Report.js
 *  billing-list --- List.js
 */

export const bListNonfilter = (page, data) => {
  switch (page) {
    case 'report-list':
      return {
        type: BILLING_NONFILTER_REPORTLIST,
        payload: {
          client: 'secure',
          request: {
            ...billingList('report-list'),
            data,
          },
        },
      };

    case 'unbilled-list':
      return {
        type: BILLING_NONFILTER_UNBILLEDLIST,
        payload: {
          client: 'secure',
          request: {
            ...billingList('unbilled-list'),
            data,
          },
        },
      };

    case 'billing-list':
      return {
        type: BILLING_NONFILTER_BILLINGLIST,
        payload: {
          client: 'secure',
          request: {
            ...billingList('billing-list'),
            data,
          },
        },
      };

    default:
      return false;
  }
};

const BILLING_IMPORT = 'billing/BILLING_IMPORT';
const BILLING_IMPORT_SUCCESS = 'billing/BILLING_IMPORT_SUCCESS';

export const uploadBilling = (page, file) => ({
  type: BILLING_IMPORT,
  payload: {
    client: 'secure',
    request: {
      ...massUpload(page),
      data: file,
    },
  },
});

const initialState = {
  views: {},
  import: {},
  dataReportList: [],
  dataBillingList: [],
  dataUnbilledList: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case BILLING_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data.rows,
      };

    case BILLING_NONFILTER_UNBILLEDLIST_SUCCESS:
      return {
        ...state,
        dataUnbilledList: action.payload.data.rows,
      };

    case BILLING_NONFILTER_REPORTLIST_SUCCESS:
      return {
        ...state,
        dataReportList: action.payload.data.rows,
      };

    case BILLING_NONFILTER_BILLINGLIST_SUCCESS:
      return {
        ...state,
        dataBillingList: action.payload.data.rows,
      };

    case BILLING_IMPORT_SUCCESS:
      return {
        ...state,
        import: action.payload.data,
      };

    case BILLING_LIST_VIEW_SUCCESS:
      return {
        ...state,
        views: action.payload.data,
      };

    case BILLING_UNBILLED_VIEW_SUCCESS:
      return {
        ...state,
        views: action.payload.data,
      };

    default:
      return state;
  }
}

export default reducer;

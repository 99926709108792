import React from 'react';
import { Row as AntRow } from 'antd';
import PropTypes from 'prop-types';

const Row = ({ children }) => <AntRow gutter={16}>{children}</AntRow>;

Row.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Row;

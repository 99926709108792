import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
 DatePicker, Form, Button, Icon, Col, Modal, Input 
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';

import { crList, cashierRemittanceFinalize } from './ducks';
import { cashierRemittanceListVIew } from '../ducks';
import PageHeader from '../../components/PageHeader';
import ReactTable from '../../components/ServerSideTable';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';
import Row from '../../components/Row';

export class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handoverDate: [],
      loading: true,
      userData: {
        rows: [],
        pages: 0,
        total: 0,
      },
      payloadReq: {
        page: 0,
        pageSize: 0,
        filter: [],
        sorted: [],
      },
      tableFilter: [],
      searchFilter: [],
      remittanceID: 0,
      depositSlip: null,
      remarks: null,
      isVisible: false,
    };

    this.onFetchDataFunc = this.onFetchDataFunc.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  onFetchDataFunc(state, instance) {
    this.setState({
      loading: true,
    });
    // console.log(state);
    const { searchFilter } = this.state;
    const { listCashierRemittanceNonFilter } = this.props;
    const paramsReq = {
      export: false,
      page: state.page,
      pageSize: state.pageSize,
      filter: state.filtered,
      sorted: state.sorted,
    };

    if (searchFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(searchFilter);
    }
    listCashierRemittanceNonFilter('remittance-list', paramsReq).then(res => this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        payloadReq: paramsReq,
        tableFilter: state.filtered,
      }),);
  }

  getEditAccountHandler(e) {
    const { history } = this.props;
    history.push(`/cashier-remittance/details/${e.id}`);
  }

  sendFinalizePayment() {
    const {
 depositSlip, remarks, remittanceID, payloadReq 
} = this.state;
    const params = {
      deposit_slip: depositSlip,
      remarks,
    };
    const { doCashierRemittanceFinalize } = this.props;
    doCashierRemittanceFinalize(remittanceID, params).then(() => {
      this.onFetchDataFunc(payloadReq);
      this.setState({
        depositSlip: null,
        remarks: null,
        isVisible: false,
      });
    });
    // console.log(e);
  }

  openModalFinalize(e) {
    this.setState({
      remittanceID: e,
      isVisible: true,
    });
  }

  handleCancel() {
    this.setState({
      remittanceID: 0,
      isVisible: false,
    });
  }

  inputHandler(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  submitHandler() {
    this.setState({
      loading: true,
    });
    const { handoverDate, payloadReq, tableFilter } = this.state;
    const { listCashierRemittanceNonFilter } = this.props;
    // listCashierRemittance(filter, 'remittance-list');
    const paramsReq = {
      export: false,
      page: payloadReq.page,
      pageSize: payloadReq.pageSize,
      filter: [...handoverDate],
      sorted: payloadReq.sorted,
    };
    if (tableFilter.length) {
      paramsReq.filter = paramsReq.filter.concat(tableFilter);
    }
    listCashierRemittanceNonFilter('remittance-list', paramsReq).then(res => this.setState({
        loading: false,
        userData: {
          rows: res.payload.data.rows,
          pages: res.payload.data.pages,
          total: res.payload.data.total,
        },
        payloadReq: paramsReq,
        searchFilter: [...handoverDate],
      }),);
  }

  submitHandlerExport() {
    const { handoverDate, payloadReq } = this.state;
    const { listCashierRemittanceNonFilter } = this.props;
    // listCashierRemittance(filter, 'remittance-list');
    const paramsReq = {
      export: true,
      page: payloadReq.page,
      pageSize: payloadReq.pageSize,
      filter: [...handoverDate],
      sorted: payloadReq.sorted,
    };
    listCashierRemittanceNonFilter('remittance-list', paramsReq).then((res) => {
      const newBlob = new Blob([res.payload.data]);
      fileDownload(newBlob, 'Remittance-list.csv');
      this.setState({
        loading: false,
      });
    });
  }

  dateHandler(dateStrings) {
    const newDateOne = dateStrings[0];
    const newDateTwo = dateStrings[1];
    const formatDateOne = format(newDateOne, 'YYYY-MM-DD HH:mm:ss');
    const formatDateTwo = format(newDateTwo, 'YYYY-MM-DD HH:mm:ss');
    const dateForm = `${formatDateOne}|${formatDateTwo}`;
    this.setState({
      handoverDate: [
        {
          id: 'dateUploaded',
          value: formatDateOne !== 'Invalid Date' ? dateForm : '',
        },
      ],
    });
  }

  render() {
    const {
      loading,
      userData,
      depositSlip,
      remarks,
      isVisible,
      remittanceID,
    } = this.state;
    const breadCrumbs = [
      {
        breadcrumbName: 'Cashier Remittance',
      },
      {
        breadcrumbName: 'List',
      },
    ];
    return (
      <div className="List">
        <Modal
          title={`Edit Remittance: ${remittanceID}`}
          visible={isVisible}
          onOk={e => this.sendFinalizePayment(e)}
          onCancel={this.handleCancel}
        >
          <Form.Item label="Deposit Slip">
            <Input
              placeholder="Enter Deposit Slip"
              name="depositSlip"
              onChange={e => this.inputHandler(e)}
              value={depositSlip}
            />
          </Form.Item>
          <Form.Item label="Remarks">
            <Input
              name="remarks"
              placeholder="Remarks for Remittance"
              onChange={e => this.inputHandler(e)}
              value={remarks}
            />
          </Form.Item>
        </Modal>
        <PageHeader title="REMITTANCE LIST" routes={breadCrumbs} />
        <Container>
          <Card>
            <Form>
              <Form.Item label="Select Date">
                <DatePicker.RangePicker
                  style={{ width: '100%' }}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment('00:00', 'HH:mm'),
                      moment('00:00', 'HH:mm'),
                    ],
                  }}
                  onChange={e => this.dateHandler(e)}
                />
              </Form.Item>
              <Button onClick={() => this.submitHandler()} type="primary" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Icon>
                Apply
              </Button>
            </Form>
          </Card>
          <Spacer />
          <Card
            title={(
              <Button onClick={() => this.submitHandlerExport()} type="danger">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faFileExport} fixedWidth />
                </Icon>
                Export
              </Button>
            )}
            extra={`Total: ${userData.total
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          >
            <ReactTable
              data={userData.rows}
              pages={userData.pages}
              columns={[
                {
                  Header: 'Delivery Date',
                  accessor: 'created_at',
                },
                {
                  Header: 'Remittance ID',
                  accessor: 'id',
                },
                {
                  Header: 'Branch',
                  accessor: 'branch',
                },
                {
                  Header: 'Total Amount',
                  accessor: 'total_amount',
                },
                {
                  Header: 'Remitted Amount',
                  accessor: 'expected_amount',
                },
                {
                  Header: 'Variance',
                  accessor: 'variance',
                },
                {
                  Header: 'Processed By',
                  accessor: 'creator_name',
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  width: 200,
                  Cell: row => (
                    <Row>
                      <Col xs={24} sm={24} lg={12}>
                        <Button
                          type="link"
                          onClick={() => this.getEditAccountHandler(row.original)
                          }
                        >
                          <Icon type="eye" />
                          View
                        </Button>
                      </Col>
                      {row.original.status === 0 ? (
                        <Col xs={24} sm={24} lg={12}>
                          <Button
                            type="link"
                            onClick={() => this.openModalFinalize(row.original.id)
                            }
                          >
                            <Icon type="file" />
                            Finalize
                          </Button>
                        </Col>
                      ) : null}
                    </Row>
                  ),
                },
              ]}
              loadingText="Fetching cashier remittance list. . ."
              loading={loading}
              onFetchData={this.onFetchDataFunc}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  listCashierRemittance: crList,
  listCashierRemittanceNonFilter: cashierRemittanceListVIew,
  doCashierRemittanceFinalize: cashierRemittanceFinalize,
};

List.propTypes = {
  listCashierRemittanceNonFilter: PropTypes.func.isRequired,
  doCashierRemittanceFinalize: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);

import React, { Component } from 'react';
import PageHeader from '../../components/PageHeader';
import Container from '../../components/Container';
import DispatchList from '../../components/DispatchList';

class RTMList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Return To Merchant',
      },
      {
        breadcrumbName: 'List',
      },
    ];

    const dispatchListColumns = [
      {
        Header: 'Dispatch ID',
        accessor: 'dh_id',
      },
      {
        Header: 'Courier',
        accessor: 'driver',
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
      },
      {
        Header: 'Date',
        accessor: 'dispatch_date',
      },
    ];

    const customFilter = {
      id: 'type',
      value: 'rtm',
    };

    return (
      <div className="RTMList">
        <PageHeader title="Return To Merchant" routes={breadCrumbs} />
        <Container>
          <DispatchList
            hasExport
            dispatchListColumns={dispatchListColumns}
            customFilter={customFilter}
            fetchText="Fetching Rtm List. . ."
            urlEndpoint="/rtm"
            typeDispatch="rtm"
          />
        </Container>
      </div>
    );
  }
}

export default RTMList;

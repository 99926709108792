import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { logout } from './ducks';

class Logout extends Component {
  componentDidMount() {
    const { doLogout } = this.props;
    doLogout();
  }

  render() {
    return <p>Logging out...</p>;
  }
}

const mapDispatchToProps = {
  doLogout: logout,
};

Logout.propTypes = {
  doLogout: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps,
)(Logout);
